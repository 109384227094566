import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Box, Tab, Tooltip } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { UpdateOrdersDialog } from "../../components";
import { MESSAGES } from "../../messages";
import AddressPopup from "../../MyAddresses/AddressPopup";
import OrderSummary from "./components/OrderSummary";
import PaymentCardDetails from "./components/PaymentCardDetails";
import PickUpOrder from "./components/PickUpOrder";
import ShippingOrder from "./components/ShippingOrder";
import {
  BILLING_ADDRESS_ID_KEY,
  BILLING_ADDRESS_KEY,
  ORDER_TABS,
  SHIPPING_ADDRESS_ID_KEY,
  SHIPPING_ADDRESS_KEY,
} from "./constants";
import { usePlaceOrder } from "./usePlaceOrder";
import { FormProvider } from "react-hook-form";

const PlaceOrder = () => {

  const { state } = useLocation();
  const {
    data: orderItems,
    errors,
    confirmFunc,
    toggleDialog,
    openConfirm,
    register,
    watch,
    setValue,
    getValues,
    handleCardDateChange,
    handlePickupToShippingChange,
    changedProducts,
    removedProducts,
    ratesChanged,
    addressesData,
    handleSameAsShippingChange,
    isWithoutShipping,
    sameAsShipping,
    isOrderShippable,
    selectedTab,
    handleBillingAddressChange,
    handleShippingAddressChange,
    billingAddressKeyName,
    shippingAddressKeyName,
    ...restOptions
  } = usePlaceOrder(state);

  return (
    <Box
      sx={{
        px: { xs: 2, sm: 3, md: 10.5, lg: 0 },
        mx: "auto", py: { xs: 3, md: 3 },
        maxWidth: "1152px", display: { lg: "flex" },
        justifyContent: "space-between",
      }}
    >
      <FormProvider {...{
        errors,
        register,
        setValue,
        getValues,
        handleBillingAddressChange,
        handleShippingAddressChange,
        billingAddressKeyName,
        shippingAddressKeyName,
      }}
      >
        <Box sx={{ width: { lg: "70%" }, typography: 'body1' }}>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handlePickupToShippingChange} aria-label={MESSAGES.TABS_ARIA}>
                <Tab label={MESSAGES.PICKUP} value={ORDER_TABS.PICKUP} sx={{ width: "50%", maxWidth: "unset" }}/>
                <Tooltip title={isOrderShippable ? "" : MESSAGES.SHIPPING_DISABLED} arrow placement="top"
                         value={ORDER_TABS.SHIPPING}>
                  {
                    isOrderShippable
                      ?
                      <Tab label={MESSAGES.SHIPPING} sx={{ width: "50%", maxWidth: "unset" }}/>
                      :
                      <span style={{ width: "50%", textAlign: "center", maxWidth: "unset" }}>
                      <Tab disabled label={MESSAGES.SHIPPING}/>
                    </span>
                  }
                </Tooltip>
              </TabList>
            </Box>

            <Box>
              <TabPanel key={ORDER_TABS.PICKUP} value={ORDER_TABS.PICKUP} sx={{ p: 0 }}>
                <PickUpOrder/>
              </TabPanel>

              <TabPanel key={ORDER_TABS.SHIPPING} value={ORDER_TABS.SHIPPING} sx={{ p: 0 }}>
                <ShippingOrder
                  orderItems={orderItems}
                  addressesData={addressesData}
                />
              </TabPanel>
            </Box>

            <PaymentCardDetails
              {...addressesData}
              handleSameAsShippingChange={handleSameAsShippingChange}
              handleCardDateChange={handleCardDateChange}
              isWithoutShipping={isWithoutShipping}
              sameAsShipping={sameAsShipping}
            />
            {addressesData.openAddressPopup &&
              <AddressPopup
                states={addressesData.states}
                selectedItem={addressesData.selectedItem}
                open={addressesData.openAddressPopup}
                toggleAddressDialog={addressesData.toggleAddressDialog}
              />
            }
          </TabContext>
        </Box>

        <OrderSummary
          data={orderItems}
          {...restOptions}
        />

      </FormProvider>

      <UpdateOrdersDialog
        open={openConfirm}
        title={MESSAGES.PLACE_ERR_TITLE}
        alert={MESSAGES.PLACE_ERR_ALERT}
        data={{
          changedProducts,
          removedProducts,
          ratesChanged
        }}
        confirmFunc={confirmFunc}
        toggleDialog={toggleDialog}
      />
    </Box>
  );
};

export default PlaceOrder;

import { useForm, useFieldArray, useWatch }             from "react-hook-form";
import { useCallback, useEffect, useMemo, useState }    from "react";
import { SH_FIELD_NAMES, SHOPPING_BAG_KEY }             from "pages/main/Product/components/ProductForm/constants";
import { createShoppingBagItem, updateShoppingBagItem } from "services/api/shoppingBag/ShoppingBagService";
import { useFetch }                                     from "pages/auth/hooks";
import messages                                         from "pages/main/Product/components/ProductForm/messages";
import { shopPaths }                                    from "routing/constants";
import { useShopDispatch, useShopSelector }             from "store/hooks";
import customerSelectors                                from "store/customer/customerSelectors";
import ShopInfoAsyncActions                             from "store/shopInfo/ShopInfoAsyncActions";

//todo: filed enum
export const optionTypes = {
  Quantity: "Quantity",
  Sizes: "Sizes",
  PaperTypes: "Paper Types",
  Material: "Material",
  LargeSizes: "Large Sizes",
  PrintSides: "Print Sides",
  RaisedSpotUv: "Raised Spot UV"
};

export const calcTypes = ["Digital", "Large", "Other"];

const useProductForm = (productId, fields, designPrice, basePrice, shoppingBagItemData, printing, calculationType) => {
  const isLoggedIn = useShopSelector(customerSelectors.getIsLoggedIn);
  const isReseller = useShopSelector(customerSelectors.getIsReseller);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors, isSubmitting }
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      [ SHOPPING_BAG_KEY ]: {
        productId: +productId,
        isArtwork: false,
        isProofing: false,
        printing: null,
        ...shoppingBagItemData && {
          notes: shoppingBagItemData.notes,
          itemName: shoppingBagItemData.itemName,
          isArtwork: shoppingBagItemData.isArtwork,
          isProofing: shoppingBagItemData.isProofing,
          printing: shoppingBagItemData.printing,
          customerUploads: shoppingBagItemData.customerUploads,
          id: shoppingBagItemData.id
        }
      }
    }
  });
  useFieldArray({ control, name: SH_FIELD_NAMES.selectedOptions });
  const selectedOptions = useWatch({ control, name: SH_FIELD_NAMES.selectedOptions });
  const [price, setPrice] = useState({ total: 0, purchasePrice: 0 });

  useEffect(() => {
    const selectedProductOptions = [];
    if (fields && selectedOptions) {
      for (let field of fields) {
        selectedProductOptions.push(field.productOptions.find(option => selectedOptions.find(selectedId => selectedId === option.id)));
      }
    }

    setValue(SH_FIELD_NAMES.selectedProductOptions, selectedProductOptions);
  }, [fields, selectedOptions, setValue]);
  const getNumber = (label) => {
    if (!label) {
      return 0;
    }
    if (label.includes("1") || label.includes("One") || label.includes("one")) {
      return 1;
    } else if (label.includes("2") || label.includes("Two") || label.includes("two")) {
      return 2;
    } else {
      return 0;
    }
  };
  const redirectPath = useMemo(() => isLoggedIn
    ? shopPaths.auth.shoppingBag.fullPath
    : shopPaths.auth.shoppingBag.unAuthPath,
    [isLoggedIn]);

  const {
    isSubmitSuccessful,
    handleFetchSubmit: handleSoppingBagItemCreate
  } = useFetch(createShoppingBagItem, messages.shoppingBagItemSaved, redirectPath);

  const checkoutRedirectPath = useMemo(() => isLoggedIn
    ? shopPaths.auth.placeOrder.fullPath
    : shopPaths.auth.placeOrder.unAuthPath,
    [isLoggedIn]);

  const {
    isSubmitSuccessful: isHandleBuySuccessFull,
    handleFetchSubmit: handleSoppingBagItemCreateWithCheckout
  } = useFetch(createShoppingBagItem, messages.shoppingBagItemCheckout, checkoutRedirectPath, {
    withData: true,
    makeArray: true
  });

  const {
    handleFetchSubmit: handleSoppingBagItemUpdate
  } = useFetch(updateShoppingBagItem, messages.shoppingBagItemSaved, redirectPath);

  /** watch triggers rerender */
  watch(SH_FIELD_NAMES.isArtwork);
  watch(SH_FIELD_NAMES.isProofing);
  watch(SH_FIELD_NAMES.file);
  watch(SH_FIELD_NAMES.customerUploads);

  const shopDispatch = useShopDispatch();
  useEffect(() => {
    if (isSubmitSuccessful) {
      shopDispatch(ShopInfoAsyncActions.fetchShoppingBagItemsCount({ isLoggedIn }));
    }
  }, [isSubmitSuccessful, shopDispatch, isLoggedIn]);

  useEffect(() => {
    if (isHandleBuySuccessFull) {
      shopDispatch(ShopInfoAsyncActions.fetchShoppingBagItemsCount({ isLoggedIn }));
    }
  }, [isHandleBuySuccessFull, shopDispatch, isLoggedIn]);

  const getSelectedValues = useCallback(() => {
    const selectedOptions = {};
    const selectedValues = getValues(SH_FIELD_NAMES.selectedOptions) || [];
    if (fields) {
      for (let field of fields) {
        selectedOptions[ field.name ] = field.productOptions.find(option => selectedValues.find(selectedId => selectedId === option.id));
      }
    }
    return selectedOptions;
  }, [getValues, fields]);

  const getTotalPrice = useCallback(() => {
    const selected = getSelectedValues();
    const data = JSON.parse(JSON.stringify(selected));

    if (!data || Object.keys(data).length === 0) {
      return;
    }
    let sum = 0;
    let totalPrice = 0;
    const count = Number(data[ optionTypes.Quantity ]?.label);
    const coefficient = Number(data[ optionTypes.Quantity ]?.coefficient) || 1;
    switch (calculationType) {
      case 0:
        let perPaper = data[ optionTypes.Sizes ]?.perPaperCount;
        const paperCount = Math.ceil(count / perPaper);
        for (let fieldName in data) {
          if (![optionTypes.Sizes, optionTypes.Quantity].includes(fieldName)) {
            if (data[ fieldName ].pricePerPaper) {
              sum += data[ fieldName ].pricePerPaper * paperCount;
            } else {
              sum += data[ fieldName ].pricePerItem * count;
            }
          }
        }
        totalPrice = (basePrice + (basePrice * count / 10) / 100 + sum) * coefficient;
        break;
      case 1:
        let sq = data[ optionTypes.Sizes ]?.squareFeet;
        for (let fieldName in data) {
          if (![optionTypes.Sizes, optionTypes.Quantity].includes(fieldName)) {
            if (data[ fieldName ].perSquareFeet) {
              sum += data[ fieldName ].perSquareFeet * sq;
            } else {
              sum += data[ fieldName ].pricePerItem * count;
            }
          }
        }
        totalPrice = (basePrice + (basePrice * count / 10) / 100 + sum) * coefficient;
        break;
      case 2:
        sum = basePrice;
        for (let fieldName in data) {
          if (![optionTypes.Quantity].includes(fieldName)) {
            if (data[ fieldName ].pricePerItem) {
              sum += data[ fieldName ].pricePerItem;
            }
          }
        }
        totalPrice = sum * count * coefficient;
        break;
      default:
        break;
    }
    return Number(totalPrice.toFixed(2));
  }, [basePrice, getSelectedValues, calculationType]);

  const getPurchasePrice = useCallback(() => {
    const total = getTotalPrice();
    const { printing: selectedPrinting, isArtwork } = getValues().shoppingBag;
    const printReady = typeof selectedPrinting === "object" ? selectedPrinting : printing[ selectedPrinting ];
    let purchase = total * printReady.coefficient;
    if (isArtwork) {
      purchase += designPrice;
    }
    const { priceFront } = SH_FIELD_NAMES;
    const purchasePrice = purchase.toFixed(2);
    setValue(priceFront, !isReseller ? purchasePrice : (purchasePrice * 0.8).toFixed(2));
    return {
      total: total,
      purchasePrice: purchasePrice
    };

  }, [designPrice, getTotalPrice, getValues, printing, setValue, isReseller]);

  useEffect(() => {
    setPrice(getPurchasePrice());
  }, [getPurchasePrice]);

  const getRequestData = useCallback((data) => {
    return isLoggedIn ? data : {
      file: data.file,
      ...data.shoppingBag
    };
  }, [isLoggedIn]);

  const handleBuy = useCallback((data) => {
    return handleSoppingBagItemCreateWithCheckout({ data: getRequestData(data), isLoggedIn });
  }, [handleSoppingBagItemCreateWithCheckout, isLoggedIn, getRequestData]);

  const updateSoppingBagItem = useCallback((data) => {
    return handleSoppingBagItemUpdate({ data: getRequestData(data), isLoggedIn });
  }, [handleSoppingBagItemUpdate, isLoggedIn, getRequestData]);

  const addToShoppingBag = useCallback((data) => {
    return handleSoppingBagItemCreate({ data: getRequestData(data), isLoggedIn });
  }, [handleSoppingBagItemCreate, isLoggedIn, getRequestData]);

  const handleOptionsChange = useCallback(({ target: { name, value } }) => {
    setValue(name, +value);
    setPrice(getPurchasePrice());
  }, [setPrice, getPurchasePrice, setValue]);

  const setPurchasePrice = useCallback(() => {
    setPrice(getPurchasePrice());
  }, [setPrice, getPurchasePrice]);

  return {
    handleBuy,
    addToShoppingBag,
    updateSoppingBagItem,
    setValue,
    getValues,
    trigger,
    isSubmitting,
    register,
    handleSubmit,
    setPurchasePrice,
    getSelectedValues,
    errors,
    getNumber,
    handleOptionsChange,
    price,
    isReseller
  };
};

export default useProductForm;
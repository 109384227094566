import Toolbar                 from "@mui/material/Toolbar";
import Typography              from "@mui/material/Typography";
import Box                     from "@mui/material/Box";
import { ShoppingBagOutlined } from "@mui/icons-material";
import Tooltip                 from "@mui/material/Tooltip";
import IconButton              from "@mui/material/IconButton";
import FilterListIcon          from "@mui/icons-material/FilterList";
import * as React              from "react";

export const EnhancedTableToolbar = () => {
  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between", pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Box display={"flex"}>
        <ShoppingBagOutlined color={"primary"}/>
        <Typography component="p" variant="h3" sx={{ ml: 1.5 }} id="tableTitle">
          Orders list
        </Typography>
      </Box>
      {/*<Tooltip title="Filter list">*/}
      {/*  <IconButton>*/}
      {/*    <FilterListIcon/>*/}
      {/*  </IconButton>*/}
      {/*</Tooltip>*/}
    </Toolbar>
  );
};
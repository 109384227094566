import { deleteAPI, getAPI, postFilesAPI, putFilesAPI }             from "services/api/NetService";
import {
  SHOPPING_BAG_ID_PATH,
  SHOPPING_BAG_PATH,
  SHOPPING_BAG_PATH_COUNT
}                                                                   from "services/api/shoppingBag/constants";
import { SH_FIELD_NAMES, SHOPPING_BAG_KEY, WITHOUT_VALIDATION_KEY } from "pages/main/Product/components/ProductForm/constants";
import ShopIndexedDb                                                from "indexedDB/ShopIndexedDb";
import { updateCustomer }                                           from "services/api/serviceHelpers";
import { getProductRequest }                                        from "../products/productsService";

export const getShoppingBag = (isLoggedIn) => {
  if (isLoggedIn) {
    return getAPI(SHOPPING_BAG_PATH)
      .then(({ data: shoppingBag, success, title }) => {
        if (success) {
          return { shoppingBag, title };
        }
        return Promise.reject(title);
      })
  }

  return ShopIndexedDb.getAllItems().then(({ data: shoppingBag, success, title }) => {
    if (success) {
      const allPRomise = new Set();
      shoppingBag.forEach(({ productId }) => {
        allPRomise.add(getProductRequest(productId));
      });

      return Promise.allSettled(allPRomise).then(async (productsRes) => {
        const idsProduct = productsRes.reduce((acc, { status, value, reason }) => {
          if (status === "fulfilled") {
            acc[ value.id ] = value;

          }

          return acc;
        }, {});

        let actualTitle = title;
        const tobeOrNotToBe = [];
        const actualShoppingBags = shoppingBag.reduce((acc, item) => {
          const product = idsProduct[ item.productId ];
          let optionExist = true;
          let prodOptions = [];

          product?.productOptionTypeGrouped.forEach((opGrouped) => {
            prodOptions = [...prodOptions, ...opGrouped.productOptions];
          });

          for (let j = 0; j < item.selectedOptions.length; j++) {
            const opId = item.selectedOptions[j]
            const opIndex = prodOptions.findIndex(op => op.id === opId);
            if (opIndex === -1) {
              optionExist = false;
              break;
            }
          }
          if (product && optionExist) {
            item.product = product;
            acc.push(item);
          } else {
            tobeOrNotToBe.push(deleteShoppingBagItem(item.id));
            actualTitle = "Some items are not available at the moment.";
          }

          return acc;
        }, []);

        await Promise.allSettled(tobeOrNotToBe);

        return { shoppingBag: actualShoppingBags, title: actualTitle };
      });
    }
    return Promise.reject(title);
  });
};

export const getShoppingBagItemsCount = (isLoggedIn) => {
  if (isLoggedIn) {
    return getAPI(SHOPPING_BAG_PATH_COUNT)
      .then(({ data, success, title }) => {
        if (success) {
          return data.count;
        }

        return Promise.reject(title);
      })
  }

  return ShopIndexedDb.getItemsCount()
    .then(({ data, success, title }) => {
      if (success) {
        return data;
      }

      return Promise.reject(title);
    })
};

export const getShoppingBagItemById = (id, isLoggedIn) => {
  if (isLoggedIn) {
    return getAPI(SHOPPING_BAG_ID_PATH(id))
      .then(({ data: shoppingBag, success, title }) => {

        if (success) {
          return shoppingBag;
        }

        return Promise.reject(title)
      })
  }

  return ShopIndexedDb.getById(id)
    .then(({ data: shoppingBag, success, title }) => {

      if (success) {
        return shoppingBag;
      }

      return Promise.reject(title)
    });
};

const createFormData = (data, isWithoutValidation) => {
  const formData = new FormData();

  /** append customer uploaded files*/
  if(data[SH_FIELD_NAMES.file]){
    for (let file of data[SH_FIELD_NAMES.file]) {
      formData.append(SH_FIELD_NAMES.file, file)
    }
    delete data.file;
  }

  formData.append(SHOPPING_BAG_KEY, JSON.stringify(data[SHOPPING_BAG_KEY]));
  formData.append(WITHOUT_VALIDATION_KEY, isWithoutValidation);

  return formData;
};

export const createShoppingBagItem = ({ data, isLoggedIn, isWithoutValidation = false }) => {
  if (isLoggedIn) {
    const formData = createFormData(data, isWithoutValidation)
    return postFilesAPI(SHOPPING_BAG_PATH, formData)
      .then(async (response) => {
        if (!response.success) {
          updateCustomer();
        }

        return response;
      });
  }

  return ShopIndexedDb.addItem(data)
    .then(async (response) => {
      if (response?.data?.productId) {
        response.data.product = await getProductRequest(response.data.productId);
        return response;
      }
    });
};

export const updateShoppingBagItem = ({ data, isLoggedIn, isWithoutValidation = false }) => {
  if (isLoggedIn) {
    const formData = createFormData(data, isWithoutValidation);
    return putFilesAPI(SHOPPING_BAG_ID_PATH(data.shoppingBag.id), formData)
      .then((response) => {
        if (!response.success) {
          updateCustomer();
        }

        return response;
      });
  }

  return ShopIndexedDb.putItem(data);
};

export const deleteShoppingBagItem = (id, isLoggedIn) => {
  if (isLoggedIn) {
    return deleteAPI(`${SHOPPING_BAG_PATH}/${id}`)
      .then((data) => data)
  }

  return ShopIndexedDb.deleteItem(id);
};
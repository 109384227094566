import PropTypes                                       from "prop-types";
import { Avatar, CircularProgress, Paper, Typography } from "@mui/material";
import { theme }                                       from "components/theme/constants";

const ALIGNMENT = {
  start: "start",
  center: "center",
  end: "end"
};

const Card = ({ title, info, Icon, alignment, href }) => {

  return (
    <Paper variant="outlined" component={"a"}
           href={href}
           sx={{
             p: 3,
             my: 1.5,
             mx: { sm: 1.5 },
             width: "100%",
             maxWidth: "360px",
             height: "240px",
             display: "flex",
             flexDirection: "column",
             alignItems: alignment,
             textAlign: alignment,
             boxSizing: "border-box",
             borderColor: "primary.main",
             textDecoration: "none"
           }}
    >
      <Avatar
        sx={{
          backgroundColor: theme.palette.primary.main20,
          width: "56px",
          height: "56px",
          mb: 3
        }}
      >
        <Icon
          fontSize="large"
          color="primary"
        />
      </Avatar>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2">
        {info || <CircularProgress size={24}/>}
      </Typography>
    </Paper>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string,
  Icon: PropTypes.object,
  alignment: PropTypes.oneOf(Object.keys(ALIGNMENT))
};

Card.defaultProps = {
  alignment: ALIGNMENT.center
};

export default Card;

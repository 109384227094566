import { AuthPagesWrapper } from "pages/auth/components";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { EmailField } from "components/main/form";
import { useFetch } from "pages/auth/hooks";
import { customerForgotPassRequest } from "services/api/auth/authCustomerService";
import { MESSAGES } from "pages/auth/messages";

const Forgot = () => {
  const {
    errors,
    register,
    isSubmitting,
    isSubmitSuccessful,
    handleSubmit,
    handleFetchSubmit,
    getValues,
  } = useFetch(customerForgotPassRequest, MESSAGES.forgotEmail);
  const sentEmail = getValues("emailAddress");

  const headerOptions = isSubmitSuccessful
    ? {
      header: "Check your email",
      description: `We sent a password reset link to ${sentEmail}`,
      HeaderIcon: EmailOutlinedIcon
    }
    : {
      header: "Forgot password?",
      description: "No worries, we’ll send you reset instructions.",
      HeaderIcon: VpnKeyOutlinedIcon
    }

  return (
    <AuthPagesWrapper
      {...headerOptions}
      showBackLink={true}
      headerAlign="center"
    >
      <Box
        component="form"
        onSubmit={handleSubmit(handleFetchSubmit)}
        noValidate
      >
        {isSubmitSuccessful
          ?
          <LoadingButton
            loading={isSubmitting}
            type={"submit"}
            fullWidth
            size={"large"}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Didn't receive? Click to resend
          </LoadingButton>
          : <>
            <EmailField
              register={register}
              errors={errors}
            />
            <LoadingButton
              loading={false}
              type={"submit"}
              fullWidth
              size={"large"}
              variant="contained"
              sx={{ mt: 4 }}
            >
              Reset Password
            </LoadingButton>
          </>
        }
      </Box>
    </AuthPagesWrapper>
  );
};

export default Forgot;

export const MESSAGES = {
  ADDRESS_SAVED: "Address is successfully saved.",
  ADDRESS_DELETED: "Address is successfully deleted.",
  PRODUCT_DELETED: "Product is successfully deleted.",

  TAX: "Tax",
  SHIP: "Shipping",
  YES: "Yes",
  NO: "No",
  NOT_VALID: "Not Valid",
  SHIPPABLE: "Shippable",
  TOTAL: "Total",
  SUBTOTAL: "Subtotal",
  YOUR_ORDER: "Your order",
  PLACE_ORDER: "Place order",
  PLACE_ERR_TITLE: "Attention!!!",
  PLACE_ERR_ALERT: "Press 'Update' to see latest updates",

  SHOPPING_BAG: "Shopping bag",
  CONTINUE_SHOPPING: "Continue shopping",
  SELECT_ALL: "Select all",
  CHECKOUT_NOW: "Checkout now",
  CONFIRM_TITLE: "Delete",
  CONFIRM_MESSAGE: "Do you want to delete product?",

  PICKUP: "PICKUP",
  SHIPPING: "SHIPPING",
  TABS_ARIA: "Order methods",
  LOCATION: "Location",
  GET_DIRECTION: "Get Direction",
  HOURS_OF_OPERATION: "Hours Of Operation",
  BUY_NOW: "Buy Now",
  ADR_SHIP_HEADER: "Shipping",
  ADR_BILL_HEADER: "Billing",
  ADR_BILL_CHECK: "Same as shipping address",
  SHIPPING_DISABLED: "Shipping is disabled for this order. There are products which are not shippable yet.",
  SET_DEFAULT_ADDRESS: "Set as default address",
  DELETE_ADDRESS: "Delete Address",
  EDIT_ADDRESS: "Edit Address",
  CONFIRM_DELETE_ADR_TITLE: "Delete",
  CONFIRM_DELETE_ADR_INFO: "Do you want to delete the address?",
  CONFIRM_DEFAULT_ADR_TITLE: "Set as default",
  CONFIRM_DEFAULT_ADR_INFO: "Do you want to set as default address?",
  DEFAULT_ADDRESS: "Default address",
  SHOW_MORE: "Show More",
  SHOW_LESS: "Show Less",
  SHIPPING_OPTIONS: "Shipping Options",
  SHIPPING_DATES_PRICES: "Selected Shipping Dates & Pricing",
  RATES_CHOICES_FAILS: "Please reload page or contact us for support",
  ADD_ADDRESS: "Add Address",
  HIDE_ADDRESSES: "Hide Addresses",
  CHANGE_ADDRESS: "Change Address",
  PLS_ADD: "Please add the address.",
  ADR_RQR: "Address is required.",
  PLS_SELECT_ADDRESS: "Please select address",
  SHP_ADD_RQR: "Shipping address is required. Please select address",
  BLL_ADD_RQR: "Billing address is required. Please select address",
};

import { AuthPagesWrapper } from "pages/auth/components";
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { ConfirmPasswordField, PasswordField } from "components/main/form";
import { useFetch } from "pages/auth/hooks";
import { customerResetPassRequest } from "services/api/auth/authCustomerService";
import { MESSAGES } from "pages/auth/messages";
import { shopPaths } from "routing/constants";

const Reset = () => {
  const {
    errors,
    register,
    isSubmitting,
    isSubmitSuccessful,
    handleSubmit,
    handleFetchSubmit,
    getValues,
  } = useFetch(customerResetPassRequest, MESSAGES.passResSuc, shopPaths.unAuth.login.path);
  const { password } = getValues();

  const headerOptions = isSubmitSuccessful
    ? {
      header: "Password reset",
      description: "Your password has been successfully reset. Click below to log in magically.",
      HeaderIcon: CheckOutlinedIcon,
      success: true,
    }
    : {
      header: "Set new password",
      description: "",
      HeaderIcon: VpnKeyOutlinedIcon
    }

  return (
    <AuthPagesWrapper
      {...headerOptions}
      headerAlign="center"
      showBackLink={true}
    >
      {!isSubmitSuccessful &&
        <Box
          component="form"
          onSubmit={handleSubmit(handleFetchSubmit)}
          noValidate
        >
          <PasswordField
            showHelpText
            register={register}
            errors={errors}
            regex={true}
          />
          <ConfirmPasswordField
            register={register}
            errors={errors}
            password={password}
          />
          <LoadingButton
            loading={isSubmitting}
            type={"submit"}
            fullWidth
            size={"large"}
            variant="contained"
            sx={{ mt: 3 }}
          >
            Set Password
          </LoadingButton>
        </Box>
      }
    </AuthPagesWrapper>
  );
};

export default Reset;

import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { SH_FIELD_NAMES, SHOPPING_BAG_KEY } from "./constants";
import messages from "./messages";

const { notes, itemName } = SH_FIELD_NAMES;

const NOTES_MAX_LENGTH = 2000;
const NAME_MAX_LENGTH = 40;

const ProductOrderInfo = ({ register, errors }) => {
  const { [SHOPPING_BAG_KEY]: infoErrors = {} } = errors;
  const { itemName: itemNameError, notes: notesError } = infoErrors;

  return (
    <>
      <TextField
        margin="normal"
        fullWidth
        multiline
        rows={4}
        id={notes}
        label={`${messages.notesLabel} (Max ${NOTES_MAX_LENGTH} chars)`}
        name={notes}
        {...register(notes, {
          maxLength: { value: NOTES_MAX_LENGTH, message: `Text should be shorter ${NOTES_MAX_LENGTH} chars.` }})}
        {...notesError &&
        {
          error: true,
          helperText: notesError.message
        }
        }
      />

      <TextField
        margin="normal"
        fullWidth
        id={itemName}
        label={`${messages.itemNameLabel} (Max ${NAME_MAX_LENGTH} chars)`}
        name={itemName}
        {...register(itemName, {
          maxLength: { value: NAME_MAX_LENGTH, message: `Text should be shorter ${NAME_MAX_LENGTH} chars.` }})
        }
        {...itemNameError &&
        {
          error: true,
          helperText: itemNameError.message
        }
        }
      />
    </>
  );
};

ProductOrderInfo.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default ProductOrderInfo;
import { Avatar, Box, Paper, Typography } from "@mui/material";
import { theme }                          from "components/theme/constants";

const PaperCard = ({ title, info, Icon, sx }) => {
  return <Paper variant="outlined"
                sx={{
                  p: 2,
                  mb: { xs: 2.5, md: 0 },
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.24)",
                  borderRadius: "8px",
                  ...sx
                }}
  >
    <Avatar
      sx={{
        backgroundColor: theme.palette.primary.main20,
        width: "56px",
        height: "56px",
        mr: 2.5
      }}
    >
      <Icon
        fontSize="large"
        color="primary"
      />
    </Avatar>
    <Box>
      <Typography variant="h4" sx={{ color: "primary.contrastText", mb: 0.5 }}>
        {title}
      </Typography>
      <Typography variant="body2">
        {info}
      </Typography>
    </Box>
  </Paper>;
};

export default PaperCard;

import React, { memo } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import {
  BILLING_ADDRESS_ID_KEY,
  BILLING_ADDRESS_KEY,
  SHIPPING_ADDRESS_ID_KEY,
  SHIPPING_ADDRESS_KEY,
} from "../ShoppingBag/PlaceOrder/constants";
import { RadioGroup } from "@mui/material";
import MyAddressesCards from "./MyAddressesCards";

const MyAddressesRadioWrapped = memo(({
                                        myAddresses,
                                        toggleDialog,
                                        toggleAddressDialog,
                                        selected,
                                        radioName,
                                        addressChangeHandler,
                                      }) => {

  const { errors, getValues } = useFormContext();
  const selectedItem = getValues(radioName);
  const selectedItemId = selectedItem?.id || selectedItem;

  return (
    <>
      <RadioGroup
        name={radioName}
        {...selected && {
          onChange: addressChangeHandler,
        }}
      >
        <MyAddressesCards
          toggleDialog={toggleDialog}
          toggleAddressDialog={toggleAddressDialog}
          myAddresses={myAddresses}
          radioName={radioName}
          errors={errors}
          selectedItem={selectedItemId}
        />
      </RadioGroup>
    </>
  );
});

MyAddressesRadioWrapped.propTypes = {
  myAddresses: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleDialog: PropTypes.func.isRequired,
  toggleAddressDialog: PropTypes.func.isRequired,
  addressChangeHandler: PropTypes.func.isRequired,
  selected: PropTypes.object,
  radioName: PropTypes.oneOf([
    BILLING_ADDRESS_ID_KEY,
    BILLING_ADDRESS_KEY,
    SHIPPING_ADDRESS_KEY,
    SHIPPING_ADDRESS_ID_KEY]).isRequired,
};

MyAddressesRadioWrapped.defaultProps = {
  selected: null,
};

export default MyAddressesRadioWrapped;

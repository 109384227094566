import { Box, Button, ImageListItem, Typography } from "@mui/material";
import ArrowBackIcon                              from "@mui/icons-material/ArrowBack";
import { useNavigate }                            from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return <Box sx={{ py: { xs: 8 }, px: { xs: 2, sm: 3, md: 10.5, lg: 10 }, maxWidth: "1152px", m: "auto" }}>
    <Box sx={{
      display: "flex", flexDirection: { xs: "column-reverse", md: "row" }, alignItems: "center",
      justifyContent: "center"
    }}>
      <Box sx={{
        width: { xs: "100%", md: "60%", lg: "50%" }, display: "flex", flexDirection: "column",
        justifyContent: "center", pl: { xs: 0, md: 2 }, textAlign: { xs: "center", md: "left" }
      }}>
        <Typography variant={"subtitle1"} color={"secondary.main"} sx={{ mb: 2 }}>
          404 error
        </Typography>
        <Typography variant={"h1"} sx={{ mb: { xs: 2, md: 3 } }}>
          Page not found
        </Typography>
        <Typography variant={"body1"} color={"grey.600"} sx={{ mb: { xs: 4, md: 6 } }}>
          Sorry, the page you are looking for doesn't exist.<br/>
          Here are some helpful links:
        </Typography>
        <Box display={"flex"} sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}>
          <Button onClick={() => navigate(-1)} variant={"outlined"}
                  startIcon={<ArrowBackIcon/>}
                  sx={{ mr: { xs: 0, md: 2 }, color: "primary.contrastText" }}>
            Go back
          </Button>
          <Button onClick={() => navigate("/")} variant={"contained"} sx={{ mb: { xs: 1.5, md: 0 } }}> Take me
            home</Button>
        </Box>
      </Box>
      <Box sx={{
        width: { xs: "70%", sm: "60%", md: "40%", lg: "50%" }, pr: { xs: 0, md: 2 },
        display: "flex", justifyContent: "end"
      }}>
        <ImageListItem>
          <img src="/cloud.png" alt={"cloud"}/>
        </ImageListItem>
      </Box>
    </Box>
  </Box>;
};

export default NotFound;

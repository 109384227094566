export const TRACK_ORDER_INITIAL_STATE = {
  serverMessageType: "",
  serverMessage: "",
  isLoading: true,
  data: {},
  allStatuses: [],
  filteredStatuses: [],
  activeStep: 0
};

export const TRACK_ORDER_ACTIONS = {
  FETCH_START: "FETCH_START",
  FETCH_END: "FETCH_END",
  FETCH_ERROR: "FETCH_ERROR",
  CHANGE_DATA: "CHANGE_DATA",
};

export const trackOrderReducer = (state, { type, payload }) => {
  switch (type) {
    case TRACK_ORDER_ACTIONS.FETCH_START:
      return { ...state, serverMessage: "", serverMessageType: "", isLoading: true };
    case TRACK_ORDER_ACTIONS.FETCH_END:
    case TRACK_ORDER_ACTIONS.FETCH_ERROR:
      return { ...state, ...payload, isLoading: false };
    case TRACK_ORDER_ACTIONS.CHANGE_DATA:
      return { ...state, ...payload };
    default:
      throw new Error(`no action type ${type}`);
  }
};

import { useCallback, useRef, useState } from "react";
import { Link, Box, Paper } from "@mui/material";
import { shopPaths } from "routing/constants";
import { useNavigate } from 'react-router-dom';
import ShopMenuSections                                            from "components/main/ShopBar/ShopMenuSections";
import { randomProductsByCategories, sortProductsBySubCategories } from "utils";
import { useShopSelector } from "store/hooks";
import shopInfoSelectors from "store/shopInfo/shopInfoSelectors";
import MoreInfo from "components/main/ShopBar/MoreInfo";

const menuCloseDelay = 500;
const menuOpenDelay = 500;

const ShopMenu = () => {
  const navigate = useNavigate();
  const { categories, categoriesFull } = useShopSelector(shopInfoSelectors.getCategoriesWithFull);
  const [openedCategoryId, setOpenedCategoryId]  = useState(null);
  const [currentCategories, setCurrentCategories]  = useState({});
  const delayId = useRef(0);
  const delayOpenId = useRef(0);

  const setOpens = useCallback((id) => {
    setOpenedCategoryId(id)
    const sorted = sortProductsBySubCategories(id, categoriesFull);
    const random = randomProductsByCategories(id,categoriesFull,3)
    setCurrentCategories({ sorted, random });
  }, [categoriesFull]);

  const openMenuHandler = useCallback((id) => {
    clearTimeout(delayId.current)

    if (id === openedCategoryId) {
      return
    }

    if (openedCategoryId) {
      return setOpens(id);
    }

    delayOpenId.current = setTimeout(() => {
      setOpens(id);
    }, menuOpenDelay);

  }, [openedCategoryId, setOpens]);

  const wholeMenuLeaveHandler = useCallback(() => {
    clearTimeout(delayOpenId.current);

    delayId.current = setTimeout(() => {
      setOpenedCategoryId(null);
    }, menuCloseDelay);
  }, []);

  const navigateTo = useCallback((path, e) => {
    e?.preventDefault();
    navigate(path);
    setOpenedCategoryId(null);
  }, [navigate])

  const menuItemClickHandler = useCallback((e, id) => {
    e.preventDefault();

    if (!openedCategoryId) {
      openMenuHandler(id);
    } else {
      navigateTo(`${shopPaths.unAuth.productList.path}/${id}`);
    }

  }, [openMenuHandler, navigateTo, openedCategoryId]);

  return (
    <Box sx={{ mt:1, display: { xs: 'none', md: 'flex', position: "relative" }}}>
      <Box
        sx={{ pt: 2, pb:1, width: "100%" }}
        // onMouseEnter={wholeMenuHoverHandler}
        // onFocus={wholeMenuHoverHandler}
        onMouseLeave={wholeMenuLeaveHandler}
        onBlur={wholeMenuLeaveHandler}
      >
        <MoreInfo count={categories.length} />
        <Box sx={{display:"flex", flexWrap:"wrap", justifyContent:"space-between", width:"100%"}}>
          {categories.map(({ name, id }) => (
            <Link
              sx={{
                p: 1,
                textTransform: "capitalize",
                fontSize:{md:"12px", lg:"16px"}
              }}
              key={id}
              color="inherit"
              underline="none"
              href={`${shopPaths.unAuth.productList.path}/${id}`}

              onClick={(e) => menuItemClickHandler(e, id)}
              onMouseEnter={() => openMenuHandler(id)}
              onFocus={() => openMenuHandler(id)}
            >
              {name}
            </Link>
          ))}
        </Box>

        {openedCategoryId &&
          <Paper
            elevation={6}
            sx={{
              minHeight: "435px",
              width: "100%",
              position: "absolute",
              top: "51px",
              left: 0,
              zIndex: 999,
              display: "flex"
            }}
          >
            <ShopMenuSections sections={currentCategories} onProductClick={navigateTo} />
          </Paper>
        }
      </Box>
    </Box>
  );
};

export default ShopMenu;

import { getCustomerVisa } from "store/customer/customerSlice";

const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE"
}

const getDefaultHeaders = (isFormData) => ({
  "credentials": "include",
  "Authorization": `Bearer ${getCustomerVisa()}`,
  ...!isFormData && {
    "Content-Type": "application/json"
  }
});

const request = (url, params, headers) => {
  return fetch(url, {
    cache: "no-cache",
    headers: {
      ...getDefaultHeaders(),
      ...headers?.token && {
        "Authorization": `Bearer ${headers.token}`,
      }
    },
    ...params
  })
    .then((response) => {
      if (response.status === 403) {
        return Promise.reject({
          data: null,
          success: false,
          status: response.status,
          title: "Not authorized"
        })
      }

      return response.json()
    })
};

export const getAPI = (url, headers) => {
  const params = {
    method: HTTP_METHODS.GET,
  };

  return request(url, params, headers);
};

export const postAPI = (url, body, headers) => {
  const params = {
    method: HTTP_METHODS.POST,
    body: JSON.stringify(body)
  };

  return request(url, params, headers);
};

export const putAPI = (url, body) => {
  const params = {
    method: HTTP_METHODS.PUT,
    body: JSON.stringify(body),
  };

  return request(url, params);
};

export const deleteAPI = (url) => {
  const params = {
    method: HTTP_METHODS.DELETE,
  };

  return request(url, params);
};

export const postFilesAPI = (url, body, isFormData = true) => {
  const params = {
    method: HTTP_METHODS.POST,
    body,
  };

  return fetch(url, {
    cache: "no-cache",
    headers: {
      ...getDefaultHeaders(isFormData),
    },
    ...params
  })
    .then((data)=> data.json())
};

export const putFilesAPI = (url, body, isFormData = true) => {
  const params = {
    method: HTTP_METHODS.PUT,
    body,
  };

  return fetch(url, {
    cache: "no-cache",
    headers: {
      ...getDefaultHeaders(isFormData),
    },
    ...params
  })
    .then((data)=> data.json())
};


import { ORDER_TABS } from "./constants";

export const PLACE_ORDER_INITIAL_STATE = {
  serverMessageType: "",
  serverMessage: "",
  data: [],
  changedProducts:[],
  removedProducts:[],
  ratesChanged: [],
  total: 0,
  loading: false,
  sameAsShipping: true,
  openConfirm: false,
  selectedTab: ORDER_TABS.PICKUP,
  isOrderShippable: false,
};

export const PLACE_ORDER_ACTIONS = {
  FETCH_START: "FETCH_START",
  FETCH_END: "FETCH_END",
  FETCH_ERROR: "FETCH_ERROR",
  CHANGE_DATA: "CHANGE_DATA",
  OPEN_CONFIRM: "OPEN_CONFIRM",
  CLOSE_CONFIRM: "CLOSE_CONFIRM",
};

export const placeOrderReducer = (state, { type, payload }) => {
  switch (type) {
    case PLACE_ORDER_ACTIONS.FETCH_START:
      return { ...state, serverMessage: "", serverMessageType: "", loading: true, openConfirm: false };
    case PLACE_ORDER_ACTIONS.FETCH_END:
    case PLACE_ORDER_ACTIONS.CHANGE_DATA:
      return { ...state, ...payload, loading: false };
    case PLACE_ORDER_ACTIONS.OPEN_CONFIRM:
      return { ...state, openConfirm: true }
    case PLACE_ORDER_ACTIONS.CLOSE_CONFIRM:
      return { ...state, ...payload, openConfirm: false }
    case PLACE_ORDER_ACTIONS.FETCH_ERROR:
      return { openConfirm: true,...state, ...payload, loading: false };
    default:
      throw new Error(`no action type ${type}`);
  }
};

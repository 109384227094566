import { memo } from "react";
import PropTypes from "prop-types";
import MyAddressesCard from "pages/customer/MyAddresses/MyAddressesCard";
import { ADDRESS_ACTIONS } from "./constants";
import { Box } from "@mui/material";

const MyAddressesCards = memo(({
                                 myAddresses,
                                 toggleDialog,
                                 toggleAddressDialog,
                                 selectedItem,
                                 radioName,
                                 errors,
                               }) => {

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        flexDirection: "row",
        width:"100%",
        overflow:"hidden"
      }}
    >
      {myAddresses.map((address) =>
        <MyAddressesCard
          key={address.id}
          radioName={radioName}
          errors={errors}
          selectedItem={selectedItem}
          {...address}
          openDeleteDialog={() => toggleDialog(true, address, ADDRESS_ACTIONS.DELETE)}
          openDefaultDialog={() => toggleDialog(true, address, ADDRESS_ACTIONS.SET_DEFAULT)}
          openAddressDialog={() => toggleAddressDialog({ selectedItem: address })}
        />,
      )}
    </Box>
  );
});

MyAddressesCards.propTypes = {
  myAddresses: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleDialog: PropTypes.func.isRequired,
  toggleAddressDialog: PropTypes.func.isRequired,
  radioName: PropTypes.string,
  errors: PropTypes.object,
  selectedItem: PropTypes.number,
};

MyAddressesCards.defaultProps = {
  radioName: "",
  errors: {},
};

export default MyAddressesCards;

import { getAPI, postFilesAPI, putAPI }                                from "../NetService";
import { CUSTOMER_PATH, RESELLER_STATUSES_PATH, RESELLER_UPLOAD_PATH } from "./constants";

export const uploadResellerForm = (data) => {
  const formData = new FormData();
  formData.append("file", data[ 0 ]);
  return postFilesAPI(RESELLER_UPLOAD_PATH, formData)
    .then(({ data, success, title }) => {
      if (success) {
        return title;
      }
      return Promise.reject(title);
    });
};

export const getResellerStatus = () => {
  return getAPI(RESELLER_STATUSES_PATH)
    .then(({ data, success, title }) => {
      if (success) {
        return data;
      }
      return Promise.reject(title);
    });
};

export const getCustomerData = () => {
  return getAPI(CUSTOMER_PATH)
    .then(({ data, success, title }) => {
      if (success) {
        return data;
      }
      return Promise.reject(title);
    });
};

export const updateCustomerData = (id, data) => {
  return putAPI(`${CUSTOMER_PATH}/${id}`, data)
    .then(({ data, success, title }) => {
      if (success) {
        return data;
      }
      return Promise.reject(title);
    });
};


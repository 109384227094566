import PropTypes from "prop-types";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { theme } from "components/theme/constants";
import LocationOn from "@mui/icons-material/LocationOn";

const LocationHeader = ({ city, address, workingDays, workingHours }) => {
  return (
    <>
      <Grid item xs={12} md={6} sx={{ mb: 7}}>
        <Typography variant="h2" sx={{ mb: 2 }} >
          Our location
        </Typography>
        <Typography variant="body1" color={"grey.600"}>
          Get the directions to our location
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} sx={{ mb: 8}}>
        <Box sx={{
          display: "flex"
        }}>
          <Avatar
            sx={{
              backgroundColor: theme.palette.primary.main20,
              width: "48px",
              height: "48px",
              mr: 2
            }}
          >
            <LocationOn
              fontSize="medium"
              color="primary"
            />
          </Avatar>
          <Box>
            <Typography variant="h4" sx={{ color: "primary.contrastText", mb: 1 }}>
              {city}
            </Typography>
            <Typography variant="body1" sx={{ borderBottom: 1, pb:1 }} color={"grey.600"} >
              {address}
            </Typography>
            <Box sx={{
              pt:1.5,
              display: "flex",
              justifyContent: "space-between",
              color:"primary.contrastText"
            }}>
              <Typography variant="body2">
                {workingDays}
              </Typography>
              <Typography variant="body2">
                {workingHours}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

LocationHeader.propTypes = {
  city: PropTypes.string,
  address: PropTypes.string,
  workingDays: PropTypes.string,
  workingHours: PropTypes.string,
};

export default LocationHeader;

import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import WithProgress from "../../../../../components/main/WithProgress";
import { MESSAGES } from "../../../messages";
import {
  SELECTED_SHIPPING_RATE_KEY,
  SELECTED_SHIPPING_TOTAL_CHARGES_KEY,
  SHIPPING_RATE_SELECT_NAME_KEY,
} from "../constants";
import { useShippingRates } from "./useShippingRates";
import {
  Alert,
  AlertTitle,
  Paper,
  Box,
  Avatar,
  Typography,
  FormControl,
  InputLabel,
  NativeSelect,
} from "@mui/material";

const ShippingRates = memo(({ shipTo, orderItems }) => {
  const { register, setValue } = useFormContext();
  const { isLoading, ratesChoices, serverMessage, serverMessageType } = useShippingRates(shipTo, orderItems);
  const ratesChoicesKeys = useMemo(() => [...ratesChoices.keys()], [ratesChoices]);

  const initialValue = useMemo(() => ({ indexedRates: [], selectedTotalCharges: 0 }), []);
  const [selectedRate, setSelectedRate] = useState(initialValue);

  const handleShipRateSelect = useCallback((ev) => {
    setSelectedRate(ratesChoices.get(ev.target.value));
  }, [ratesChoices]);

  useEffect(() => {
    if (!isLoading && !serverMessage) {
      const choices = ratesChoices.get(ratesChoicesKeys[0]);
      if (choices) {
        setSelectedRate(choices);
      }
    }
  }, [isLoading, ratesChoices, ratesChoicesKeys, serverMessage]);

  const selSelected = useCallback(() => {
    setValue(SELECTED_SHIPPING_RATE_KEY, selectedRate.indexedRates);
    setValue(SELECTED_SHIPPING_TOTAL_CHARGES_KEY, selectedRate.selectedTotalCharges);
  }, [selectedRate.indexedRates, selectedRate.selectedTotalCharges, setValue]);

  const selInitial = useCallback(() => {
    setValue(SELECTED_SHIPPING_RATE_KEY, initialValue.indexedRates);
    setValue(SELECTED_SHIPPING_TOTAL_CHARGES_KEY, initialValue.selectedTotalCharges);
  }, [initialValue.indexedRates, initialValue.selectedTotalCharges, setValue]);

  useEffect(() => {
    selSelected();

    return () => {
      selInitial();
    };
  }, [selInitial, selSelected]);

  useEffect(() => {
    selSelected();
  }, [shipTo, selSelected]);

  return (
    <Paper sx={{ p: 2 }}>
      <Box display={"flex"} alignItems={"center"} sx={{ mb: 2 }}>
        <Avatar sx={{ backgroundColor: "primary.light", mr: 2 }}>2</Avatar>
        <Typography variant={"h4"}>{MESSAGES.SHIPPING_OPTIONS}</Typography>
      </Box>
      <WithProgress inProgress={isLoading}>
        {serverMessage
          ?
          <Alert severity={serverMessageType}>
            <AlertTitle>{MESSAGES.RATES_CHOICES_FAILS}</AlertTitle>
            {serverMessage}
          </Alert>
          :
          <FormControl variant="standard">
            <InputLabel htmlFor={SHIPPING_RATE_SELECT_NAME_KEY}>
              {MESSAGES.SHIPPING_OPTIONS}
            </InputLabel>
            <NativeSelect
              inputProps={{
                name: SHIPPING_RATE_SELECT_NAME_KEY,
                id: SHIPPING_RATE_SELECT_NAME_KEY,
              }}
              {...register(SHIPPING_RATE_SELECT_NAME_KEY, { required: true, onChange: handleShipRateSelect, shouldUnregister: true })}
            >
              {ratesChoicesKeys.map((choiceKey) => {
                return <option key={choiceKey} aria-label={choiceKey} value={choiceKey}>{choiceKey}</option>;
              })
              }
            </NativeSelect>
          </FormControl>
        }
        <Box sx={{ display: "flex", mt: 2, minWidth: "175px", maxWidth: "480px", justifyContent: "space-between" }}>
          <Box>
            <Typography variant="h4" sx={{ mb: 1 }}>
              Order Name
            </Typography>
            {orderItems.map((i, index) => {
              return (
                <Typography
                  key={index}
                  variant="body2"
                  maxWidth={180}
                  sx={{
                    width: { xs: 180, sm: "100%" },
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: { xs: "hidden", sm: "visible" },
                  }}
                >
                  {i.itemName || i.product.name}
                </Typography>
              );
            })}
          </Box>
          <Box>
            <Typography variant="h4" sx={{ mb: 1 }}>
              Arrives in
            </Typography>
            {selectedRate.indexedRates.map((selectedChoiceRates, index) => {
              const date = selectedChoiceRates.arrival.date
              const year = date.substring(0, 4)
              const month = date.substring(4, 6)
              const day = date.substring(6)
              const label = `${year}/${month}/${day}`;
              return (
                <Typography key={index} variant="body2">
                  {label}
                </Typography>
              );
            })
            }
          </Box>
        </Box>
      </WithProgress>
    </Paper>
  );
});

ShippingRates.propTypes = {
  shipTo: PropTypes.object.isRequired,
  orderItems: PropTypes.array.isRequired,
};

export default ShippingRates;

export const getSelectedProductOptions = (productOptionTypeGrouped = [], selectedOptions = []) => {

  const selectedOnes = [];

  for (const productOptionType of productOptionTypeGrouped) {
    for (const option of productOptionType.productOptions) {
      if (selectedOptions.includes(option.id)) {
        // debugger
        option.productOptionType = {
          ...productOptionType,
          fields: JSON.parse(productOptionType.fields)
        }
        selectedOnes.push(option)
      }
    }
  }

  return selectedOnes;
};

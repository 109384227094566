export const passwordValidationRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&*+,\-)(.:;<=>?@^_|~]).{8,}$/;
export const emailValidationRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const zipCodeValidateRegex = /^[1-9][0-9]{4}$/;
export const uSAPhoneNumberValidateRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const cardCVVValidateRegex = /^[0-9]{3,4}$/;

export const snackBarVariants = {
  error: "error",
  success: "success",
  default: "default",
  warning: "warning",
  info: "info",
}
import { createTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";

const primaryMain = "#AF995A";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      light: "#E2CA88",
      main: primaryMain,
      main20: alpha(primaryMain, 0.2),
      main30: alpha(primaryMain, 0.3),
      main12: alpha(primaryMain, 0.12),
      main4:alpha(primaryMain, 0.04),
      dark: "#7E6B2F",
      contrastText: "#212121"
    },
    secondary: {
      light: "#8B9EE1",
      main: "#5A70AF",
      dark: "#28457F",
      contrastText: "#FFFFFF",
      contrastText23: alpha("#FFFFFF", 0.23)
    },
    background: {
      default: "#212121",
    },
    appBar: {
      main: "#FFFFFF",
    },
    badge: {
      main: "#E2CA88",
    }
  }
});

theme.palette.success.success30 = alpha(theme.palette.success.main, 0.3);

theme.typography.fontFamily = `"Roboto", "-apple-system","sans-serif"`;

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: "40px",
  fontWeight: 700,
  letterSpacing: 0,
  [theme.breakpoints.up("sm")]: {
    fontSize: '56px',
  },
};

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: "34px",
  fontWeight: 700,
  letterSpacing: 0.25
};

theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: "24px",
  fontWeight: 500,
  letterSpacing: 0
};

theme.typography.h4 = {
  ...theme.typography.h4,
  fontSize: "20px",
  fontWeight: 500,
  letterSpacing: 0.15
};

theme.typography.body1 = {
  ...theme.typography.body1,
  fontSize: "18px"
};
theme.typography.body2 = {
  ...theme.typography.body2,
  fontSize: "16px"
};
theme.typography.body3 = {
  ...theme.typography.body2,
  fontSize: "14px"
};
theme.typography.body4 = {
  ...theme.typography.body2,
  fontSize: "12px",
  [theme.breakpoints.up("sm")]: {
    fontSize: '14px',
  },
};
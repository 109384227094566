export const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Order No"
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status"
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Total"
  },
  {
    id: "created",
    numeric: true,
    disablePadding: false,
    label: "Date Purchased"
  },
  // {
  //   id: "reorder",
  //   numeric: false,
  //   disablePadding: true,
  //   label: ""
  // }
];

export const mobileHeadCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Order No"
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status"
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Total"
  }
];

function descendingComparator(a, b, orderBy) {
  if (b[ orderBy ] < a[ orderBy ]) {
    return -1;
  }
  if (b[ orderBy ] > a[ orderBy ]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[ 0 ], b[ 0 ]);
    if (order !== 0) {
      return order;
    }
    return a[ 1 ] - b[ 1 ];
  });
  return stabilizedThis.map((el) => el[ 0 ]);
}
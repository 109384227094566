import * as React     from "react";
import Box            from "@mui/material/Box";
import Tab            from "@mui/material/Tab";
import TabContext     from "@mui/lab/TabContext";
import TabList        from "@mui/lab/TabList";
import TabPanel       from "@mui/lab/TabPanel";
import { Typography } from "@mui/material";
import ProductTemplates from "pages/main/Product/components/ProductTemplates";

const ProductTabs = ({ header, description, templates }) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return <Box sx={{ width: "100%", typography: "body1" }}>
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleChange}  aria-label="Product tabs">
          <Tab label="Description" sx={{textTransform:"none"}} value="1"/>
          <Tab label="Templates" sx={{textTransform:"none"}} value="2"/>
        </TabList>
      </Box>
      <TabPanel value="1">
        <Typography variant={"body1"} sx={{mb:2}}>
          {header}
        </Typography>
        <Typography variant={"body3"} color={"grey.600"} sx={{whiteSpace:"pre-line"}}>
          <div className="content" dangerouslySetInnerHTML={{__html: description}}></div>
        </Typography>
      </TabPanel>
      <TabPanel value="2">
        <ProductTemplates templates={templates} />
      </TabPanel>
    </TabContext>
  </Box>;

};

export default ProductTabs;
import { Box, FormControlLabel, Checkbox, Paper, Typography, Button } from "@mui/material";
import WithProgress from "../../../components/main/WithProgress";
import useShoppingBag                                                                                   from "./useShoppingBag";
import { ConfirmDialog }                                                                                from "../components";
import { shopPaths }                                                                                    from "routing/constants";
import ShoppingBagItems                                                                                 from "pages/customer/ShoppingBag/ShoppingBagItems";
import { MESSAGES } from "../messages";

const ShoppingBag = () => {
  const {
    data, dataLoading, loadingConfirm, totalPrice, removeItem, toggleDialog, toggleCheck, checkedItems, allChecked,
    openConfirm, onCheckout, toggleAllChecked, navigate
  } = useShoppingBag();

  return (
    <WithProgress inProgress={dataLoading}>
      <Box sx={{ backgroundColor: "grey.50", mt: 0.5 }}>
        <Box sx={{ display: { md: "flex" }, width: "100%" }}>
          <Box sx={{ flexBasis: { md: "65%" } }}>
            <Paper sx={{ px: 2, py: 3, mt: 3 }}>
              <Typography variant={"h2"}>{`${MESSAGES.SHOPPING_BAG} (${data.length})`}</Typography>
              {data?.length > 1 &&
                <FormControlLabel
                  control={<Checkbox checked={allChecked} onChange={toggleAllChecked} color="primary" sx={{ mr: 0.5 }}/>}
                  label={MESSAGES.SELECT_ALL}
                  sx={{ mt: 2, ml: 0.5 }}
                />
              }
            </Paper>
            {data.length
              ?
              <ShoppingBagItems
                items={data}
                onCheckout={onCheckout}
                toggleCheck={toggleCheck}
                checkedItems={checkedItems}
                toggleDialog={toggleDialog}
              />
              :
              <Button
                sx={{ mt: 2 }}
                fullWidth
                variant={"contained"}
                onClick={() => {
                  navigate(`${shopPaths.unAuth.home.path}`);
                }}
              >
                {MESSAGES.CONTINUE_SHOPPING}
              </Button>
            }
          </Box>
          <Box sx={{ ml: { md: 3 }, width: { md: "35%" } }}>
            <Paper sx={{ p: 4, mt: 3 }}>
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant={"body1"} color={"grey.600"}>{MESSAGES.SUBTOTAL}:</Typography>
                <Typography variant={"h4"} color={"primary.contrastText"}>{`$ ${totalPrice}`}</Typography>
              </Box>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={!checkedItems.length || totalPrice === 0}
                sx={{ mt: 2 }}
                onClick={onCheckout}
              >
                {MESSAGES.CHECKOUT_NOW}
              </Button>
            </Paper>
          </Box>
        </Box>
        <ConfirmDialog
          open={openConfirm}
          title={MESSAGES.CONFIRM_TITLE}
          message={MESSAGES.CONFIRM_MESSAGE}
          confirmFunc={removeItem}
          toggleDialog={toggleDialog}
          loading={loadingConfirm}
        />
      </Box>
    </WithProgress>
  );
};

export default ShoppingBag;

export const MY_ORDERS_INITIAL_STATE = {
  serverMessageType: "",
  serverMessage: "",
  requestLoading: false,

  myOrders: [],
  statuses:[],
  order: "asc",
  orderBy: "createdAt",
  rowsPerPage: 5,
  page: 0
};

export const MY_ORDERS_ACTIONS = {
  FETCH_START: "FETCH_START",
  FETCH_END: "FETCH_END",
  FETCH_ERROR: "FETCH_ERROR",
  CHANGE_DATA: "CHANGE_DATA",
};

export const myOrdersReducer = (state, { type, payload }) => {
  switch (type) {
    case MY_ORDERS_ACTIONS.FETCH_START:
      return { ...state, serverMessage: "", serverMessageType: "", requestLoading: true };
    case MY_ORDERS_ACTIONS.FETCH_END:
      return { ...state, ...payload, requestLoading: false };
    case MY_ORDERS_ACTIONS.FETCH_ERROR:
      return { ...state, ...payload, requestLoading: false };
    case MY_ORDERS_ACTIONS.CHANGE_DATA:
      return { ...state, ...payload };
    default:
      throw new Error(`no action type ${type}`);
  }
};

import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import WithProgress from "../../../../../components/main/WithProgress";
import { theme } from "../../../../../components/theme/constants";
import { MESSAGES } from "../../../messages";
import { ConfirmDialog } from "../../../components";
import SelectedAddress from "../../../MyAddresses/SelectedAddress";
import MyAddressesRadioWrapped from "../../../MyAddresses/MyAddressesRadioWrapped";
import { ADDRESS_TYPES, SELECT_TIME } from "../constants";
import AddressButtons from "./AddressButtons";
import AddressErrorsContainer from "./AddressErrorsContainer";
import { useShowAddresses } from "./useShowAddresses";

const AddressPaper = memo(({
                        myAddresses,
                        toggleDialog,
                        toggleAddressDialog,
                        loadingConfirm,
                        openConfirm,
                        confirmTitle,
                        confirmInfo,
                        confirmFunc,
                        header,
                        point,
                        requestLoading,
                      }) => {

  const { shippingAddressKeyName, getValues, handleShippingAddressChange } = useFormContext();
  const selectedOne = getValues(shippingAddressKeyName);

  const {
    hideAddressesView,
    showAddressesView,
    showMore,
    handleShowMore,
    shouldShowMore,
    addresses,
    selectedAddress,
    showAddresses,
  } = useShowAddresses(myAddresses, selectedOne);

  const addressChangeHandler = useCallback((...args) => {
    handleShippingAddressChange(...args);
    setTimeout(() => {
      hideAddressesView();
    }, SELECT_TIME);
  }, [hideAddressesView, handleShippingAddressChange]);

  return <Paper sx={{ px: 2, py: 2, my: 2 }}>
    <Box sx={{ display: { sm: "flex" }, justifyContent: "space-between" }}>
      <Box display={"flex"} alignItems={"center"}>
        <Avatar sx={{ backgroundColor: "primary.light", mr: 2 }}>{point}</Avatar>
        <Typography variant={"h4"}>{header} Address</Typography>
      </Box>

      <AddressButtons
        showAddresses={showAddresses}
        hideAddressesView={hideAddressesView}
        showAddressesView={showAddressesView}
        toggleAddressDialog={toggleAddressDialog}
      />
    </Box>

    {addresses.length ? showAddresses
        ?
        <Box sx={{ mt: 2 }}>
          <WithProgress inProgress={requestLoading}>
            <MyAddressesRadioWrapped
              myAddresses={addresses}
              addressChangeHandler={addressChangeHandler}
              toggleDialog={toggleDialog}
              toggleAddressDialog={toggleAddressDialog}
              radioName={shippingAddressKeyName}
              selected={selectedAddress}
            />
          </WithProgress>
          {confirmFunc &&
            <ConfirmDialog
              open={openConfirm}
              title={confirmTitle}
              message={confirmInfo}
              confirmFunc={confirmFunc}
              toggleDialog={toggleDialog}
              loading={loadingConfirm}
            />
          }
          {shouldShowMore &&
            <Button onClick={handleShowMore} endIcon={showMore ? <ArrowDropUp/> : <ArrowDropDown/>}>
              {showMore ? MESSAGES.SHOW_LESS : MESSAGES.SHOW_MORE}
            </Button>
          }
        </Box>
        :
        <SelectedAddress address={selectedAddress}/>
      :
      <Typography variant={"subtitle1"} color={theme.palette.error.main}>{MESSAGES.PLS_ADD}</Typography>
    }

    <AddressErrorsContainer addressType={ADDRESS_TYPES.SHIPPING} />
  </Paper>;
});

AddressPaper.propTypes = {
  point: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
};


export default AddressPaper;

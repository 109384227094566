import { Navigate, Route, Routes }                                  from "react-router-dom";
import { shopPaths }                                                from "routing/constants";
import { NotFound }                                                 from "pages";
import { ShopPageWrapper }                                          from "components/main";
import { ContactUs, Home, PrivacyPolicy, Product, Products, Terms } from "pages/main";
import { AuthWrapper, EmailConfirm, Forgot, Login, Resend, Reset, SignUp } from "pages/auth";
import { useSelector } from "react-redux";
import customerSelectors from "store/customer/customerSelectors";
import {
  EmptyWrapper,
  MyAccountSettings,
  MyAddresses,
  MyOrders,
  MyPaymentMethods,
  MyWrapper
}                                              from "pages/customer";
import { PlaceOrder, ShoppingBag, TrackOrder } from "pages/customer/ShoppingBag";
import CompletedProjects from "../pages/main/CompletedProjects/CompletedProjects";
import Project           from "../pages/main/CompletedProjects/Project/Project";

const ShopRouter = () => {
  const isLoggedIn = useSelector(customerSelectors.getIsLoggedIn);

  const {
    auth: {
      myAccountSettings, myAddresses, myOrders, myPaymentMethods, shoppingBag, placeOrder,
      trackOrder
    },
    unAuth: {
      home, contactUs, product, productList, signUp, emailConfirm, login, forgot, resend, reset, terms, privacy, completedProjects
    }
  } = shopPaths;

  return (
    <Routes>
      <Route path="/" element={<ShopPageWrapper/>}>
        <Route path={home.path} element={<Home/>}/>
        <Route path={`${product.path}/:name-:id`} element={<Product/>}/>
        <Route path={`${product.path}/:name-:id/:shoppingBagItemId`} element={<Product/>}/>
        <Route path={`${productList.path}/:id`} element={<Products/>}/>
        <Route path={completedProjects.path} element={<CompletedProjects/>}/>
        <Route path={`${completedProjects.path}/:id`} element={<Project/>}/>
        <Route path={contactUs.path} element={<ContactUs/>}/>
        <Route path={terms.path} element={<Terms/>}/>
        <Route path={privacy.path} element={<PrivacyPolicy/>}/>

        {/*unAuth Paths*/}
        <Route element={<EmptyWrapper/>}>
          <Route path={shoppingBag.path} element={<ShoppingBag/>}/>
          <Route path={placeOrder.path} element={<PlaceOrder/>}/>
          <Route path={`${trackOrder.path}/:orderId`} element={<TrackOrder/>}/>
        </Route>

        {/*paths only for customers with visa*/}
        <Route
          path={"my"}
          element={isLoggedIn
            ? <EmptyWrapper/>
            : <Navigate to={home.path} replace/>
          }
        >
          <Route path={shoppingBag.path} element={<ShoppingBag/>}/>
          <Route path={placeOrder.path} element={<PlaceOrder/>}/>
          <Route path={`${trackOrder.path}/:orderId`} element={<TrackOrder/>}/>
        </Route>
        <Route
          path={"my"}
          element={isLoggedIn
            ? <MyWrapper/>
            : <Navigate to={home.path} replace/>
          }
        >
          <Route path={myOrders.path} element={<MyOrders/>}/>
          <Route path={myAccountSettings.path} element={<MyAccountSettings/>}/>
          <Route path={myAddresses.path} element={<MyAddresses/>}/>
          <Route path={myPaymentMethods.path} element={<MyPaymentMethods/>}/>
        </Route>

        {/*sign up paths */}
        <Route
          element={isLoggedIn
            ? <Navigate to={myOrders.fullPath} replace/>
            : <AuthWrapper/>
          }
        >
          <Route path={signUp.path} element={<SignUp/>}/>
          <Route path={emailConfirm.path} element={<EmailConfirm/>}/>
          <Route path={login.path} element={<Login/>}/>
          <Route path={reset.path} element={<Reset/>}/>
          <Route path={forgot.path} element={<Forgot/>}/>
          <Route path={resend.path} element={<Resend/>}/>
        </Route>
        <Route path="*" element={<NotFound/>}/>
      </Route>
    </Routes>
  );
};

export default ShopRouter;

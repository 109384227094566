import { Box, Typography }        from "@mui/material";
import WorkCard                   from "./WorkCard";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import LanguageOutlinedIcon       from "@mui/icons-material/LanguageOutlined";
import LocalOfferOutlinedIcon     from "@mui/icons-material/LocalOfferOutlined";
import HandshakeOutlinedIcon      from "@mui/icons-material/HandshakeOutlined";
import SupportAgentOutlinedIcon   from "@mui/icons-material/SupportAgentOutlined";
import ContactsOutlinedIcon       from "@mui/icons-material/ContactsOutlined";

const WorkWithUs = () => {
  return <Box
    sx={{ maxWidth: "1152px", mx: "auto", display: { lg: "flex" }, justifyContent: "space-between", px: { xs: 2, sm: 3, md: 10.5, lg: 0 }, py: { xs: 12 } }}>
    <Box sx={{ mr: { lg: 7 }, width: { lg: "35%", position:"relative" } }}>
      <Typography variant="h2" sx={{ mb: 2.5 }}>
        Why work with us?
      </Typography>
      <Typography variant="body1" sx={{ color: "grey.600" }}>
        Each design is the result of creative minds and expertise working hand in hand to achieve excellence at every
        opportunity. Partnering with you will be a great experience for us.
      </Typography>
      <Box sx={{ display:{xs:"none", lg:"block"},position: "absolute", left: "0", bottom: "20%", width: "250px"}}>
        <img src={"1.png"} alt={"triangle"}/>
      </Box>
    </Box>
    <Box
      sx={{ pt: { xs: 3, lg: 0 }, width: { lg: "65%" }, display: { sm: "flex" }, flexWrap: "wrap", justifyContent: "space-between" }}>
      <WorkCard
        Icon={LocalPrintshopOutlinedIcon}
        title={"Materials"}
        info={"You can find the right look for your business card by choosing from standard to specialty luxe papers."}
        sx={{ mr: { sm: 3 }, width: { sm: "47.5%", md: "48%" } }}
      />
      <WorkCard
        Icon={LanguageOutlinedIcon}
        title={"Everything online"}
        info={"From design to payment, we carry out printing services online, saving you precious time. "}
        sx={{ width: { sm: "47.5%", md: "48%" } }}
      />
      <WorkCard
        Icon={LocalOfferOutlinedIcon}
        title={"Special offers"}
        info={"We appreciate our loyal customers by offering regular discounts and special deals. "}
        sx={{ mr: { sm: 3 }, width: { sm: "47.5%", md: "48%" } }}
      />
      <WorkCard
        Icon={HandshakeOutlinedIcon}
        title={"Personalization"}
        info={"We welcome any crazy ideas and are ready to fulfill your projects to go all the way to an extra fancy design."}
        sx={{ width: { sm: "47.5%", md: "48%" } }}
      />
      <WorkCard
        Icon={SupportAgentOutlinedIcon}
        title={"Consultation"}
        info={"Start your experience with us by reaching out to our customer support for a professional design consultation. "}
        sx={{ mr: { sm: 3 }, mb: { xs: 3, sm: 0 }, width: { sm: "47.5%", md: "48%" } }}
      />
      <WorkCard
        Icon={ContactsOutlinedIcon}
        title={"Everything for you"}
        info={"Trusted by partners, we provide an excellent online experience and all-in-one printing services."}
        sx={{ mb: 0, width: { sm: "47.5%", md: "48%" } }}
      />
    </Box>
  </Box>;
};

export default WorkWithUs;
import { Typography, Box, Button, Paper } from "@mui/material";
import { useCookies }                     from "react-cookie";

const COOKIES_ACCEPTED = "cookiesAccepted";

const AcceptCookies = () => {
  const [cookies, setCookie] = useCookies();
  const accepted = cookies[ COOKIES_ACCEPTED ];
  const acceptHandler = () => setCookie(COOKIES_ACCEPTED, true, { maxAge: 31556952000 }); //one year

  return (
    !accepted &&
    <Paper elevation={4} sx={{ width: "330px", p: 2, position: "fixed", top: "1em", left: "1em", zIndex: 99999 }}>
      <Typography variant="h4" gutterBottom>
        🍪 We value your privacy
      </Typography>
      <Typography variant="body3">
        Cookies help us deliver a better experience on our website. By using our website, you agree to the use of
        cookies. Learn more in our Privacy Policy.
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Button variant="contained" size="small" onClick={acceptHandler}>
          Got it
        </Button>
      </Box>
    </Paper>
  );
};

export default AcceptCookies;

import { BEST_SELLER_PATH, PRODUCT_LIST_PATH, PRODUCT_PATH, RANDOM_PATH, SEARCH_PATH } from "./constants";
import { getAPI }                                                                      from "services/api/NetService";

export const getProductListRequest = (id) => {
    return getAPI(`${PRODUCT_LIST_PATH}/${id}`)
    .then(({ data: prodList, success, title }) => {
      if (success) {
        return prodList;
      }
  
      return Promise.reject(title)
    })
  };

export const getBestSellerProductsRequest = () => {
  return getAPI(BEST_SELLER_PATH)
  .then(({ data: bestSellerProducts, success, title }) => {
    if (success) {
      return bestSellerProducts;
    }
    return Promise.reject(title)
  })
};

export const searchByProductNameRequest = (name) => {
  return getAPI(`${SEARCH_PATH}?name=${name}`)
  .then(({ data, success, title }) => {
    if (success) {
      return data;
    }
    return Promise.reject(title)
  })
};

export const getRandomProductsRequest = (count, categoryId ) => {
  const urlPath = categoryId ? `&productCategoryId=${categoryId}` : ""
  return getAPI(`${ RANDOM_PATH }?randomCount=${count}${urlPath}`)
  .then(({ data: randomProducts, success, title }) => {
    if (success) {
      return randomProducts;
    }
    return Promise.reject(title)
  })
};

export const getProductRequest = (id) => {
  return getAPI(`${PRODUCT_PATH}/${id}`)
  .then(({ data: product, success, title }) => {
    if (success && product) {
      return product;
    }

    return Promise.reject(title)
  })
};
import { Box, Typography }      from "@mui/material";
import { Autoplay, Navigation } from "swiper";
import { ImageCard }            from "../../../Products/components";
import { Swiper, SwiperSlide }  from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import BoltOutlinedIcon         from "@mui/icons-material/BoltOutlined";
import { Link }                 from "react-router-dom";
import { theme }                from "../../../../../components/theme/constants";
import { productPathBuilder } from "../../../../../utils/productHelper";

const Carousel = ({ data, title }) => {
  const textColor = theme.palette.primary.contrastText

  return <Box sx={{ px: { xs: 2, sm: 3, md: 10.5, lg: 0 }, m: "auto", maxWidth: "1152px" }}>
    <Typography color="primary.contrastText" variant="h2" sx={{ display: "flex", alignItems: "center", mb: 6 }}>
      <BoltOutlinedIcon fontSize={"large"} sx={{ color: "primary.main", mr: 2 }}/>
      {title}
    </Typography>
    <Swiper
      style={{
        "--swiper-navigation-color": theme.palette.primary.main,
        "--swiper-navigation-size":"20px",
        padding: "20px 35px"
      }}
      slidesPerView={1}
      slidesPerGroup={1}
      spaceBetween={38}
      allowTouchMove={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      }}
      loop={true}
      navigation={true}
      modules={[Navigation, Autoplay]}
      breakpoints={{
        600: {
          slidesPerView: 2
        },
        900: {
          slidesPerView: 3
        }
      }}
    >
      {data.map(prod => <SwiperSlide key={prod.id}>
          <Link to={productPathBuilder(prod.name, prod.id)} style={{ textDecoration: "none", color: textColor }}>
            <ImageCard
               title={prod.name} url={prod.mainImageURI} altText={prod.mainImageAltText} />
          </Link>
        </SwiperSlide>
      )}
    </Swiper>
  </Box>;
};

export default Carousel;

import { useCallback, useEffect, useReducer, useState } from "react";
import useShopSnackbar                                  from "hooks/useShopSnackbar";
import { MY_ORDERS_ACTIONS, MY_ORDERS_INITIAL_STATE, myOrdersReducer } from "./myOrdersReducer";
import {
  getOrders,
  getStatusesRequest
}                                                                      from "../../../services/api/Orders/OrdersService";
import { useNavigate }                                                 from "react-router-dom";

export const useMyOrders = () => {
  const [state, dispatch] = useReducer(myOrdersReducer, MY_ORDERS_INITIAL_STATE);
  useShopSnackbar(state.serverMessage, state.serverMessageType);
  const [open, setOpen] = useState(null);

  const navigate = useNavigate();
  const getOrdersData = useCallback(() => {
    let statuses = {};
    dispatch({ type: MY_ORDERS_ACTIONS.FETCH_START });
    getStatusesRequest().then(arr => {
      statuses = arr;
    });
    getOrders().then((myOrders) => {
      dispatch({
        type: MY_ORDERS_ACTIONS.FETCH_END,
        payload: { myOrders, statuses }
      });
    });
  }, []);

  useEffect(() => {
    getOrdersData();
  }, [getOrdersData]);

  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === "asc";
    dispatch({
      type: MY_ORDERS_ACTIONS.CHANGE_DATA,
      payload: {
        order: isAsc ? "desc" : "asc",
        orderBy: property
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: MY_ORDERS_ACTIONS.CHANGE_DATA,
      payload: {
        page: newPage
      }
    });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: MY_ORDERS_ACTIONS.CHANGE_DATA,
      payload: {
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0
      }
    });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    state.page > 0 ? Math.max(0, (1 + state.page) * state.rowsPerPage - state.myOrders.length) : 0;

  return {
    ...state,
    open,
    setOpen,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    emptyRows,
    navigate
  };
};

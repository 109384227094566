import React, { memo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import {
  Box, Paper, Typography, Avatar, TextField, NativeSelect, FormControl, FormGroup, InputLabel,
  InputAdornment, FormHelperText, FormControlLabel, Checkbox, Button,
} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {
  cardNumber,
  CARD_NUMBER_KEY,
  cardCode,
  CARD_CODE_KEY,
  CARD_DETAILS_KEY,
  expirationDateYear,
  expirationDateMonth,
  MONTHS,
  YEARS,
  CARDS_MAP, ADDRESS_TYPES, SELECT_TIME,
} from "pages/customer/ShoppingBag/PlaceOrder/constants";
import { useFormContext } from "react-hook-form";
import PaymentCardImages
  from "pages/customer/ShoppingBag/PlaceOrder/components/PaymentCardImages";
import { theme } from "../../../../../components/theme/constants";
import { cardCVVValidateRegex } from "../../../../constants";
import { MESSAGES } from "../../../messages";
import WithProgress from "../../../../../components/main/WithProgress";
import { ConfirmDialog } from "../../../components";
import SelectedAddress from "../../../MyAddresses/SelectedAddress";
import AddressButtons from "./AddressButtons";
import AddressErrorsContainer from "./AddressErrorsContainer";
import { useShowAddresses } from "./useShowAddresses";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import MyAddressesRadioWrapped from "../../../MyAddresses/MyAddressesRadioWrapped";

const PaymentCardDetails = memo(({
                              handleCardDateChange,
                              sameAsShipping,
                              myAddresses,
                              handleSameAsShippingChange,
                              isWithoutShipping,
                              openConfirm,
                              confirmTitle,
                              requestLoading,
                              confirmFunc,
                              toggleAddressDialog,
                              confirmInfo,
                              loadingConfirm,
                              toggleDialog,
                            }) => {
  const { register, errors, getValues, billingAddressKeyName, handleBillingAddressChange } = useFormContext();
  const [currentCard, setCurrentCard] = useState();
  const [currentCardValue, setCurrentCardValue] = useState("");
  const [currentCVVValue, setCurrentCVVValue] = useState("");
  const selectedOne = getValues(billingAddressKeyName);
  const {
    showMore,
    showAddresses,
    hideAddressesView,
    showAddressesView,
    handleShowMore,
    shouldShowMore,
    addresses,
    selectedAddress,
  } = useShowAddresses(myAddresses, selectedOne, !sameAsShipping);

  const addressChangeHandler = useCallback((...args) => {
    handleBillingAddressChange(...args);
    setTimeout(() => {
      hideAddressesView();
    }, SELECT_TIME);
  }, [hideAddressesView, handleBillingAddressChange]);

  const cardNumberChangeHandler = useCallback(({ target: { value } }) => {
    const firstNum = +value.charAt(0);
    setCurrentCard(CARDS_MAP[firstNum]);

    const cardValue = firstNum === 3 // american express case
      ?
      value.replace(/\D/g, "").match(/(\d{0,4})(\d{0,6})(\d{0,5})/)
      :
      value.replace(/\D/g, "").match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);

    if (cardValue) {
      const value = !cardValue[2]
        ? cardValue[1]
        : `${cardValue[1]}-${cardValue[2]}${`${cardValue[3] ? `-${cardValue[3]}` : ""}`}${`${cardValue[4] ? `-${cardValue[4]}` : ""}`}`;

      setCurrentCardValue(value);
    }
  }, []);

  const handleCVVChange = useCallback((e) => {
    setCurrentCVVValue(e.target.value);
  }, []);

  return (
    <Paper sx={{ px: 2, py: 2, my: 2 }}>
      <Box sx={{ display: { sm: "flex" }, mb: 3, justifyContent: "space-between" }}>
        <Box display={"flex"} alignItems={"center"}>
          <Avatar sx={{ backgroundColor: "primary.light", mr: 2 }}>
            {isWithoutShipping ? 3 : 2}
          </Avatar>
          <Typography variant={"h4"}>Payment details</Typography>
        </Box>
      </Box>
      <PaymentCardImages/>
      <Box>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Card Number"
          id={CARD_NUMBER_KEY}
          name={CARD_NUMBER_KEY}
          inputProps={{
            inputMode: "numeric",
            autoComplete: "billing cc-number",
            "credit-card": "credit-card",
            pattern: "[0-9]*",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {currentCard
                  ?
                  <img width="40px" src={`/payment-cards/${currentCard}.png`} alt={currentCard}/>
                  :
                  <CreditCardIcon fontSize="large"/>
                }
              </InputAdornment>
            ),
          }}
          {...register(CARD_NUMBER_KEY, {
            required: `Card Number is required.`,
            onChange: cardNumberChangeHandler,
          })
          }
          {...errors[CARD_DETAILS_KEY]?.[cardNumber] &&
          {
            error: true,
            helperText: errors[CARD_DETAILS_KEY][cardNumber].message,
          }
          }
          value={currentCardValue}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "flex" },
          borderBottom: "1px solid lightgrey",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <FormGroup>
          <Box
            sx={{
              display: { xs: "flex" },
              justifyContent: { xs: "start", sm: "space-between" },
              alignItems: "baseline",
            }}>
            <FormControl variant="standard">
              <InputLabel htmlFor={expirationDateMonth}>
                Month
              </InputLabel>
              <NativeSelect
                inputProps={{
                  name: expirationDateMonth,
                  id: expirationDateMonth,
                }}
                {...register(expirationDateMonth, {
                  required: "Expiration Month and Year are required",
                  onChange: handleCardDateChange,
                })
                }
              >
                <option aria-label="None" value=""/>
                {
                  Object.entries(MONTHS).map(([month, number]) =>
                    <option key={number} value={number}>{month}</option>)
                }
              </NativeSelect>
            </FormControl>
            <Typography sx={{ mx: { xs: 1, sm: 2 } }}>/</Typography>
            <FormControl variant="standard">
              <InputLabel htmlFor={expirationDateYear}>
                Year
              </InputLabel>
              <NativeSelect
                sx={{ minWidth: "60px" }}
                inputProps={{
                  name: expirationDateYear,
                  id: expirationDateYear,
                }}
                {...register(expirationDateYear, {
                  required: "Expiration Month and Year are required",
                  onChange: handleCardDateChange,
                })
                }
                on
              >
                <option aria-label="None" value=""/>
                {YEARS.map((number) => <option key={number} value={number}>{number}</option>)}
              </NativeSelect>
            </FormControl>
          </Box>
          {(errors?.[expirationDateMonth] || errors?.[expirationDateYear]) &&
            <FormHelperText
              error>{errors?.[expirationDateMonth]?.message || errors?.[expirationDateYear]?.message}</FormHelperText>
          }
        </FormGroup>

        <TextField
          margin="normal"
          required
          sx={{ width: { xs: 90, sm: 150 } }}
          label="CVV"
          id={CARD_CODE_KEY}
          name={CARD_CODE_KEY}
          value={currentCVVValue}
          inputProps={{
            inputMode: "numeric",
          }}
          {...register(CARD_CODE_KEY, {
            required: `Card Code (CVV) is required.`,
            onChange: handleCVVChange,
            pattern: {
              value: cardCVVValidateRegex,
              message: "Card CVV should be 3 or 4 digits.",
            }
          })
          }
          {...errors[CARD_DETAILS_KEY]?.[cardCode] &&
          {
            error: true,
            helperText: errors[CARD_DETAILS_KEY][cardCode].message,
          }
          }
        />
      </Box>
      {!isWithoutShipping &&
        <FormControlLabel
          sx={{ mt: 1 }}
          control={
            <Checkbox
              onChange={handleSameAsShippingChange}
              checked={sameAsShipping}
              color="primary"
              sx={{ mr: 0.5 }}
            />
          }
          label={MESSAGES.ADR_BILL_CHECK}
        />
      }
      {(isWithoutShipping || !sameAsShipping) &&
        <>
          <Box>
            <WithProgress inProgress={requestLoading}>
              {addresses.length ? showAddresses
                  ?
                  <MyAddressesRadioWrapped
                    myAddresses={addresses}
                    addressChangeHandler={addressChangeHandler}
                    toggleDialog={toggleDialog}
                    toggleAddressDialog={toggleAddressDialog}
                    radioName={billingAddressKeyName}
                    selected={selectedAddress}
                  />
                  :
                  <SelectedAddress address={selectedAddress} />
                :
                <Typography variant={"subtitle1"} color={theme.palette.error.main}>{MESSAGES.PLS_ADD}</Typography>
              }
            </WithProgress>

            <AddressErrorsContainer addressType={ADDRESS_TYPES.BILLING} />

            {confirmFunc &&
              <ConfirmDialog
                open={openConfirm}
                title={confirmTitle}
                message={confirmInfo}
                confirmFunc={confirmFunc}
                toggleDialog={toggleDialog}
                loading={loadingConfirm}
              />
            }
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mt: 2 }}>
            {showAddresses && shouldShowMore &&
              <Button
                onClick={handleShowMore}
                endIcon={showMore ? <ArrowDropUp/> : <ArrowDropDown/>}
              >
                {showMore ? MESSAGES.SHOW_LESS : MESSAGES.SHOW_MORE}
              </Button>
            }
            <AddressButtons
              showAddresses={showAddresses}
              hideAddressesView={hideAddressesView}
              showAddressesView={showAddressesView}
              toggleAddressDialog={toggleAddressDialog}
            />
          </Box>
        </>
      }
    </Paper>
  );
});

PaymentCardDetails.propTypes = {
  handleCardDateChange: PropTypes.func.isRequired,
  handleSameAsShippingChange: PropTypes.func.isRequired,
  isWithoutShipping: PropTypes.bool.isRequired,
};

export default PaymentCardDetails;

import { ThemeProvider } from "@mui/material";
import { theme } from "components/theme/constants";

const ShopThemeProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>
);

export default ShopThemeProvider;

import {
  CUSTOMER_FILE_EXTENSION_MATCHING,
  FILE_MAX_ITEMS,
  FILE_MAX_SIZE,
  FILE_MAX_SIZE_LABEL
} from "pages/main/Product/constants";

export const handleImageValidate = (selectedImages, required, isSubmitting) => {
  const count = selectedImages.length;
  if (required && !count && isSubmitting) {
    return "Images are required.";
  }

  if (count > FILE_MAX_ITEMS) {
    return `Images count should not be greater than ${FILE_MAX_ITEMS}`;
  }

  const inValidNames = [];
  const inValidFormat = [];
  for (let i = 0; i < count; i++) {
    const { size, name } = selectedImages[i];
    if (size > FILE_MAX_SIZE) {
      inValidNames.push(name);
    }

    if (!name.match(CUSTOMER_FILE_EXTENSION_MATCHING)) {
      inValidFormat.push(name);
    }
  }

  if (inValidNames.length) {
    return `Please upload files smaller ${FILE_MAX_SIZE_LABEL} (${inValidNames.join(", ")})`
  }

  if (inValidFormat.length) {
    return `Files should be in jpg, jpeg, png or pdf formats (${inValidFormat.join(", ")})`
  }
};

import { useNavigate }               from "react-router-dom";
import { shopPaths }                 from "routing/constants";
import { Divider } from "@mui/material";
import { ListItem }                  from "@mui/material";
import { List }                      from "@mui/material";
import { Stepper }                   from "@mui/material";
import { Step }                      from "@mui/material";
import { StepLabel }                 from "@mui/material";
import { Paper }         from "@mui/material";
import { Avatar }        from "@mui/material";
import { Button }        from "@mui/material";
import { Typography }    from "@mui/material";
import { Box }           from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import WithProgress      from "../../../../components/main/WithProgress";
import OrderPaper        from "../components/OrderPaper";
import MyAddressCard     from "../../MyAddresses/MyAddressesCard";
import { theme }         from "components/theme/constants";
import { useTrackOrder } from "pages/customer/ShoppingBag/TrackOrder/useTrackOrder";
import { useMemo }       from "react";

const TrackOrder = () => {
  const { data, isLoading, newOrder, filteredStatuses, isLoggedIn, activeStep } = useTrackOrder();
  const navigate = useNavigate();
  const shippingTotal = useMemo(()=>{
    if(data.isWithoutShipping){
      return null
    }
    const items = data.shoppingBags || data.shoppingBagItems ||[]
    const total = items.reduce((accumulator, item) => accumulator + item.shippingRate.totalCharges, 0)
    return total
  },[data.isWithoutShipping, data.shoppingBags, data.shoppingBagItems])

  return <Box bgcolor={"grey.50"} sx={{ py: 4, mt: 0.5 }}>
    <Box sx={{ px: { md: 10.5, lg: 0 }, mx: "auto", maxWidth: "1152px" }}>
      {newOrder
        ?
        <Box
          sx={{ borderRadius: 2, overflow: "hidden", backgroundColor: "grey.200", width: "100%", py: 6, textAlign: "center", position: "relative" }}>
          <Box
            sx={{ position: "absolute", display: { xs: "none", sm: "block" }, right: "-150px", top: "20px", width: "300px" }}>
            <img width={"100%"} src={"/1.png"} alt={"triangle"}/>
          </Box>
          <Box
            sx={{ position: "absolute", display: { xs: "none", sm: "block" }, left: "-30px", bottom: "0", width: "200px" }}>
            <img width={"100%"} src={"/1.png"} alt={"triangle"}/>
          </Box>
          <Avatar sx={{ width: 90, height: 90, backgroundColor: `${theme.palette.success.success30}`, m: "auto" }}>
            <CheckOutlinedIcon sx={{ fontSize: "50px" }} color={"success"}/>
          </Avatar>
          <Typography variant={"h2"} sx={{ mt: 3, mb: 1.5 }}>
            Thank you for order
          </Typography>
          <Typography variant={"body1"}>
            Your order has been successfully placed
          </Typography>
          <Button sx={{ width: "180px", mt: 5 }} variant={"contained"} onClick={() => {
            navigate(`${shopPaths.unAuth.home.path}`);
          }}>Continue shopping</Button>
        </Box>
        :
        isLoggedIn
          ?
          <Box sx={{ display: { sm: "flex" }, maxWidth: { md: "710px", lg: "760px" }, mx: "auto" }}>
            <Button fullWidth variant={"contained"} onClick={() => navigate(`/${shopPaths.auth.myOrders.fullPath}`)}>
              Back to orders list
            </Button>
            {/*<Button fullWidth variant={"contained"} onClick={() => {*/}
            {/*}} sx={{ ml: { sm: 1 }, mt: { xs: 1, sm: 0 } }}>*/}
            {/*  Reorder All*/}
            {/*</Button>*/}
          </Box>
          :
          null
      }
        <WithProgress inProgress={isLoading}>
        {
        !data.id
          ?
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant={"h3"} sx={{ marginTop: "2rem" }}>Order Not Found</Typography>
          </Box>
          :
          <Box sx={{ maxWidth: { md: "710px", lg: "760px" }, mx: "auto" }}>
            <Paper
              sx={{ display: { xs: "flex", sm: "block" }, flexDirection: "column", justifyContent: "center", mt: 5, mb: 3, p: 2 }}>
              {data.status === 7 ?
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant={"h3"}>Order Canceled</Typography>
                </Box>
                :
                <>
                  <Stepper activeStep={activeStep} orientation="vertical" sx={{ m: "auto", display: { sm: "none" } }}>

                    {filteredStatuses.map((i) => (
                      <Step key={i.item}>
                        <StepLabel>
                          <Typography variant={"body3"}>
                            {i.item}
                          </Typography>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <Stepper activeStep={activeStep} alternativeLabel sx={{ display: { xs: "none", sm: "flex" } }}>
                    {filteredStatuses.map((i) => (
                      <Step key={i.item}>
                        <StepLabel>{i.item}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </>
              }
              <Box sx={{ display: { sm: "flex" }, m: "auto", justifyContent: "end", mt: 3 }}>
                <Box display={"flex"} sx={{ mb: { xs: 2.5, sm: 0 }, mr: { sm: 4 }, justifyContent: "center" }}>
                  <Typography variant={"body3"} color={"grey.600"}>
                    Placed on:
                  </Typography>
                  <Typography variant={"body3"} color={"primary.contrastText"} sx={{ ml: 1 }}>
                    {(new Date(data.created)).toLocaleDateString()}
                  </Typography>
                </Box>
                {data.modified && <Box display={"flex"} sx={{ justifyContent: "center" }}>
                  <Typography variant={"body3"} color={"grey.600"}>
                    Updated at:
                  </Typography>
                  <Typography variant={"body3"} color={"primary.contrastText"} sx={{ ml: 1 }}>
                    {(new Date(data.modified)).toLocaleDateString()}
                  </Typography>
                </Box>}
              </Box>
            </Paper>
            <Box>
              {/*todo - keep one shoppingBags or  shoppingBagItems */}
              {(data.shoppingBags || data.shoppingBagItems).map((item, i) => {
                return <OrderPaper reorder={!newOrder} key={i} item={item} withActions={false}/>;
              })}
            </Box>
            <Box sx={{ mt: 2, justifyContent: "space-between" }}>
              {!data.isWithoutShipping && <Paper sx={{ p: 2, mb: { xs: 2, sm: 2 } }}>
                <Typography variant={"h4"}>
                  Shipping address
                </Typography>
                <MyAddressCard {...data.shippingAddress} sx={{ width: "unser" }} noAction={true}/>
              </Paper>}
              <Paper sx={{ px: 3, py: 2, flexGrow: 1 }}>
                <List>
                  <ListItem disablePadding sx={{ display: "flex", py: 1, justifyContent: "space-between" }}>
                    <Typography variant={"body3"}>
                      Subtotal:
                    </Typography>
                    <Typography variant={"subtitle2"}>
                      {shippingTotal ? `$ ${(data.total-shippingTotal).toFixed(2)}` : `$ ${data.total}` }
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding sx={{ display: "flex", py: 1, justifyContent: "space-between" }}>
                    <Typography variant={"body3"}>
                      Shipping:
                    </Typography>
                    <Typography variant={"subtitle2"}>
                      {shippingTotal ? `$ ${shippingTotal}` : "-"}
                    </Typography>
                  </ListItem>
                </List>
                <Divider/>
                <List>
                  <ListItem disablePadding sx={{ display: "flex", py: 1, justifyContent: "space-between" }}>
                    <Typography variant={"subtitle2"}>
                      Total:
                    </Typography>
                    <Typography variant={"subtitle2"}>
                      {data.total} $
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding sx={{ py: 1 }}>
                    <Typography variant={"body3"}>
                      Paid by Credit/Debit Card
                    </Typography>
                  </ListItem>
                </List>
              </Paper>
            </Box>
          </Box>
        }
        </WithProgress>
    </Box>
  </Box>;

};

export default TrackOrder;


import { useState }                                        from "react";
import { IconButton, Drawer, Box, Link }                   from "@mui/material";
import MenuIcon                                            from "@mui/icons-material/Menu";
import CloseIcon                                           from "@mui/icons-material/Close";
import ShopBottomNavigation                                from "components/main/ShopBar/ShopBottomNavigation";
import MobileIAccordion                                    from "components/main/ShopBar/MobileIAccordion";
import SearchBar                                           from "./SearchBar";
import { LocationOnOutlined, MailOutlined, PhoneOutlined } from "@mui/icons-material";
import { useShopSelector } from "store/hooks";
import shopInfoSelectors from "store/shopInfo/shopInfoSelectors";

const ShopMobileBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { email, phone } = useShopSelector(shopInfoSelectors.getCompanyInfo);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setIsOpen(open);
  };

  return (
    <Box sx={{ display: { xs: "flex", md: "none" } }}>
      <IconButton
        aria-label={isOpen ? "close" : "open"}
        size="large"
        onClick={toggleDrawer(true)}
      >
        {isOpen
          ? <CloseIcon fontSize="inherit"/>
          : <MenuIcon fontSize="inherit"/>
        }
      </IconButton>
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer(false)}
      >
        <Box sx={{ height: "calc(100% - 56px)", width:"100%", overflow: "scroll"}}>
          <Box sx={{ p: 1 }}>
            <img src="/logo512.png" alt="logo" style={{ height: "44px" }}/>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
            <Link href={`tel:${phone}`}> <PhoneOutlined/></Link>
            <Link href={`mailto:${email}`}> <MailOutlined/></Link>
            <Link target={"_blank"}
                  href={"https://www.google.com/maps/place/380+S+Los+Angeles+St+Unit+P1,+Los+Angeles,+CA+90013,+USA/@34.0473321,-118.2479971,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2c649e43f8bf7:0xb218bfa3be22fda!8m2!3d34.0473321!4d-118.2458084"}>
              <LocationOnOutlined/>
            </Link>
          </Box>
          <Box sx={{ display: "flex", width: "100%", position: "relative", mt: 1, mb: 2 }}>
            <SearchBar/>
          </Box>
          <MobileIAccordion hideMenu={toggleDrawer(false)}/>
          <ShopBottomNavigation hideMenu={toggleDrawer(false)}/>
        </Box>
      </Drawer>
    </Box>
  );
};

export default ShopMobileBar;

import { Box, Grid, Typography } from "@mui/material";
import { Link }                  from "react-router-dom";
import { ImageCard }        from "../Products/components";
import useCompletedProjects from "./useCompletedProjects";
import { theme }            from "../../../components/theme/constants";

const CompletedProjects = () => {
  const { data } = useCompletedProjects();
  const textColor = theme.palette.primary.contrastText

  return (
    <Box sx={{ maxWidth: "1152px", m: "auto", mb: { xs: 4, md: 6 }, px: { xs: 2, sm: 3, md: 10.5 } }}>
      <Box sx={{ my: { xs: 8, md: 0 }, mt: { md: 12 } }}>
        <Typography gutterBottom variant="h1" component="div" sx={{ mb: { xs:2, sm:6 } }}>
          Completed projects
        </Typography>
      </Box>

      <Grid container spacing={{ sm: 3 }} rowSpacing={{ xs: 3 }}>
          {data?.map(project => {
            if (!project.mainImageUri) {
              return;
            }
            return  <Grid key={project.id} item xs={12} sm={6} md={4}>
              <Link to={`/completed-projects/${project.id}`}  style={{ textDecoration: "none", color: textColor }}>
                <ImageCard title={project.clientName} description={project.description} url={project.mainImageUri}/>
              </Link>
            </Grid>;
          })}
        </Grid>
    </Box>
  );

};

export default CompletedProjects;
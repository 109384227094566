import React, { memo } from "react";
import PropTypes from "prop-types";
import { MESSAGES } from "../../../messages";
import { Box, Button } from "@mui/material";

const AddressButtons = memo(({ showAddresses, toggleAddressDialog, showAddressesView, hideAddressesView }) => {

  return (
    <Box>
      {showAddresses
        ?
        <>
          <Button
            variant="outlined"
            sx={{ mt: { xs: 2, sm: 0 }, ml: 2 }}
            onClick={() => toggleAddressDialog({})}
          >
            {MESSAGES.ADD_ADDRESS}
          </Button>
          <Button
            variant="outlined"
            sx={{ mt: { xs: 2, sm: 0 }, ml: 2 }}
            onClick={hideAddressesView}
          >
            {MESSAGES.HIDE_ADDRESSES}
          </Button>
        </>
        :
        <Button
          variant="outlined"
          sx={{ mt: { xs: 2, sm: 0 } }}
          onClick={showAddressesView}
        >
          {MESSAGES.CHANGE_ADDRESS}
        </Button>
      }
    </Box>
  );
});

AddressButtons.propTypes = {
  showAddresses: PropTypes.bool.isRequired,
  hideAddressesView: PropTypes.func.isRequired,
  showAddressesView: PropTypes.func.isRequired,
  toggleAddressDialog: PropTypes.func.isRequired,
};

export default AddressButtons;

import { postAPI }  from "../NetService";
import { SUBSCRIBE_PATH }   from "./constants";

export const postSubscribeRequest = (data) => {
  return postAPI(SUBSCRIBE_PATH, data)
  .then(({ data, success, title }) => {
    if (success) {
      return data
    }
    return Promise.reject(title)
  })
};
import { snackBarVariants } from "pages/constants";

export const setSuccessAlert = (serverMessage) => ({
  serverMessage,
  serverMessageType: snackBarVariants.success,
});

export const setErrorAlert = (serverMessage) => ({
  serverMessage,
  serverMessageType: snackBarVariants.error,
});

import PropTypes from "prop-types";
import { Button, Alert } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from "@mui/lab";

const ConfirmDialog = ({
  open,
  loading,
  toggleDialog,
  title,
  alert,
  message,
  confirmFunc
}) => {

  const handleClose = () => toggleDialog(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        {alert &&
          <Alert variant="outlined" severity="error">
            {alert}
          </Alert>
        }
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>Cancel</Button>
        <LoadingButton variant="contained" onClick={confirmFunc} loading={loading} fullWidth={false} autoFocus>Ok</LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  confirmFunc: PropTypes.func.isRequired,
  alert: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  loading: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  title: "Are you sure",
  message: "Are you sure ?",
  open: false,
  loading: false,
};

export default ConfirmDialog;
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { getPackagesShippingRateRequest } from "../../../../../services/api/shipping/shippingService";
import { CURRENCY_SYMBOL } from "../constants";

const SHIPPING_RATES_INITIAL_STATE = {
  serverMessageType: "",
  serverMessage: "",
  isLoading: true,
  ratesChoices: new Map(),
  selectedShippingRate: null,
};

const SHIPPING_RATES_ACTIONS = {
  FETCH_START: "FETCH_START",
  FETCH_END: "FETCH_END",
  FETCH_ERROR: "FETCH_ERROR",
  CHANGE_DATA: "CHANGE_DATA",
};

const shippingRatesReducer = (state, { type, payload }) => {
  switch (type) {
    case SHIPPING_RATES_ACTIONS.FETCH_START:
      return { ...state, serverMessage: "", serverMessageType: "", isLoading: true };
    case SHIPPING_RATES_ACTIONS.FETCH_END:
    case SHIPPING_RATES_ACTIONS.FETCH_ERROR:
    case SHIPPING_RATES_ACTIONS.CHANGE_DATA:
      return { ...state, ...payload, isLoading: false };
    default:
      throw new Error(`no action type ${type}`);
  }
};

export const useShippingRates = (shipTo, orderItems) => {
  const [state, dispatch] = useReducer(shippingRatesReducer, { ...SHIPPING_RATES_INITIAL_STATE });
  const addBusinessDays = (numBusinessDays) => {
    const centralTimezone = 'America/Chicago';
    const nowInCentralTime = new Date().toLocaleString('en-US', { timeZone: centralTimezone });
    const endDate = new Date(nowInCentralTime);
    // if(endDate.getDay() === 6){
    //   endDate.setDate(endDate.getDate() + 2)
    // } else if(endDate.getDay() === 0 ){
    //   endDate.setDate(endDate.getDate() + 1)
    // }

    let addedDays = 0;
    while (addedDays < numBusinessDays) {
      endDate.setDate(endDate.getDate() + 1);
      if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
        addedDays++;
      }
    }
    const year = endDate.getFullYear();
    const month = String(endDate.getMonth() + 1).padStart(2, "0");
    const day = String(endDate.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  };
  const orderPackages = useMemo(() => orderItems.reduce((pack, orderItem) => {
      const { selectedOptions, printing, product: { productPackages } } = orderItem;
      const printingDays = Number(printing.maximumDays);
      const pickupDate = addBusinessDays(printingDays);
      const { package: { id: packageId }, weight: productWeight } = productPackages.find(({ productOptionId }) =>
        !!selectedOptions.find((selectedOptionId) => productOptionId === selectedOptionId),
      );

      pack.push({
        pickupDate,
        packageId,
        productWeight,
      });

      return pack;
    }, []),
    [orderItems]);

  const makeRatesChoices = useCallback((shippingRates) => {
    const arrShippingRates = Object.values(shippingRates);

    return arrShippingRates[0].reduce((acc, currentItem, currentIndex) => {

      const restRatesPriceTotal = arrShippingRates.reduce((groupedObj, item, index) => {
        groupedObj.selectedTotalCharges = +(groupedObj.selectedTotalCharges + +item[currentIndex].totalCharges).toFixed(2);
        const currentItem = item[currentIndex];
        groupedObj.indexedRates.push(currentItem);
        return groupedObj;
      }, { selectedTotalCharges: 0, indexedRates: [] });

      const choiceOption = `${currentItem.service.description} - ${CURRENCY_SYMBOL} ${restRatesPriceTotal.selectedTotalCharges}`;

      acc.set(choiceOption, restRatesPriceTotal);
      return acc;
    }, new Map());

  }, []);

  useEffect(() => {
    if (shipTo && orderPackages) {

      dispatch({ type: SHIPPING_RATES_ACTIONS.FETCH_START });
      getPackagesShippingRateRequest({ shipTo, packages: orderPackages })
        .then(({ success, data, title }) => {
          if (success) {
            dispatch({
              type: SHIPPING_RATES_ACTIONS.FETCH_END,
              payload: {
                ratesChoices: makeRatesChoices(data),
              },
            });
          } else {
            return Promise.reject(title);
          }
        })
        .catch(errMsg => {
          dispatch({
            type: SHIPPING_RATES_ACTIONS.FETCH_ERROR,
            payload: {
              serverMessageType: "error",
              serverMessage: errMsg?.message ?? errMsg,
            },
          });
        });
    }
  }, [makeRatesChoices, orderPackages, shipTo]);

  return {
    ...state,
  };
};

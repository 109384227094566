const SHOP_DB_NAME = "shoppingBagDB_NEW";
const SH_B_STORE = "shoppingBag_NEW";
const ADDRESS_STORE = "addresses";
const KEY_PATH = "id";
const indexName = "id";

class Response {
  constructor(data, success = true, title) {
    this.data = data;
    this.success = success;
    this.title = title;
  }
}

function ShopIndexedDb () {
  let db;
  let upgradeTransactionCompleted = false;
  let upgradeTransactionAddressesCompleted = false;

  const isInitialized = () => {
    if (upgradeTransactionCompleted && upgradeTransactionAddressesCompleted) {
      return true;
    }

    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (upgradeTransactionCompleted && upgradeTransactionAddressesCompleted) {

          resolve(true);
          clearInterval(intervalId);
        }
      }, 50);
    });
  };

  return {
    isInitialized,

    initialize: () => {
      if (!window.indexedDB) {
        console.log("Browser doesn't support IndexedDB.");
        return;
      }

      const request = window.indexedDB.open(SHOP_DB_NAME);

      request.onupgradeneeded = (event) => {
        db = event.target.result;
        db.createObjectStore(SH_B_STORE, { keyPath: KEY_PATH, autoIncrement: true })
          .createIndex(indexName, KEY_PATH, { unique: true });
        event.target.transaction.oncomplete = () => {
          upgradeTransactionCompleted = true;
        };
      };

      request.onsuccess = (event) => {
        db = event.target.result;
        upgradeTransactionCompleted = true;
        db.close();

        const addressRequest = window.indexedDB.open(SHOP_DB_NAME, 2);

        addressRequest.onupgradeneeded = (event) => {
          db = event.target.result;
          db.createObjectStore(ADDRESS_STORE, { keyPath: KEY_PATH, autoIncrement: true })
            .createIndex(indexName, KEY_PATH, { unique: true });
          event.target.transaction.oncomplete = () => {
            upgradeTransactionAddressesCompleted = true;
          };
        };

        addressRequest.onsuccess = (event) => {
          db = event.target.result;
          upgradeTransactionAddressesCompleted = true;
          db.close();
        };

        addressRequest.onerror = (event) => {
          console.log("[addressRequest]", event.target.error)
        };
      };

      request.onerror = (event) => {
        console.log("[request]", event.target.error)
      };
    },

    addItem: async (item) => {
      await isInitialized();

      return new Promise((resolve, reject) => {
        const transaction = db.transaction(SH_B_STORE, "readwrite");

        transaction.oncomplete = (event) => {
          console.log("[transaction]-oncomplete 1", event)
        };

        transaction.onerror = (event) => {
          console.log("[transaction]-onerror", event)
        };

        const objectStore = transaction.objectStore(SH_B_STORE);

        const objectStoreRequest = objectStore.add(item);

        objectStoreRequest.onsuccess = (event) => {
          resolve(new Response({
            ...item,
            id: event.target.result
          }));
        };

        objectStoreRequest.onerror = (event) => {
          reject(new Response(null, false, "Shopping bag item is not saved"));
        };
      });
    },

    putItem: async (item) => {
      await isInitialized();

      return new Promise((resolve, reject) => {
        const transaction = db.transaction(SH_B_STORE, "readwrite");

        transaction.oncomplete = (event) => {
          console.log("[transaction]-oncomplete 2", event)
        };

        transaction.onerror = (event) => {
          console.log("[transaction]-onerror", event)
        };

        const objectStore = transaction.objectStore(SH_B_STORE);

        const objectStoreTitleRequest = objectStore.get(item.id)
        objectStoreTitleRequest.onsuccess = (e) => {

          if (item.file || item.customerUploads) {
            const customerUploadedFiles = (item.customerUploads || []).reduce((files, customerUpload) => [...files, customerUpload.fileRef], []);
            item.file = [
              ...item.file,
              ...customerUploadedFiles
            ];
            item.customerUploads = undefined;
          }
          const upDateItem = objectStore.put({...e.target.result, ...item });
          upDateItem.onsuccess = (event) => {
            resolve(new Response(event.target.result));
          }

          upDateItem.onerror = (event) => {
            reject(new Response(null, false, "Something went wrong during update"));
          }
        };

        objectStoreTitleRequest.onerror = () => {
          reject(new Response(null, false, `Item by Id ${item.id} not found`));
        };
      });
    },

    deleteItem: async (id) => {
      await isInitialized();

      return new Promise((resolve, reject) => {
        const transaction = db.transaction(SH_B_STORE, "readwrite");

        transaction.oncomplete = (event) => {
          console.log("[transaction]-oncomplete 3", event)
        };

        transaction.onerror = (event) => {
          console.log("[transaction]-onerror", event)
        };

        const objectStore = transaction.objectStore(SH_B_STORE);

        const objectStoreTitleRequest = objectStore.delete(+id);

        objectStoreTitleRequest.onsuccess = (ev) => {
          resolve(new Response());
        };

        objectStoreTitleRequest.onerror = () => {
          reject(new Response(null, false, `Item by Id ${id} not deleted`));
        };
      });
    },

    deleteAllItems: async () => {
      await isInitialized();

      return new Promise((resolve, reject) => {
        const transaction = db.transaction(SH_B_STORE, "readwrite");

        transaction.oncomplete = (event) => {
          console.log("[transaction]-oncomplete 4", event)
        };

        transaction.onerror = (event) => {
          console.log("[transaction]-onerror", event)
        };

        const objectStore = transaction.objectStore(SH_B_STORE);

        const objectStoreTitleRequest = objectStore.clear();

        objectStoreTitleRequest.onsuccess = (ev) => {
          resolve(new Response());
        };

        objectStoreTitleRequest.onerror = () => {
          reject(new Response(null, false, "Something went wrong"));
        };
      });

    },

    getById: async (id) => {
      await isInitialized();

      return new Promise((resolve, reject) => {
        const transaction = db.transaction(SH_B_STORE, "readwrite");

        transaction.oncomplete = (event) => {
          console.log("[transaction]-oncomplete 5", event)
        };

        transaction.onerror = (event) => {
          console.log("[transaction]-onerror", event)
        };

        const objectStore = transaction.objectStore(SH_B_STORE);

        const objectStoreTitleRequest = objectStore.get(+id);

        objectStoreTitleRequest.onsuccess = () => {

          const bugItem = objectStoreTitleRequest.result;

          if (bugItem.file.length) {
            bugItem.customerUploads = bugItem.file.reduce((acc, currentFile, index) => {
              const customerUpload = {
                id: index,
                name: currentFile.name,
                fileRef: currentFile,
              }
              acc.push(customerUpload);

              return acc;
            }, []);

            delete bugItem.file;
          }

          resolve(new Response(bugItem))
        };

        objectStoreTitleRequest.onerror = () => {
          reject(new Response(null, false, `Item by Id ${id} not found`));
        };
      });
    },

    getAllItems: async () => {
      await isInitialized();

      return new Promise((resolve, reject) => {
        const request = window.indexedDB.open(SHOP_DB_NAME);

        request.onsuccess = (event) => {
          db = event.target.result;
          const transaction = db.transaction(SH_B_STORE, "readwrite");

          transaction.oncomplete = (event) => {
            console.log("[transaction]-oncomplete 6", event)
          };

          transaction.onerror = (event) => {
            console.log("[transaction]-onerror", event)
          };

          const objectStore = transaction.objectStore(SH_B_STORE);

          const objectStoreRequest = objectStore.getAll();

          objectStoreRequest.onsuccess = (event) => {
            resolve(new Response(event.target.result));
          };

          objectStoreRequest.onerror = (event) => {
            reject(new Response(null, false, "Cannot get items."));
          };
        }
      });
    },

    getItemsCount: async () => {
      await isInitialized();

      return new Promise((resolve, reject) => {
        const request = window.indexedDB.open(SHOP_DB_NAME);

        request.onsuccess = (event) => {
          db = event.target.result;
          const transaction = db.transaction(SH_B_STORE, "readwrite");

          transaction.oncomplete = (event) => {
            console.log("[transaction]-oncomplete 7", event)
          };

          transaction.onerror = (event) => {
            console.log("[transaction]-onerror", event)
          };

          const objectStore = transaction.objectStore(SH_B_STORE);

          const objectStoreRequest = objectStore.count();

          objectStoreRequest.onsuccess = (event) => {
            resolve(new Response(event.target.result));
          };

          objectStoreRequest.onerror = (event) => {
            reject(new Response(null, false, "Can not get items."));
          };
        }
      });
    },

    addAddress: async (address) => {
      await isInitialized();

      return new Promise((resolve, reject) => {
        const transaction = db.transaction(ADDRESS_STORE, "readwrite");

        transaction.oncomplete = (event) => {
          console.log("[transaction]-oncomplete 8", event)
        };

        transaction.onerror = (event) => {
          console.log("[transaction]-onerror", event)
        };

        const objectStore = transaction.objectStore(ADDRESS_STORE);

        const objectStoreRequest = objectStore.add(address);

        objectStoreRequest.onsuccess = (event) => {
          resolve(new Response(address));
        };

        objectStoreRequest.onerror = (event) => {
          reject(new Response(null, false, "Is not saved"));
        };
      });
    },

    getAllAddresses: async () => {
      await isInitialized();

      return new Promise((resolve, reject) => {
        const request = window.indexedDB.open(SHOP_DB_NAME);

        request.onsuccess = (event) => {
          db = event.target.result;
          const transaction = db.transaction(ADDRESS_STORE, "readwrite");

          transaction.oncomplete = (event) => {
            console.log("[transaction]-oncomplete 9", event)
          };

          transaction.onerror = (event) => {
            console.log("[transaction]-onerror", event)
          };

          const objectStore = transaction.objectStore(ADDRESS_STORE);

          const objectStoreRequest = objectStore.getAll();

          objectStoreRequest.onsuccess = (event) => {
            resolve(new Response(event.target.result));
          };

          objectStoreRequest.onerror = (event) => {
            reject(new Response(null, false, "Cannot get items."));
          };
        }
      });
    },

    getAddressById: async (id) => {
      await isInitialized();

      return new Promise((resolve, reject) => {
        const request = window.indexedDB.open(SHOP_DB_NAME);
        request.onsuccess = (event) => {
          db = event.target.result;

          const transaction = db.transaction(ADDRESS_STORE, "readwrite");

          transaction.oncomplete = (event) => {
            console.log("[transaction]-oncomplete 10", event)
          };

          transaction.onerror = (event) => {
            console.log("[transaction]-onerror", event)
          };

          const objectStore = transaction.objectStore(ADDRESS_STORE);

          const objectStoreTitleRequest = objectStore.get(+id);

          objectStoreTitleRequest.onsuccess = () => {

            resolve(new Response(objectStoreTitleRequest.result))
          };

          objectStoreTitleRequest.onerror = () => {
            reject(new Response(null, false, `Item by Id ${id} not found`));
          };
        }
      });
    },

    putAddress: async (address) => {
      address.id=Number(address.id)
      await isInitialized();

      return new Promise((resolve, reject) => {
        const transaction = db.transaction(ADDRESS_STORE, "readwrite");

        transaction.oncomplete = (event) => {
          console.log("[transaction]-oncomplete 11", event)
        };

        transaction.onerror = (event) => {
          console.log("[transaction]-onerror", event)
        };

        const objectStore = transaction.objectStore(ADDRESS_STORE);

        const objectStoreTitleRequest = objectStore.get(address.id);

        objectStoreTitleRequest.onsuccess = () => {

          const upDateAddress = objectStore.put(address);

          upDateAddress.onsuccess = (event) => {
            resolve(new Response(event.target.result));
          }

          upDateAddress.onerror = (event) => {
            reject(new Response(null, false, "Something went wrong during update"));
          }
        };

        objectStoreTitleRequest.onerror = () => {
          reject(new Response(null, false, "Address by Id not found"));
        };
      });
    },

    deleteAddress: async (id) => {
      await isInitialized();

      return new Promise((resolve, reject) => {
        const transaction = db.transaction(ADDRESS_STORE, "readwrite");

        transaction.oncomplete = (event) => {
          console.log("[transaction]-oncomplete 12", event)
        };

        transaction.onerror = (event) => {
          console.log("[transaction]-onerror", event)
        };

        const objectStore = transaction.objectStore(ADDRESS_STORE);

        const objectStoreTitleRequest = objectStore.delete(+id);

        objectStoreTitleRequest.onsuccess = (ev) => {
          resolve(new Response());
        };

        objectStoreTitleRequest.onerror = () => {
          reject(new Response(null, false, `Item by Id ${id} is not deleted`));
        };
      });
    },
  }
}

export default new ShopIndexedDb();

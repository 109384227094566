import { Box, Button, Paper, Typography } from "@mui/material";
import WithProgress from "../../../components/main/WithProgress";
import { useMyAccountSettings }                             from "./useMyAccountSettings";
import Person                                               from "@mui/icons-material/Person";
import {
  ConfirmPasswordField,
  EmailField,
  FullNameField,
  PasswordField,
  PhoneField
}                                                           from "../../../components/main/form";
import { BecomeReseller }                                   from "./BecomeReseller";

export default function MyAccountSettings() {
  const {
    requestLoading,
    handleUpdate,
    handleSubmit,
    errors,
    password,
    register,
    resellerStatus,
    handleUpload
  } = useMyAccountSettings();

  return <Box sx={{ mt: { xs: 5, md: 8, lg: 0 }, width: { xs: "100%", lg: "74%" } }}>
    <Box display={"flex"} justifyContent={"space-between"}>
      <Box display={"flex"} alignItems={"center"}>
        <Person color={"primary"}/>
        <Typography component="p" variant="h3" sx={{ ml: 1.5 }}>
          My Account Settings
        </Typography>
      </Box>
    </Box>
    <BecomeReseller resellerStatus={resellerStatus} onSubmit={handleUpload}/>
    <Paper sx={{ width: "100%", my: 2, px: 2, boxSizing: "border-box" }}>
          <WithProgress inProgress={requestLoading}>
          <Box
            component="form"
            onSubmit={handleSubmit(handleUpdate)}
            noValidate
            sx={{ p: 2 }}
          >
            <EmailField
              disabled={true}
              register={register}
              errors={errors}
            />
            <Box display={"flex"} justifyContent={"space-between"} sx={{ flexDirection: { xs: "column", md: "row" } }}>
              <FullNameField
                register={register}
                errors={errors}
                required={false}
                sx={{ width: { xs: "100%", md: "48%" } }}
              />
              <PhoneField
                register={register}
                errors={errors}
                required={false}
                sx={{ width: { xs: "100%", md: "48%" } }}
                inputProps={{
                  form: {
                    autocomplete: "off"
                  }
                }}
              />
            </Box>
            <PasswordField
              regex
              label={"Current Password"}
              name={"currentPassword"}
              register={register}
              errors={errors}
              showHelpText
            />
            <PasswordField
              regex
              register={register}
              label={"New Password"}
              errors={errors}
              showHelpText
              required={false}
            />
            <ConfirmPasswordField
              register={register}
              errors={errors}
              password={password}
              required={false}
            />

            <Box display={"flex"} justifyContent={"flex-end"} sx={{ py: 2 }}>
              <Button type={"submit"} variant={"contained"}>Update</Button>
            </Box>
          </Box>
          </WithProgress>
    </Paper>
  </Box>;
}



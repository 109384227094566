import PropTypes from "prop-types";
import { Radio, Button, Typography } from "@mui/material";
import { SH_FIELD_NAMES } from "pages/main/Product/components/ProductForm/constants";

const ProductOrderReadyItem = ({ checked, register, frontPrice, readyOpt, index }) => {
  const { printing } = SH_FIELD_NAMES;

  return (
    <Button
      key={index}
      variant={checked ? "contained" : "outlined"}
      component="label"
      sx={{display: "block", my:1, px:0, width:"33%", textAlign:"center"}}
    >
      <Radio
        value={index}
        id={`${index}`}
        name={printing}
        sx={{ display: "none" }}
        {...register(printing)}
      />
      <Typography variant="h6">
        $ {frontPrice}
      </Typography>
      <Typography variant={"body4"}>
        {readyOpt.name}
      </Typography>
    </Button>
  )
};
ProductOrderReadyItem.propTypes = {
  checked: PropTypes.bool,
  register: PropTypes.func.isRequired,
  frontPrice: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  readyOpt: PropTypes.shape({
    name: PropTypes.string,
    coefficient: PropTypes.number
  }).isRequired
};

export default ProductOrderReadyItem;

import { createSlice, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import ShopInfoAsyncActions from "store/shopInfo/ShopInfoAsyncActions";

const companyInfo = JSON.parse(localStorage.getItem("companyInfo") ?? "{}");
const categories = JSON.parse(localStorage.getItem("categories") ?? "[]");
const categoriesFull = JSON.parse(localStorage.getItem("categoriesFull") ?? "[]");
const mainSliderImages = JSON.parse(localStorage.getItem("mainSliderImages") ?? "[]");
const shoppingBagItemsCount = +localStorage.getItem("shoppingBagItemsCount") ?? 0;

const initialState = {
  companyInfo,
  categories,
  categoriesFull,
  mainSliderImages,
  shoppingBagItemsCount,
};

const isShopInfoInitializeFulfilled = isFulfilled(
  ShopInfoAsyncActions.fetchShopInitializeData,
);

const isShopInfoFulfilled = isFulfilled(
  ShopInfoAsyncActions.fetchCompanyInfo,
  ShopInfoAsyncActions.fetchCategories,
  ShopInfoAsyncActions.fetchCategoriesFull,
  ShopInfoAsyncActions.fetchMainSliderImages,
  ShopInfoAsyncActions.fetchShoppingBagItemsCount,
);

const isShopInfoPending = isPending(
  ShopInfoAsyncActions.fetchShopInitializeData,
  ShopInfoAsyncActions.fetchCompanyInfo,
  ShopInfoAsyncActions.fetchCategories,
  ShopInfoAsyncActions.fetchCategoriesFull,
  ShopInfoAsyncActions.fetchMainSliderImages,
  ShopInfoAsyncActions.fetchShoppingBagItemsCount,
);

const isShopInfoRejected = isRejected(
  ShopInfoAsyncActions.fetchShopInitializeData,
  ShopInfoAsyncActions.fetchCompanyInfo,
  ShopInfoAsyncActions.fetchCategories,
  ShopInfoAsyncActions.fetchCategoriesFull,
  ShopInfoAsyncActions.fetchMainSliderImages,
  ShopInfoAsyncActions.fetchShoppingBagItemsCount,
);

const shopInfoSlice = createSlice({
  name: "shopInfo",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addMatcher((action) => isShopInfoInitializeFulfilled(action), (state, { payload, meta }) => {
        return {
          ...state,
          ...payload
        }
      })
      .addMatcher((action) => isShopInfoFulfilled(action), (state, { payload }) => {
        return {
          ...state,
          ...payload
        }
      })
      .addMatcher((action) => isShopInfoPending(action), (state, { payload }) => {
        return {
          ...state,
          isLoading: true
        }
      })
      .addMatcher((action) => isShopInfoRejected(action), (state, { payload }) => {
        return {
          ...state,
          isLoading: true
        }
      })
  },
});

export const { actions: ShopInfoActions } = shopInfoSlice;

export default shopInfoSlice.reducer;

import { Box, Grid, Typography } from "@mui/material";
import { useParams }             from "react-router";
import WithProgress from "../../../components/main/WithProgress";
import { ImageCard }             from "./components";
import useProducts               from "./useProducts";
import { Link }                  from "react-router-dom";
import { theme }                 from "../../../components/theme/constants";
import { productPathBuilder } from "../../../utils/productHelper";

const Products = () => {
  const { id } = useParams();
  const textColor = theme.palette.primary.contrastText
  const { data, isLoaded } = useProducts(id);

  return (
    <WithProgress inProgress={!isLoaded} fullHeight={true}>
    <Box sx={{ maxWidth: "1152px", m: "auto", mb: { xs: 4, md: 6 }, px: { xs: 2, sm: 3, md: 10.5 } }}>
      {data?.map((categ, index) => <Box key={index} sx={{ my: { xs: 8, md: 0 }, mt: { md: 12 } }}>
          <Typography gutterBottom variant="h2" component="div" sx={{ mb: 6 }}>
            {categ.name}
          </Typography>
          <Grid container spacing={{ sm: 3 }} rowSpacing={{ xs: 3 }}>
            {categ.products.map(product => <Grid key={product.id} item xs={12} sm={6} md={4}>
              <Link to={productPathBuilder(product.name, product.id)} style={{ textDecoration: "none", color: textColor }}>
                <ImageCard title={product.name} url={product.mainImageURI} altText={product.mainImageAltText}/>
              </Link>
            </Grid>)}
          </Grid>
        </Box>
      )}
    </Box>
    </WithProgress>
  );
};

export default Products;

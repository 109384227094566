import { useState }                                   from "react";
import PropTypes                                      from "prop-types";
import { Box, FormControl, InputLabel, NativeSelect } from "@mui/material";
import { SH_FIELD_NAMES }                             from "pages/main/Product/components/ProductForm/constants";

const OrderOptions = ({ sortedFields, register, handleOptionsChange, selectedOptions }) => {
  const [defaultValues, setDefaultValues] = useState({});
  const [sizeType, setSizeType] = useState("IN");
  return (
    sortedFields.map((item, index) => {
      const { id, productOptions, name } = item;
      return <Box key={id} sx={{ display: "flex", width: "100%" }}>
        <FormControl sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <InputLabel htmlFor={name}
                      sx={{ transform: "translateY(-9px) scale(0.75)" }}>
            {name}
          </InputLabel>
          <NativeSelect
            defaultValue={defaultValues[ id ]}
            fullWidth
            id={name}
            sx={{ my: 2, minWidth: "100%" }}
            {...register(`${SH_FIELD_NAMES.selectedOptions}.${index}`, {
              valueAsNumber: true
            })}
            key={index}
            onChange={handleOptionsChange}
          >
            {productOptions?.map(({ id: optionId, label, labelInch }) => {
              const defaultValue = selectedOptions.includes(optionId) ? optionId : "";
              if (!defaultValues[ id ] && defaultValue) {
                setDefaultValues({
                  ...defaultValues,
                  [ id ]: defaultValue
                });
              }
              return (
                <option key={optionId}
                        value={optionId}>{sizeType === "IN" && name === "Sizes" ? labelInch : label}</option>
              );
            })}
          </NativeSelect>
        </FormControl>
        {name === "Sizes" &&
          <NativeSelect
            value={sizeType}
            id={"sizeType"}
            sx={{ my: 2, ml: 2, width: "60px" }}
            inputProps={{ sx: { pl: 1 } }}
            onChange={(e) => setSizeType(e.target.value)}
          >
            <option value={"IN"}>IN</option>
            <option value={"FT"}>FT</option>
          </NativeSelect>
        }
      </Box>;
    })
  );
};

OrderOptions.propTypes = {
  sortedFields: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  register: PropTypes.func.isRequired,
  handleOptionsChange: PropTypes.func.isRequired
};

OrderOptions.defaultProps = {
  selectedOptions: []
};

export default OrderOptions;

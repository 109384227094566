import { useCallback, useEffect, useReducer }     from "react";
import useShopSnackbar                            from "../../../hooks/useShopSnackbar";
import {
  MY_ACCOUNT_SETTINGS_ACTIONS,
  MY_ACCOUNT_SETTINGS_INITIAL_STATE,
  myAccountSettingsReducer
}                                                 from "./myAccountSettingsReducer";
import { useForm }                                from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import customerSelectors from "store/customer/customerSelectors";

import {
  getCustomerData,
  updateCustomerData,
  uploadResellerForm
} from "services/api/settings/SettingsService";
import { snackBarVariants }                       from "../../constants";
import { updateCustomerDada } from "store/customer/customerSlice";

export const useMyAccountSettings = () => {
  const [state, dispatch] = useReducer(myAccountSettingsReducer, MY_ACCOUNT_SETTINGS_INITIAL_STATE);
  const dispatchRedux = useDispatch();
  const customerInfo = useSelector(customerSelectors.getInfo);
  const { getValues, watch, register, setError, handleSubmit, formState: { errors } }
    = useForm({ defaultValues: { ...customerInfo, password: "" } });
  useShopSnackbar(state.serverMessage, state.serverMessageType);

  watch("password");
  const handleUpdate = useCallback((data) => {
    let newData = {
      fullName: data.fullName,
      phone: data.phone,
      currentPassword: data.currentPassword,
      emailAddress: data.emailAddress
    };
    if (data.password) {
      newData = {
        ...newData,
        password: data.password,
        confirmPassword: data.confirmPassword
      };
    }
    dispatch({
      type: MY_ACCOUNT_SETTINGS_ACTIONS.FETCH_START
    });

    updateCustomerData(data.id, newData)
      .then(d => {
        dispatch({
          type: MY_ACCOUNT_SETTINGS_ACTIONS.FETCH_END,
          payload: {
            ...d, serverMessageType: snackBarVariants.success,
            serverMessage: "Updated Successfully"
          }
        });
        dispatchRedux(updateCustomerDada(d));
      })
      .catch(title => {
        dispatch({
          type: MY_ACCOUNT_SETTINGS_ACTIONS.FETCH_ERROR,
          payload: {
            serverMessageType: snackBarVariants.error,
            serverMessage: title
          }
        });
        setError("currentPassword", { type: "focus", message: title }, { shouldFocus: true });
      });
  }, [dispatchRedux, setError]);

  const getData = useCallback(() => {
    dispatch({ type: MY_ACCOUNT_SETTINGS_ACTIONS.FETCH_START });
    getCustomerData()
      .then((data) => {
        dispatch({
          type: MY_ACCOUNT_SETTINGS_ACTIONS.FETCH_END,
          payload: { ...data, password: "" }
        });

        dispatchRedux(updateCustomerDada(data));
      })
      .catch((title) => {
        dispatch({
          type: MY_ACCOUNT_SETTINGS_ACTIONS.FETCH_ERROR,
          payload: {
            serverMessageType: snackBarVariants.error,
            serverMessage: title
          }
        })
      })



  }, [dispatchRedux]);

  useEffect(() => {
    getData();
  }, [getData]);

  const toggleDialog = useCallback((open, selectedItem) => {
    dispatch({
      type: open ? MY_ACCOUNT_SETTINGS_ACTIONS.OPEN_CONFIRM : MY_ACCOUNT_SETTINGS_ACTIONS.CLOSE_CONFIRM,
      payload: {
        selectedItem: open ? selectedItem : null
      }
    });

  }, []);

  const handleUpload = useCallback((resellerForm, res) => {
    dispatch({
      type: MY_ACCOUNT_SETTINGS_ACTIONS.FETCH_START
    });
    uploadResellerForm(resellerForm).then(title => {
      dispatch({
        type: MY_ACCOUNT_SETTINGS_ACTIONS.FETCH_END,
        payload: {
          resellerStatus: 1, serverMessageType: snackBarVariants.success,
          serverMessage: title
        }
      });
      res();
      dispatchRedux(updateCustomerDada({ ...customerInfo, resellerStatus: 1 }));
    }).catch(title => dispatch({
      type: MY_ACCOUNT_SETTINGS_ACTIONS.FETCH_ERROR,
      payload: {
        serverMessageType: snackBarVariants.error,
        serverMessage: title
      }
    }));
  }, [customerInfo, dispatchRedux]);

  return {
    ...state,
    handleUpload,
    errors,
    register,
    get password() {
      return getValues().password;
    },
    handleUpdate,
    handleSubmit,
    toggleDialog
  };
};
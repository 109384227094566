import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Link,
  List,
  ListItem,
  ListSubheader,
  Typography
}                                   from "@mui/material";
import PropTypes                    from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { grey }                     from "@mui/material/colors";
import { groupCategories } from "utils";
import { theme } from "components/theme/constants";
import { productPathBuilder } from "../../../utils/productHelper";

const ShopMenuSections = ({ sections: { sorted:sections,random }, onProductClick }) => {
  const [drawnItems, setDrawnItems] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const [selectedItem, setSelectedItem] = useState({ name: "Products"});

  const onProductHover = (item) => setSelectedItem(item);

  const onClick = useCallback((name, id, e) => {
    onProductClick(productPathBuilder(name, id), e);
  }, [onProductClick]);

  useEffect(() => {
    if (sections) {
      setFilteredSections(groupCategories(sections));
    }
  }, [sections]);

  useEffect(() => {
    const allSections = filteredSections.map((groupedSectionArr, index) => {

      return (
        <Box key={index} sx={{ pr:1 }}>
          {
            groupedSectionArr.map((category) => {
              return Object.keys(category).map((header, index) => {
                const currentItems = sections[header];

                return (
                  <List key={header+index}>
                    <ListSubheader
                      sx={{ padding: "0.2rem 0.5rem"}}
                    >
                      <Typography
                        variant="body2"
                        color="secondary.dark"
                        sx={{ fontWeight: 500, p: 0 }}
                      >
                        {header}
                      </Typography>
                    </ListSubheader>
                    {currentItems?.map((item, itemIndex) => {
                      if (index === 0 && itemIndex === 0) {
                        setSelectedItem(item)
                      }
                      return (
                        <ListItem
                          key={item.id}
                          onFocus={() => onProductHover(item)}
                          onMouseEnter={() => onProductHover(item)}
                          sx={{ padding: "0.125rem 0.5rem", "&:hover": { background: theme.palette.grey[100] }}}
                        >
                          <Link
                            sx={{ textTransform: "capitalize", display:"flex", flexWrap:"nowrap", alignItems:"center" }}
                            key={item.id}
                            color="inherit"
                            underline="none"
                            href={productPathBuilder(item.name, item.id)}
                            onClick={(e) => onClick(item.name, item.id, e)}
                          >
                            <Typography variant="body3" color={"primary.contrastText"}>
                              {item.name}
                            </Typography>
                            {item.isNew && <Typography fontWeight={500} color={"primary.main"} sx={{ ml: 1.5, fontSize: "12px" }}>
                              New
                            </Typography>}
                          </Link>
                        </ListItem>
                      );
                    })}
                  </List>
                );
              })
            })
          }
        </Box>
      );
    })

    setDrawnItems(allSections);
  }, [filteredSections, onClick, sections]);

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
      <Box sx={{py:3, pl:1.5, width:"150px",pr:0}} >
        {random?.map(item => {
          return <Link
            href={productPathBuilder(item.name, item.id)}
            onClick={(e) => onClick(item.name, item.id, e)}
            key={item.id}
          >
            <Card sx={{ maxWidth: 150 }} variant="none">
              <CardActionArea>
                <CardMedia
                  component="img"
                  width="150px"
                  image={item.mainImageURI}
                  alt={item.altText}
                />
                <CardContent sx={{px:0, textAlign:"center"}}>
                  <Typography variant="body3">
                    {item.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        })}
      </Box>

      <Box sx={{ display: "flex", flexGrow:1 }}>
        {drawnItems}
      </Box>
      <Box sx={{
        py:3,
        px:1.5,
        background: grey[50]
      }}>
        <Link
          href={productPathBuilder(selectedItem.name, selectedItem.id)}
          onClick={(e) => onClick(selectedItem.name, selectedItem.id, e)}
        >
          <Card sx={{ maxWidth: 250 }} variant="none">
            <CardActionArea>
              <CardMedia
                component="img"
                width="250px"
                image={selectedItem.mainImageURI}
                alt={selectedItem.altText}
              />
              <CardContent sx={{px:0, textAlign:"center"}}>
                <Typography variant="h4">
                  {selectedItem.name}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Link>
      </Box>
    </Box>
  );
};

ShopMenuSections.propTypes = {
  sections: PropTypes.object,
  onProductClick: PropTypes.func.isRequired,
};

export default ShopMenuSections;

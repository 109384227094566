import { PaperCard, Subscribe, BussinessStats, AboutUs, WorkWithUs, Carousel } from "./components";
import { Box }                                                                 from "@mui/material";
import { Star }                                                                from "@mui/icons-material";
import { useQuery }                                                            from "@tanstack/react-query";
import {
  getBestSellerProductsRequest,
  getRandomProductsRequest
}                                                                              from "../../../services/api/products/productsService";
import MainSlider
                                                                               from "pages/main/Home/components/MainSlider/MainSlider";
import { useShopSelector } from "store/hooks";
import shopInfoSelectors from "store/shopInfo/shopInfoSelectors";

const Home = () => {
  const { ongoingProjects, completedProjects, partners, yearsOfExperience } = useShopSelector(shopInfoSelectors.getCompanyInfo);

  const query = useQuery(["bestSellerProducts"], getBestSellerProductsRequest);
  const bestSellers = query?.data || [];
  const queryRandom = useQuery(["randomProducts"], () => getRandomProductsRequest(5));
  const randomData = queryRandom?.data || [];

  return <Box sx={{ overflow: "hidden" }}>
    <MainSlider/>
    <Box sx={{ backgroundColor: "grey.50", py: { xs: 12 } }}>
      <Box
        sx={{
          maxWidth: "1152px", display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "space-between",
          px: { xs: 2, sm: 3, md: 10.5 }, mx: "auto", position: "relative"
        }}>
        <PaperCard Icon={Star}
                   title="Fast Printing & Delivery"
                   info="Choose on-demand custom printing services with fast implementation and delivery."
                   sx={{ mr: { md: 3 }, zIndex: 1 }}/>
        <PaperCard Icon={Star}
                   title="Quality Control"
                   info="Order guaranteed premium-quality products tailored to specific needs and budgets."
                   sx={{ mr: { md: 3 }, zIndex: 1 }}/>
        <PaperCard Icon={Star}
                   title="Personalized Service"
                   info="Get 24/7 support from a dedicated team ready to deliver high-level service."
                   sx={{ zIndex: 1 }}
        />
        <img style={{ position: "absolute", right: "-110px", top: "-90px", width: "280px" }} src={"1.png"}
             alt={"triangle"}/>
      </Box>
    </Box>
    <Box sx={{ backgroundColor: "grey.50", pb: { xs: 12 } }}>
      <Carousel data={bestSellers} title={"Best sellers"}/>
    </Box>
    <Box sx={{ backgroundColor: "grey.50", pb: { xs: 12 } }}>
      <Carousel data={randomData} title={"Recommended"}/>
    </Box>
    <Box sx={{ backgroundColor: "primary.contrastText", color: "secondary.contrastText" }}>
      <BussinessStats ongoing={ongoingProjects} projects={completedProjects} partners={partners}
                      years={yearsOfExperience}/>
    </Box>
    <Box sx={{ backgroundColor: "grey.50" }}>
      <WorkWithUs/>
    </Box>
    <Box sx={{ backgroundColor: "grey.50" }}>
      <AboutUs/>
    </Box>
    <Box sx={{ px: { xs: 2, sm: 3, md: 10.5, lg: 0 }, mx: "auto", my: 12, maxWidth: "1152px" }}>
      <Subscribe/>
    </Box>
  </Box>;
};

export default Home;

import { useGlobalStoreConsumer } from "global/useGlobalStoreConsumer";

// todo - find better solution
const StoreConsumer = () => {
  useGlobalStoreConsumer();

  return (
    <></>
  );
};

export default StoreConsumer;

export const MY_ACCOUNT_SETTINGS_INITIAL_STATE = {
  serverMessageType: "",
  serverMessage: "",
  fullName: "",
  emailAddress: "",
  currentPassword: "",
  password: "",
  role:null,
  resellerStatus:null,
  confirmPassword:"",
  requestLoading:false,
  uploadLoading:false,
  openConfirm:false,
  loadingConfirm:false
};

export const MY_ACCOUNT_SETTINGS_ACTIONS = {
  FETCH_START: "FETCH_START",
  FETCH_END: "FETCH_END",
  FETCH_ERROR: "FETCH_ERROR",
  UPLOAD_START: "UPLOAD_START",
  UPLOAD_END: "UPLOAD_END",
  UPLOAD_ERROR: "UPLOAD_ERROR",
  OPEN_CONFIRM: "OPEN_CONFIRM",
  CLOSE_CONFIRM: "CLOSE_CONFIRM",
  START_CONFIRM: "START_CONFIRM",
  END_CONFIRM: "END_CONFIRM",
};

export const myAccountSettingsReducer = (state, { type , payload }) => {
  switch (type) {
    case MY_ACCOUNT_SETTINGS_ACTIONS.FETCH_START:
      return { ...state, serverMessage: "", serverMessageType: "", requestLoading:true };
    case MY_ACCOUNT_SETTINGS_ACTIONS.FETCH_END:
      return { ...state, ...payload, requestLoading: false };
    case MY_ACCOUNT_SETTINGS_ACTIONS.FETCH_ERROR:
      return { ...state, ...payload, requestLoading: false };
    case MY_ACCOUNT_SETTINGS_ACTIONS.UPLOAD_START:
      return { ...state, serverMessage: "", serverMessageType: "", uploadLoading:true };
    case MY_ACCOUNT_SETTINGS_ACTIONS.UPLOAD_END:
      return { ...state, ...payload, uploadLoading: false };
    case MY_ACCOUNT_SETTINGS_ACTIONS.UPLOAD_ERROR:
      return { ...state, ...payload, uploadLoading: false };
    case MY_ACCOUNT_SETTINGS_ACTIONS.OPEN_CONFIRM:
      return { ...state, ...payload, openConfirm: true };
    case MY_ACCOUNT_SETTINGS_ACTIONS.CLOSE_CONFIRM:
      return { ...state, ...payload, openConfirm: false };
    case MY_ACCOUNT_SETTINGS_ACTIONS.START_CONFIRM:
      return { ...state, ...payload, loadingConfirm: true };
    case MY_ACCOUNT_SETTINGS_ACTIONS.END_CONFIRM:
      return { ...state, ...payload, loadingConfirm: false, openConfirm: false };
    default:
      throw new Error(`no action type ${type}`);
  }
};

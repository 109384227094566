import { getAPI } from "services/api/NetService";
import { INFO_PATH, MAIN_SLIDER_PATH } from "services/api/company/constants";

export const getCompanyInfoRequest = () => {
  return getAPI(INFO_PATH)
    .then(({ data: companyInfo, success, title }) => {
      if (success) {
        return companyInfo;
      }

      return Promise.reject(title)
    })
};

export const getHomeSliderImages = () => {
  return getAPI(MAIN_SLIDER_PATH)
    .then(({ data: images, success, title }) => {
      if (success) {
        return images;
      }

      return Promise.reject(title)
    })
};

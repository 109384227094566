import PropTypes                          from "prop-types";
import { Button, Paper, Typography, Box } from "@mui/material";
import Dialog                             from "@mui/material/Dialog";
import DialogActions                      from "@mui/material/DialogActions";
import DialogContent                      from "@mui/material/DialogContent";
import DialogContentText                  from "@mui/material/DialogContentText";
import DialogTitle                        from "@mui/material/DialogTitle";
import { LoadingButton }                  from "@mui/lab";
import * as React                         from "react";
import { theme }                          from "../../../components/theme/constants";

const UpdateOrdersDialog = ({
  open,
  loading,
  toggleDialog,
  title,
  alert,
  data,
  confirmFunc
}) => {
  const { removedProducts, changedProducts, ratesChanged } = data;
  const handleClose = () => toggleDialog(false);
  const warning = theme.palette.warning.light;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" sx={{ backgroundColor: "primary.main", mb: 2 }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Paper>
          {!!changedProducts.length && changedProducts.map(item => {
            return <Box key={item.id} sx={{ display: "flex", justifyContent: "space-between", px: 2, py: 1 }}>
              <Typography variant={"body2"}>
                {item.product.name}:
              </Typography>
              <Typography variant={"body3"}>
                Price changed from <b>{item.priceFront}</b> to <b>{item.price}</b>
              </Typography>
            </Box>;
          })}
          {!!removedProducts.length && removedProducts.map(item => {
            return <Box key={item.id} sx={{ display: "flex", justifyContent: "space-between", px: 2, py: 1 }}>
              <Typography variant={"body2"}>
                {item.product.name}:
              </Typography>
              <Typography variant={"body3"} color={"red"}>
                Out of stock
              </Typography>
            </Box>;
          })}
        </Paper>
        {!!ratesChanged.length && !removedProducts.length && !changedProducts.length  && <Box sx={{ mt: 2, ml:1 }}>
          <Typography variant={"body3"} color={"red"} >
            Shipping rates are changed
          </Typography>
        </Box>}
        <DialogContentText id="alert-dialog-description" sx={{ mt: 2 }}>
          {alert}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>Cancel</Button>
        <LoadingButton variant="contained" onClick={confirmFunc} loading={loading} fullWidth={false}
                       autoFocus>Update</LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

UpdateOrdersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  confirmFunc: PropTypes.func.isRequired,
  alert: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.any,
  loading: PropTypes.bool
};

UpdateOrdersDialog.defaultProps = {
  title: "Are you sure",
  message: "Are you sure ?",
  open: false,
  loading: false
};

export default UpdateOrdersDialog;
import { SECTIONS_MAX } from "utils/constants";

export const sortProductsBySubCategories = (selectedId, categoriesFull) => {

  const currentCategoryItem = categoriesFull.find(({ id }) => selectedId === id);
  const { products, productSubCategories } = currentCategoryItem || {};

  return products?.reduce((acc, product) => {
    const productSubCategory = productSubCategories.find(({ id }) => id === product.productSubCategoryId);
    let name = productSubCategory ? productSubCategory.name : currentCategoryItem.name;

    if (!acc[ name ]) {
      acc[ name ] = [];
    }
    acc[ name ].push(product);

    return acc;
  }, {});

};

export const randomProductsByCategories = (selectedId, categoriesFull, count) => {
  const currentCategoryItem = categoriesFull.find(({ id }) => selectedId === id);
  const { products = [] } = currentCategoryItem || {};
  const shuffled = [...products].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count)
};

export const groupCategories = (items, maxS = 20, minS = 17) => {
  let groupedIndex = 0;
  const groupedItems = [];
  const mapS = new Map(Object.entries(items));

  if (mapS.size < SECTIONS_MAX) {
    mapS.forEach((currentCategory, header) => {
        groupedItems.push([{ [header]: currentCategory }]);
        groupedIndex++;
    })

    return groupedItems;
  }

  /** Get long ones */
  mapS.forEach((currentCategory, header) => {
    if (currentCategory.length >= maxS) {

      groupedItems.push([{ [header]: currentCategory }]);
      groupedIndex++;

      mapS.delete(header);
    }
  });

  const usedIndexes = [];
  const mapSectionsArr = Array.from(mapS);
  const len = mapSectionsArr.length;

  for (let i = 0; i < len; i++) {
    if (usedIndexes.includes(i)) {
      continue;
    }

    const [header, currentItem] = mapSectionsArr[i];
    let currentSum = currentItem.length;
    groupedItems.push([{ [header]: currentItem }]);
    usedIndexes.push(i);

    for (let j = i + 1; j < len; j++) {
      if (usedIndexes.includes(j)) {
        continue;
      }

      const [nextHeader, nextItem] = mapSectionsArr[j];
      const nextSum = currentSum + nextItem.length;

      if (nextSum <= minS && nextSum < maxS) {
        currentSum = nextSum;
        groupedItems[groupedIndex].push({ [nextHeader]: nextItem });
        usedIndexes.push(j);
      }
    }

    groupedIndex++;
  }

  return groupedItems;
}

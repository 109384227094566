const messages = {
  notesLabel: "Notes",
  itemNameLabel: "Order Name",
  noArt: "Without design",
  withArt: "Help with artwork",
  proof: "Need proofing",
  shpBtn: "Add to Shopping Bag",
  updateShpBtn: "Update Shopping Bag Item",
  checkBtn: "Checkout now",
  shoppingBagItemSaved: "Item is saved in shopping bag successfully!",
  shoppingBagItemCheckout: "Item is saved in shopping bag successfully! Please checkout",
};

export default messages;

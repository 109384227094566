import { theme } from "components/theme/constants";
import { Box, Avatar, Paper, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from "@mui/lab";
import { useFetch } from "pages/auth/hooks/useFetch";
import { customerEmailConfirmRequest } from "services/api/auth/authCustomerService";
import { MESSAGES } from "pages/auth/messages";
import { shopPaths } from "routing/constants";

const EmailConfirm = () => {

  const {
    isSubmitting,
    handleSubmit,
    handleFetchSubmit
  } = useFetch(customerEmailConfirmRequest, MESSAGES.emailConfirmedMsg, shopPaths.unAuth.login.path);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleFetchSubmit)}
      noValidate
      sx={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          p: 3,
          width:"100%",
          maxWidth:"360px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          boxSizing: "border-box",
          borderColor: "primary.main"
        }}
      >
        <Avatar
          sx={{
            backgroundColor: theme.palette.primary.main20,
            width: "56px",
            height: "56px",
            mb: 3
          }}
        >
          <CheckIcon fontSize="large" color="primary" />
        </Avatar>
        <Typography variant="h3" gutterBottom>
          Welcome to Platinium
        </Typography>
        <Typography variant="body2">
          We are very excited to have you with us! Please click the following button to confirm your account.
        </Typography>
        <LoadingButton
          loading={isSubmitting}
          type={"submit"}
          fullWidth
          size={"large"}
          variant="contained"
          sx={{ mt: 2 }}
        >
          Confirm your email address
        </LoadingButton>
      </Paper>
    </Box>
  );
}

export default EmailConfirm;

import React from "react";
import PropTypes from "prop-types";
import { Alert } from "@mui/material";
import { messages } from "components/messages";

const categoriesMinCount = 3;

const MoreInfo = ({ count }) => {
  return (count > 0 && count < categoriesMinCount &&
    <Alert icon={false} severity="success" color="info">
      🚀 {messages.COMING_MORE}
    </Alert>
  );
};

MoreInfo.propTypes = {
  count: PropTypes.number.isRequired,
};

export default MoreInfo;

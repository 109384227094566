import { useEffect, useReducer }                 from "react";
import { useSelector }                           from "react-redux";
import { useLocation, useParams }                from "react-router-dom";
import { getStatusesRequest, trackOrderRequest } from "services/api/Orders/OrdersService";
import {
  TRACK_ORDER_ACTIONS,
  TRACK_ORDER_INITIAL_STATE,
  trackOrderReducer
}                                                from "pages/customer/ShoppingBag/TrackOrder/trackOrderReducer";
import customerSelectors                         from "store/customer/customerSelectors";

export const useTrackOrder = () => {
  const { orderId } = useParams();
  const { state: locationState } = useLocation();
  const { newOrder } = locationState ?? {};
  const isLoggedIn = useSelector(customerSelectors.getIsLoggedIn);
  const [state, dispatch] = useReducer(trackOrderReducer, TRACK_ORDER_INITIAL_STATE);

  useEffect(() => {
    dispatch({ type: TRACK_ORDER_ACTIONS.FETCH_START });
    trackOrderRequest(orderId).then((resData) => {
      if (resData) {
        dispatch({
          type: TRACK_ORDER_ACTIONS.CHANGE_DATA,
          payload: { data: resData }
        });
      }
    }).finally(() => dispatch({ type: TRACK_ORDER_ACTIONS.FETCH_END }));
  }, [orderId]);

  useEffect(() => {
    const filteredStatuses = state.allStatuses.map((item, index) => ({ item, index }));
    filteredStatuses.splice(7, 1);
    if (state.data.isWithoutShipping) {
      filteredStatuses.splice(4, 2);
    } else {
      filteredStatuses.splice(5, 2);
    }
    if (state.data.status !== 2) {
      filteredStatuses.splice(2, 1);
    }
    const activeIndex = filteredStatuses.findIndex(i => i.index === state.data.status);

    dispatch({
      type: TRACK_ORDER_ACTIONS.CHANGE_DATA,
      payload: {
        filteredStatuses,
        activeStep: activeIndex === filteredStatuses.length - 1 ? activeIndex + 1 : activeIndex
      }
    });
  }, [state.allStatuses, state.data.isWithoutShipping, state.data.status]);

  useEffect(() => {
    getStatusesRequest().then(allStatuses => {
      dispatch({
        type: TRACK_ORDER_ACTIONS.CHANGE_DATA,
        payload: { allStatuses }
      });
    });
  }, []);

  return {
    ...state,
    isLoggedIn,
    newOrder
  };
};

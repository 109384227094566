import { createSelector } from "@reduxjs/toolkit";

const shopInfoBaseState = state => state.shopInfo;

const getShopInfoEntireData = createSelector(shopInfoBaseState, (state) => state);
const getCompanyInfo = createSelector(shopInfoBaseState, (state) => state.companyInfo);
const getCategories = createSelector(shopInfoBaseState, (state) => state.categories);
const getCategoriesFull = createSelector(shopInfoBaseState, (state) => state.categoriesFull);
const getCategoriesWithFull = createSelector(shopInfoBaseState, (state) => ({
  categories: state.categories,
  categoriesFull: state.categoriesFull,
}));
const getMainSliderImages = createSelector(shopInfoBaseState, (state) => state.mainSliderImages);
const getShoppingBagItemsCount = createSelector(shopInfoBaseState, (state) => state.shoppingBagItemsCount);

const shopInfoSelectors = {
  getShopInfoEntireData,
  getCompanyInfo,
  getCategories,
  getCategoriesFull,
  getCategoriesWithFull,
  getMainSliderImages,
  getShoppingBagItemsCount,
};

export default shopInfoSelectors;

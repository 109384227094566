import { Typography, Divider, Box, Paper, Avatar } from "@mui/material";
import { useShopSelector }                         from "../../../../../store/hooks";
import shopInfoSelectors                           from "../../../../../store/shopInfo/shopInfoSelectors";
import LocationMap                                 from "../../../../main/ContactUs/components/LocationInfo/LocationMap";
import { MESSAGES }                                from "../../../messages";

const PickUpOrder = () => {
  const { address, workingDays, workingHours } = useShopSelector(shopInfoSelectors.getCompanyInfo);

  return (
    <Paper sx={{ px: 2, py: 2, my: 2 }}>
      <Box sx={{ display: {sm: "flex" }, justifyContent: "space-between"}}>
        <Box>
          <Box sx={{ display: { sm: "flex" }, mb: 2, justifyContent: "space-between" }}>
            <Box display={"flex"} alignItems={"center"}>
              <Avatar sx={{ backgroundColor: "primary.light", mr: 2 }}>1</Avatar>
              <Typography variant={"h4"}>Pickup Location</Typography>
            </Box>
          </Box>
          <Typography variant={"h4"} sx={{ pb: 0.5 }}>
            {MESSAGES.LOCATION}
          </Typography>
          <Divider/>
          <Typography variant={"body2"} sx={{ pt: 0.5 }}>
            {address}
          </Typography>
          <Typography variant={"h4"} sx={{ mt: 2, pb: 0.5 }}>
            {MESSAGES.HOURS_OF_OPERATION}
          </Typography>
          <Divider/>
          <Typography variant={"body2"} sx={{ pt: 0.5 }}>
            {workingDays} {workingHours}
          </Typography>
        </Box>
        <Box sx={{ mt:{xs:2,sm:0}, ml: {sm:2} }}>
          <LocationMap width="100%" height="100%"/>
        </Box>
      </Box>
    </Paper>
  );
};

export default PickUpOrder;

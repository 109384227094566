import { configureStore } from "@reduxjs/toolkit";
import customerReducer from "store/customer/customerSlice";
import shopInfoReducer from "store/shopInfo/shopInfoSlice";
import shopSaveLocally from "store/middleware/shopSaveLocally";

const shopStore = configureStore({
  reducer: {
    customer: customerReducer,
    shopInfo: shopInfoReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
  }).concat(shopSaveLocally)
});

export default shopStore;

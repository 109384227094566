import PropTypes from "prop-types";
import { memo, useCallback, useMemo }                          from "react";
import { useNavigate }            from "react-router-dom";
import { Box, Button, CardMedia, Checkbox, Paper, Typography, IconButton } from "@mui/material";
import Table                         from "@mui/material/Table";
import TableBody                     from "@mui/material/TableBody";
import TableCell                     from "@mui/material/TableCell";
import TableContainer                from "@mui/material/TableContainer";
import TableHead                     from "@mui/material/TableHead";
import TableRow                      from "@mui/material/TableRow";
import { getSelectedProductOptions } from "utils/orderHelper";
import * as React                    from "react";
import { useSelector }               from "react-redux";
import customerSelectors             from "../../../../store/customer/customerSelectors";
import { MESSAGES } from "../../messages";
import {PAPER_TYPES} from "../constants";
import { productPathBuilder } from "../../../../utils/productHelper";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const OrderPaper = memo(({ type, item, toggleDialog, changeCheck, defaultChecked, withActions = true,
  reorder = false, onCheckout }) => {

  const { selectedProductOptions: selected, selectedOptions, price, priceFront, itemName, productId, id, product: productData } = item;
  const isLoggedIn = useSelector(customerSelectors.getIsLoggedIn);
  const navigate = useNavigate();

  const onRemove = useCallback(() => {
    toggleDialog(true, item);
  }, [item, toggleDialog]);

  const onEdit = useCallback(() => {
    navigate(productPathBuilder(productData.name, productId, id));
  }, [id, navigate, productData.name, productId]);

  const buyCurrentItem = useCallback((ev) => {
    onCheckout(ev, item);
  }, [item, onCheckout]);

  const sortedFields = useMemo(() => {
    if (productData ) {
      const selectedOnes = selectedOptions
        ? getSelectedProductOptions(productData.productOptionTypeGrouped, selectedOptions)
        : [];
      let selectedProductOptions = selected?.length ? selected : selectedOnes;
      if(!isLoggedIn && type === PAPER_TYPES.SHOPPING_BAG){
        selectedProductOptions = selected && selected.productOptionType ? selected : selectedOnes
      }
      return selectedProductOptions;
    }

    return [];
  }, [selected, productData, selectedOptions, isLoggedIn, type]);

  return <Paper sx={{ px: 2, py: 3, mt: 3 }}>
    <Box sx={{ display: { sm: "flex" } }} alignItems={"start"} justifyContent={"space-between"}>
      {withActions && <Checkbox checked={defaultChecked} onChange={() => changeCheck(id)}/>}
      <Box
        sx={{ my: { xs: 2.5, sm: 0 }, display: "flex", justifyContent: "flex-start", overflow: "hidden", flexDirection: { sm: "column" }, width: { sm: "40%" } }}>
        {productData?.images?.map((image, i) => <CardMedia
          sx={{ m: 0.5, borderRadius: "4px", width: { xs: "49%", sm: "100%" } }}
          key={i}
          component="img"
          image={image.uri}
          alt={image.altText || image.name}
        />)}
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: { xs: "column" }, width: { sm: "55%" }, ml: { sm: 2 }, mt: { sm: 1 } }}>
        <Box>
          <Typography variant={"h4"}>
            {productData.name}
          </Typography>
          <Typography variant={"body1"} sx={{ my: 1.5 }}>
            {itemName}
          </Typography>
        </Box>
        <Typography variant={"h4"} alignSelf={"end"}>
          {`$ ${price ?? priceFront}`}
        </Typography>
        <TableContainer sx={{ mt: 4.5 }}>
          <Table sx={{ minWidth: 285 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Types</TableCell>
                <TableCell align="right">Values</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedFields.map((field) => (
                <TableRow
                  key={field.productOptionType.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {field.productOptionType.name}
                  </TableCell>
                  <TableCell align="right">{field.label}</TableCell>
                </TableRow>
              ))}
              {item.printing &&
              <TableRow>
                <TableCell component="th" scope="row">Print process</TableCell>
                <TableCell align="right">{item.printing.name}</TableCell>
              </TableRow>
              }
              <TableRow>
                <TableCell component="th" scope="row">{MESSAGES.SHIPPABLE}</TableCell>
                <TableCell align="right">{ item.product.isShippable ? MESSAGES.YES : MESSAGES.NO }</TableCell>
              </TableRow>
              {isLoggedIn
                ?
                item.customerUploads?.length > 0 &&
                <TableRow>
                  <TableCell component="th" scope="row">Uploaded files</TableCell>
                  <TableCell align="right">
                    {item.customerUploads.length} {`file${item.customerUploads.length > 1 ? "s" : ""}`}
                  </TableCell>
                </TableRow>
                :
                item.file?.length > 0 &&
                <TableRow>
                  <TableCell component="th" scope="row">Uploaded files</TableCell>
                  <TableCell align="right">
                    {item.file.length} {`file${item.file.length > 1 ? "s" : ""}`}
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
    {withActions &&
      <Box sx={{ mt: 5, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box>
          <IconButton role="link" aria-label="edit" color="primary" onClick={onEdit}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="delete" color="primary" onClick={onRemove}>
            <DeleteIcon />
          </IconButton>
        </Box>
        <Button variant="contained" onClick={buyCurrentItem}>
          {MESSAGES.BUY_NOW}
        </Button>
      </Box>
    }
    {/*{reorder &&*/}
    {/*<Box display={"flex"} justifyContent={"end"} sx={{ mt: 5, alignItems: "center" }}>*/}
    {/*  <Button onClick={(e) => {*/}
    {/*    e.stopPropagation();*/}
    {/*  }} variant={"contained"} sx={{ borderRadius: "50px", textTransform: "none" }}> Reorder Item*/}
    {/*  </Button>*/}
    {/*</Box>*/}
    {/*}*/}
  </Paper>;
});

OrderPaper.propTypes = {
  onCheckout: PropTypes.func,
};

export default OrderPaper;
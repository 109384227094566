import Proptypes from "prop-types";
import { LoadingButton } from "@mui/lab";
import messages from "pages/main/Product/components/ProductForm/messages";

const SaveButtons = ({ disabled, handleSubmit, addToShoppingBag, updateSoppingBagItem, handleBuy, loading, shoppingBagItemId }) => {
  return (
    shoppingBagItemId
      ?
      <LoadingButton
        loading={loading}
        value="bag"
        fullWidth
        disabled={disabled}
        type="button"
        variant="contained"
        color="primary"
        sx={{mt: 2}}
        onClick={handleSubmit(updateSoppingBagItem)}
      >
        {messages.updateShpBtn}
      </LoadingButton>
      :
      <>
        <LoadingButton
          loading={loading}
          value="bag"
          fullWidth
          disabled={disabled}
          type="button"
          variant="contained"
          color="primary"
          sx={{mt: 2}}
          onClick={handleSubmit(addToShoppingBag)}
        >
          {messages.shpBtn}
        </LoadingButton>
        <LoadingButton
          loading={loading}
          fullWidth
          disabled={disabled}
          type="button"
          variant="contained"
          color="primary"
          value="checkout"
          sx={{mt: 2}}
          onClick={handleSubmit(handleBuy)}
        >
          {messages.checkBtn}
        </LoadingButton>
      </>
  );
};

SaveButtons.propTypes = {
  disabled: Proptypes.bool.isRequired,
  handleSubmit: Proptypes.func.isRequired,
  addToShoppingBag: Proptypes.func.isRequired,
  shoppingBagItemId: Proptypes.number,
  updateSoppingBagItem: Proptypes.func.isRequired,
  handleBuy: Proptypes.func.isRequired,
  loading: Proptypes.bool.isRequired,
}

export default SaveButtons;

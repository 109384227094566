import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import customerSelectors from "store/customer/customerSelectors";
import { useFetch } from "pages/auth/hooks";
import { createShoppingBagItem } from "services/api/shoppingBag/ShoppingBagService";
import { SHOPPING_BAG_KEY } from "pages/main/Product/components/ProductForm/constants";
import ShopIndexedDb from "indexedDB/ShopIndexedDb";
import ShopInfoAsyncActions from "store/shopInfo/ShopInfoAsyncActions";
import { useShopDispatch } from "store/hooks";

export const useGlobalStoreConsumer = () => {
  const shopDispatch = useShopDispatch();
  const isLoggedIn = useSelector(customerSelectors.getIsLoggedIn);
  const saveIsCalled = useRef(false);
  const createFn = useCallback((data) => createShoppingBagItem({ data, isLoggedIn, isWithoutValidation : true}), [isLoggedIn]);

  const { handleFetchSubmit } = useFetch(createFn, saveIsCalled.current ? "" : "Items are saved in Logged In Basket", "");

  const { getAllItems, deleteItem } = ShopIndexedDb;
  const getCount = useCallback(() => shopDispatch(ShopInfoAsyncActions.fetchShoppingBagItemsCount({ isLoggedIn })),
    [shopDispatch, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      saveIsCalled.current = true;
      const callArray = [];

      getAllItems()
        .then(({ data: localBasketItems }) => {

          if (!localBasketItems.length) {
            return;
          }

          for (const basketItem of localBasketItems) {
            const { file, id, ...paramsNoFile } = basketItem;
            const reqData = {
              [SHOPPING_BAG_KEY]: paramsNoFile,              
              file
            };
            callArray.push(handleFetchSubmit(reqData), deleteItem(id));
          }

          return Promise.allSettled(callArray)
            .then(() => getCount());

        })
        .catch((er) => {
          console.log("Shopping bug items are not saved", er)
        })
    } else {
      getCount();
    }

  }, [getCount, deleteItem, getAllItems, handleFetchSubmit, isLoggedIn])
};

import PropTypes from "prop-types";
import { Box, CircularProgress } from "@mui/material";

const WithProgress = ({ inProgress, children, fullHeight, size }) => {
  return (
    inProgress
      ?
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          ...fullHeight && {
            alignItems: "center",
            width: "100%",
            height: "50vh"
          }
      }}
      >
        <CircularProgress sx={{ color: "primary.main" }} size={size} />
      </Box>
      :
      children
  );
};

WithProgress.propTypes = {
  inProgress: PropTypes.bool,
  fullHeight: PropTypes.bool,
};

WithProgress.defaultProps = {
  size: 100,
};

export default WithProgress;

import PropTypes from "prop-types";
import { MAP_ADDRESS_EMBEDDED } from "../../constants";

const LocationMap = ({ width, height }) => {
  return (
    <iframe
      width={width}
      height={height}
      id="gmap_canvas"
      src={MAP_ADDRESS_EMBEDDED}
      frameBorder="0"
      scrolling="no"
      title="location"
      marginHeight="0"
      marginWidth="0"
    />
  );
};

LocationMap.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

LocationMap.defaultProps = {
  width: "100%",
  height: "600",
};

export default LocationMap;

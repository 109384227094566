import { Outlet } from "react-router-dom";
import { ShopBar, ShopFooter } from "components/main";
import SEO from "./SEO";
import { MESSAGES } from "../../messages";

const ShopPageWrapper = () => (
  <>
    <SEO title={MESSAGES.SHOP_TITLE} description={MESSAGES.SHOP_TITLE} />
    <ShopBar />
    <Outlet />
    <ShopFooter />
  </>
);

export default ShopPageWrapper;

import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import { uSAPhoneNumberValidateRegex } from "pages/constants";

const PhoneField = ({ register, errors, ...props }) => {
  return(
    <TextField
      margin="normal"
      required
      fullWidth
      label="Phone"
      id="phone"
      name="phone"
      autoComplete="phone"
      {...register("phone", {
        required: "Phone is required.",
        pattern: {
          value: uSAPhoneNumberValidateRegex,
          message: "Phone should be valid."
        }
      })}
      {...errors.phone &&
      {
        error: true,
        helperText: errors.phone.message
      }
      }
      {...props}
    />
  );
};

PhoneField.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default PhoneField;

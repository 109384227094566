import React                                from 'react';
import ReactDOM                             from 'react-dom/client';
import { BrowserRouter }                    from "react-router-dom";
import './index.css';
import App                                  from './App';
import reportWebVitals                      from './reportWebVitals';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ScrollToTop                          from "./utils/ScrollToTop";
import { Provider }                         from "react-redux";
import { shopStore }                        from "store";
import { Messenger }                        from "./messenger";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={shopStore}>
        <BrowserRouter>
          <Messenger/>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

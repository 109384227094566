import { Box, Checkbox, FormControlLabel, FormHelperText, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import { shopPaths } from "routing/constants";
import { AuthPagesWrapper } from "pages/auth/components";
import { useFetch } from "pages/auth/hooks";
import { ConfirmPasswordField, EmailField, FullNameField, PasswordField, PhoneField } from "components/main/form";
import { customerSingUpRequest } from "services/api/auth/authCustomerService";
import { MESSAGES } from "pages/auth/messages";

const SignUp = () => {
  const {
    errors,
    register,
    isSubmitting,
    handleSubmit,
    handleFetchSubmit,
    getValues,
  } = useFetch(customerSingUpRequest, MESSAGES.singUpSuccessMsg, shopPaths.unAuth.login.path);
  const { password } = getValues();

  return (
    <AuthPagesWrapper
      header="Sign Up"
      description="Start your journey with us."
      showSignIn={true}
      showForget={true}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(handleFetchSubmit)}
        noValidate
      >
        <FullNameField
          register={register}
          errors={errors}
        />
        <EmailField
          register={register}
          errors={errors}
        />
        <PhoneField
          register={register}
          errors={errors}
        />
        <PasswordField
          regex
          register={register}
          errors={errors}
          showHelpText
        />
        <ConfirmPasswordField
          register={register}
          errors={errors}
          password={password}
        />

        <Box
          sx={{
            width: "100%",
            pl: { xs: 2, md: 8 },
          }}
        >
          <FormControlLabel
            control={<Checkbox
              value={true}
              color="primary"
              {...register("agree", {
                required: "You should accept terms & conditions"
              })}

            />}
            label={
            <Typography variant="body2" color="text.secondary">I'm agree with <Link to={shopPaths.unAuth.terms.path} target={"_blank"}>Terms & Conditions</Link></Typography>
            }
          />
          {errors.agree &&
            <FormHelperText error>{errors.agree.message}</FormHelperText>
          }


          <FormControlLabel
            control={<Checkbox
              value={true}
              color="primary"
              {...register("isSubscriber")}
            />}
            label={<Typography variant="body2" color="text.secondary">Subscribe to our news letter.</Typography>}
          />

        </Box>
        <LoadingButton
          loading={isSubmitting}
          type={"submit"}
          fullWidth
          size={"large"}
          variant="contained"
          sx={{ mt: 3 }}
        >
          Create Account
        </LoadingButton>
      </Box>

    </AuthPagesWrapper>
  );
}

export default SignUp;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { customerSingInRequest } from "services/api/auth/authCustomerService";

export const customerLogin = createAsyncThunk(
  "customer/login",
  async ({ emailAddress, password, setError }, { rejectWithValue }) => {
    return customerSingInRequest({ emailAddress, password })
      .then(({ data, token, errors, success, title }) => {
        const customerData = { data, token };

        if (success) {
          return customerData;
        }

        if (errors) {
          Object.keys(errors).forEach((err) => {
            setError(err.toLowerCase(), {
              type: "server",
              message: errors[err].join(" ")
            });
          });
        }

        return Promise.reject(title || "Not Logged in");
      })
      .catch((error) => rejectWithValue(error))
  }
);

import { Box, Button, Card, CardContent, ImageListItem, Typography } from "@mui/material";
import { shopPaths }                                                 from "../../../../../routing/constants";
import { Link }                                                      from "react-router-dom";

const BussinessStats = ({ ongoing, partners, projects, years }) => {

  return <Box
    sx={{ maxWidth: "1152px", mx: "auto", position: "relative", textAlign: { xs: "center", sm: "left" }, px: { xs: 2, sm: 3, md: 10.5, lg: 0 }, py: { xs: 8, sm: 12 } }}>
    <img style={{ position: "absolute", left: "-90px", top: "35px", width: "250px" }} src={"2.png"} alt={"triangle"}/>
    <Typography variant="h2" component="div" sx={{ pb: { xs: 2, sm: 2.5 } }}>
      One-Stop print partner for your business
    </Typography>
    <Typography variant="body1" component="div" sx={{ mb: { xs: 6, sm: 8 } }}>
      Build your corporate image with a standout design in luxe paper, and let your business card do the talking.
    </Typography>
    <Box sx={{ display: { lg: "flex" }, flexWrap: "wrap", justifyContent: "space-between" }}>
      <Box
        sx={{ pb: { xs: 6, sm: 12, lg: 0 }, mr: { lg: 12 }, width: { xs: "100%", lg: "45.5%" } }}>
        <Box sx={{ display: { sm: "flex" }, flexWrap: "wrap", textAlign: "center", justifyContent: "space-between" }}>
          <Card
            sx={{ backgroundColor: "primary.contrastText", boxShadow: 0, mr: { xs: 0, sm: 3 }, width: { xs: "100%", sm: "47.5%", lg: "45%" } }}>
            <CardContent sx={{ px: 0, py: 0 }}>
              <Typography variant="h1" component="div" sx={{ color: "primary.light", pb: 1.5 }}>
                {ongoing}+
              </Typography>
              <Typography variant="body1" component="div" sx={{ color: "secondary.contrastText", pb: 1 }}>
                Ongoing projects
              </Typography>
              <Typography variant="body2" component="div" sx={{ color: "grey.500" }}>
                We make every project a unique experience by creating attention-grabbing designs for business cards.
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{ backgroundColor: "primary.contrastText", boxShadow: 0, width: { xs: "100%", sm: "47.5%", lg: "45%" } }}>
            <CardContent sx={{ px: 0, py: 0 }}>
              <Typography variant="h1" component="div" sx={{ color: "primary.light", pb: 1.5 }}>
                {projects}+
              </Typography>
              <Typography variant="body1" component="div" sx={{ color: "secondary.contrastText", pb: 1 }}>
                Completed projects
              </Typography>
              <Typography variant="body2" component="div" sx={{ color: "grey.500" }}>
                We will find the perfect fit for your business, from standard to custom designs.
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{ backgroundColor: "primary.contrastText", boxShadow: 0, mr: { xs: 0, sm: 3 }, width: { xs: "100%", sm: "47.5%", lg: "45%" } }}>
            <CardContent sx={{ px: 0, py: 0 }}>
              <Typography variant="h1" component="div" sx={{ color: "primary.light", pb: 1.5 }}>
                {partners}+
              </Typography>
              <Typography variant="body1" component="div" sx={{ color: "secondary.contrastText", pb: 1 }}>
                Partners
              </Typography>
              <Typography variant="body2" component="div" sx={{ color: "grey.500" }}>
                We build long-term partnerships and value our loyal customers for choosing us.
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{ backgroundColor: "primary.contrastText", boxShadow: 0, width: { xs: "100%", sm: "47.5%", lg: "45%" } }}>
            <CardContent sx={{ px: 0, py: 0 }}>
              <Typography variant="h1" component="div" sx={{ color: "primary.light", pb: 1.5 }}>
                {years}+
              </Typography>
              <Typography variant="body1" component="div" sx={{ color: "secondary.contrastText", pb: 1 }}>
                Years of experience
              </Typography>
              <Typography variant="body2" component="div" sx={{ color: "grey.500" }}>
                Over the years, we have continued to provide our customers with the highest quality custom printing
                services.
              </Typography>
            </CardContent>
          </Card>
        </Box>
        {/*<Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>*/}
        {/*  <Link to={shopPaths.unAuth.completedProjects.path}*/}
        {/*        style={{ textDecoration: "none" }}*/}
        {/*  >*/}
        {/*    <Button variant="contained"*/}
        {/*            color="primary"> Completed Projects</Button>*/}
        {/*  </Link>*/}
        {/*</Box>*/}
      </Box>
      <ImageListItem sx={{ width: { xs: "100%", lg: "46%" } }}>
        <img src={"stats.png"} alt={"hands"}/>
      </ImageListItem>
    </Box>


  </Box>;
};
export default BussinessStats;

import ListSubheader                                                  from "@mui/material/ListSubheader";
import { List, Box, Paper, ListItemButton, ListItemIcon, Typography } from "@mui/material";
import ShoppingBagOutlined
                                                                      from "@mui/icons-material/ShoppingBagOutlined";
import LocationOn
                                                                      from "@mui/icons-material/LocationOn";
import Person                                                         from "@mui/icons-material/Person";
import { shopPaths }                                                  from "routing/constants";
import { useNavigate }                                                from "react-router-dom";

const MyMenu = () => {
  const navigate = useNavigate();

  const handleNavigate = (to) => {
    navigate(`/${to}`);
  };

  return (
    <Box sx={{ mr: { lg: 3 }, minWidth: { lg: "24%" } }}>
      <Paper elevation={1}>
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Dashboard
            </ListSubheader>
          }
        >
          <ListItemButton sx={{ p: 2 }} onClick={() => handleNavigate(shopPaths.auth.myOrders.fullPath)}>
            <ListItemIcon>
              <ShoppingBagOutlined/>
            </ListItemIcon>
            <Typography variant={"body2"}>Orders</Typography>
          </ListItemButton>
        </List>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Settings
            </ListSubheader>
          }
        >
          <ListItemButton sx={{ p: 2 }} onClick={() => handleNavigate(shopPaths.auth.myAccountSettings.fullPath)}>
            <ListItemIcon>
              <Person/>
            </ListItemIcon>
            <Typography variant={"body2"}>Account settings</Typography>
          </ListItemButton>
          <ListItemButton sx={{ p: 2 }} onClick={() => handleNavigate(shopPaths.auth.myAddresses.fullPath)}>
            <ListItemIcon>
              <LocationOn/>
            </ListItemIcon>
            <Typography variant={"body2"}>Addresses</Typography>
          </ListItemButton>
          {/*<ListItemButton sx={{p:2}} onClick={() => handleNavigate(shopPaths.auth.myPaymentMethods.fullPath)}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <CreditCardOutlined/>*/}
          {/*  </ListItemIcon>*/}
          {/*  <Typography variant={"body2"}>Payment methods</Typography>*/}
          {/*</ListItemButton>*/}
        </List>
      </Paper>
    </Box>
  );
};

export default MyMenu;

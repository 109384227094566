import { CardMedia } from "@mui/material";
import Box           from "@mui/material/Box";
import TableCell     from "@mui/material/TableCell";
import * as React    from "react";
import TableRow      from "@mui/material/TableRow";
import { theme }     from "components/theme/constants";

export const RowDescription = ({ row }) => {
  const { selectedProductOptions: selected, price, itemName, product } = row;
  const quantity = selected.find(item => item.productOptionType.name === "Quantity")?.label;
  // const {
  //   handleFetchSubmit: handleSoppingBagItemCreateWithCheckout
  // } = useFetch(createShoppingBagItem, messages.shoppingBagItemCheckout, shopPaths.auth.placeOrder.fullPath, {
  //   withData: true,
  //   makeArray: true
  // });
  // const reorderItem = () => {
  //   const data = {
  //     shoppingBag:{
  //       productId: row.productId,
  //       isArtwork: row.isArtwork,
  //       isProofing: row.isProofing,
  //       printing: row.printing,
  // {/*      selectedOptions:row.selectedOptions,*/
  // }
  // {/*      priceFront: row.priceFront,*/
  // }
  //       notes:row.notes,
  //       itemName: row.itemName
  //     },
  //     file:[]
  //   };
  //   return handleSoppingBagItemCreateWithCheckout(data)
  // };
  return <>
    <TableRow
      hover
      tabIndex={-1}
      sx={{ alignItems: "center" }}
    >
      <TableCell align="left" padding="none" width={"20%"} sx={{ pr: 1 }}>
        <Box display={"flex"}>
          {
            product?.images?.map((image, i) => {
                if (i > 1) {
                  return;
                }
                return <CardMedia
                  sx={{ m: 0.5, borderRadius: "4px", width: "60px" }}
                  key={i}
                  component="img"
                  image={image.uri}
                  alt={image.name}
                />;
              }
            )
          }
        </Box>
      </TableCell>
      <TableCell
        padding="none"
        sx={{ ...theme.typography.body4 }}
        width={"30%"}
      >
        {itemName || product.name}
      </TableCell>
      <TableCell align="left" padding="none" sx={{ pr: 2 }} width={"15%"}>x{quantity}</TableCell>
      {/*<TableCell align="left" padding="none" sx={{ whiteSpace: "nowrap", ...theme.typography.body4, pr: 2 }}*/}
      {/*           width={"15%"}>$ {price}</TableCell>*/}
      {/*<TableCell align="left" padding="none" sx={{ ...theme.typography.body4, pb: 1 }} width={"20%"}>*/}
      {/*  <Button onClick={(e) => {*/}
      {/*    e.stopPropagation();*/}
      {/*    reorderItem();*/}
      {/*  }} variant={"outlined"} sx={{ borderRadius: "50px", textTransform: "none" }}> Reorder Item</Button>*/}
      {/*</TableCell>*/}
    </TableRow>
  </>;
};
import * as PropTypes from "prop-types";
import React from "react";
import { useFormContext } from "react-hook-form";
import {
  ADDRESS_TYPES,
  billingAddress,
  billingAddressId,
  ORDER_KEY,
  shippingAddress,
  shippingAddressId,
} from "../constants";
import { FormHelperText } from "@mui/material";

const AddressErrorsContainer = ({ addressType }) => {
  const { errors } = useFormContext();

  switch (addressType) {
    case ADDRESS_TYPES.BILLING:
      return (
        <>
          {errors[ORDER_KEY]?.[billingAddressId] &&
            <FormHelperText error>{errors[ORDER_KEY][billingAddressId].message}</FormHelperText>
          }
          {errors[ORDER_KEY]?.[billingAddress] &&
            <FormHelperText error>{errors[ORDER_KEY][billingAddress].message}</FormHelperText>
          }
        </>
      );
    case ADDRESS_TYPES.SHIPPING:
      return (
        <>
          {errors[ORDER_KEY]?.[shippingAddress] &&
            <FormHelperText error>{errors[ORDER_KEY][shippingAddress].message}</FormHelperText>
          }
          {errors[ORDER_KEY]?.[shippingAddressId] &&
            <FormHelperText error>{errors[ORDER_KEY][shippingAddressId].message}</FormHelperText>
          }
        </>
      );
  }
};

AddressErrorsContainer.propTypes = {
  addressType: PropTypes.oneOf(Object.keys(ADDRESS_TYPES)).isRequired,
};

export default AddressErrorsContainer;

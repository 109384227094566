import React from "react";
import { Box, Typography } from "@mui/material";
import useProductForm from "pages/main/Product/components/ProductForm/useProductForm";
import ProductOrderReady from "pages/main/Product/components/ProductForm/ProductOrderReady";
import OrderArtWork from "pages/main/Product/components/ProductForm/OrderArtWork";
import ProductOrderImages from "pages/main/Product/components/ProductForm/ProductOrderImages";
import ProductOrderInfo from "pages/main/Product/components/ProductForm/ProductOrderInfo";
import SaveButtons from "pages/main/Product/components/ProductForm/SaveButtons";

const ProductWrapper = ({ fields, basePrice, id, designPrice, shoppingBagItemData, printing, calculationType, ProductOptions }) => {
  const {
    handleBuy,
    addToShoppingBag,
    updateSoppingBagItem,
    isSubmitting,
    setValue,
    getValues,
    trigger,
    register,
    handleSubmit,
    errors,
    handleOptionsChange,
    price,
    setPurchasePrice,
    getSelectedValues,
    getNumber,
    isReseller
  } = useProductForm(id, fields, designPrice, basePrice, shoppingBagItemData, printing, calculationType);

  return (
    <Box
      component="form"
      name="shoppingBag"
      noValidate
      sx={{ width: { md: "50%" }, ml: { xs: 0, md: 3 }, mt: 1 }}
    >
      <ProductOptions
        getNumber={getNumber}
        getSelectedValues={getSelectedValues}
        selectedOptions={shoppingBagItemData?.selectedOptions}
        sortedFields={fields}
        register={register}
        handleOptionsChange={handleOptionsChange}
      />
      <ProductOrderReady
        designPrice={designPrice}
        printing={printing}
        setValue={setValue}
        getValues={getValues}
        register={register}
        price={price}
        setPurchasePrice={setPurchasePrice}
      />
      <OrderArtWork
        setValue={setValue}
        getValues={getValues}
        register={register}
        trigger={trigger}
        setPurchasePrice={setPurchasePrice}
      />

      <ProductOrderImages
        register={register}
        isSubmitting={isSubmitting}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        trigger={trigger}
      />

      <ProductOrderInfo
        register={register}
        errors={errors}
      />
      <Box display={"flex"} justifyContent={"space-between"} sx={{ py: 1 }}>
        {isReseller
          ? <Typography fontWeight={500} color={"primary.main"} variant={"body"}> Total Price</Typography>
          : <Typography fontWeight={900} color={"primary.main"}> Total Price</Typography>
        }
        <Typography fontWeight={900} variant={isReseller ? "body" : "h3"}>$ {price.purchasePrice}</Typography>
      </Box>
      {isReseller &&
        <Box display={"flex"} justifyContent={"space-between"} sx={{ py: 1 }}>
          <Typography fontWeight={900} color={"primary.main"}> For You</Typography>
          <Typography fontWeight={900} variant={"h3"}>$ {(price.purchasePrice*0.8).toFixed(2)}</Typography>
        </Box>
      }
      <SaveButtons
        loading={isSubmitting}
        handleSubmit={handleSubmit}
        addToShoppingBag={addToShoppingBag}
        shoppingBagItemId={shoppingBagItemData?.id}
        updateSoppingBagItem={updateSoppingBagItem}
        handleBuy={handleBuy}
        disabled={!fields.length}
      />
    </Box>
  );
};

export default ProductWrapper;

import { AuthPagesWrapper } from "pages/auth/components";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { EmailField, PasswordField } from "components/main/form";
import { useFetch } from "pages/auth/hooks";
import { customerLogin } from "store/customer/customerActions";
import { MESSAGES } from "pages/auth/messages";
import { shopPaths } from "routing/constants";

const Login = () => {
  const {
    errors,
    register,
    isSubmitting,
    handleSubmit,
    handleFetchSubmit,
  } = useFetch(customerLogin, MESSAGES.signIn, `/${shopPaths.auth.myOrders.fullPath}`, {
    shouldDispatch: true,
    failRedirectPath: shopPaths.unAuth.resend.path,
    errorMsg: "Email is not confirmed"
  });

  return (
    <AuthPagesWrapper
      header="Log In"
      description="Welcome back! Please enter your details."
      showSignUp={true}
      showForget={true}
      showResend={true}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(handleFetchSubmit)}
        noValidate
      >
        <EmailField
          register={register}
          errors={errors}
        />
        <PasswordField
          register={register}
          errors={errors}
          regex={false}
        />
        <LoadingButton
          loading={isSubmitting}
          type={"submit"}
          fullWidth
          size={"large"}
          variant="contained"
          sx={{ mt: 4 }}
        >
          Log In
        </LoadingButton>
      </Box>
    </AuthPagesWrapper>
  );
};

export default Login;

import { createSlice } from "@reduxjs/toolkit";
import { customerLogin } from "store/customer/customerActions";

const CUSTOMER_TOKEN_KEY = "customerVisa";
const saveCustomerInfo = (data) => localStorage.setItem(CUSTOMER_TOKEN_KEY, JSON.stringify(data));
export const customerDataUpdate = (newData) => {
  const { data, token } = getCustomerInfo();
  localStorage.setItem(CUSTOMER_TOKEN_KEY, JSON.stringify({ data: { ...data, ...newData }, token }));
};
const removeCustomerInfo = () => localStorage.removeItem(CUSTOMER_TOKEN_KEY);
const getCustomerInfo = () => JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_KEY) || "{}");
export const getCustomerVisa = () => getCustomerInfo().token;

const initialState = {
  isLoading: false,
  customerToken: getCustomerVisa(),
  isLoggedIn: !!getCustomerVisa(),
  customerInfo: getCustomerInfo().data,
  error: null,
  success: false,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    logout: (state) => {
      removeCustomerInfo(); // deletes token from storage

      return {
        ...state,
        error: null,
        success: true,
        isLoading: false,
        isLoggedIn: false,
        customerInfo: null,
        customerToken: null,
      };
    },
    updateCustomerDada: (state, { payload }) => {
      customerDataUpdate(payload);
      return {
        ...state,
        ...payload
      }
    }
  },
  extraReducers: {
    [customerLogin.pending]: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    [customerLogin.fulfilled]: (state, { payload }) => {
      saveCustomerInfo(payload);
      return {
        ...state,
        success: true,
        isLoading: false,
        isLoggedIn: true,
        customerInfo: payload.data,
        customerToken: payload.token,
      };
    },
    [customerLogin.rejected]: (state, { payload }) => {
      if (payload?.status === 403) {
        return ({
          ...state,
          isLoading: false,
          ...payload
        })
      }

      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }
  }
});

export const { logout, updateCustomerDada } = customerSlice.actions;
export default customerSlice.reducer;

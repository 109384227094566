import { useEffect } from "react";
import { useSnackbar } from 'react-notistack';
import { snackBarVariants } from "pages/constants";

export const snackBarDuration = 5000;

const useShopSnackbar = (message, variant = snackBarVariants.info, callbackFn, callbackErrorFn) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    message && enqueueSnackbar(message, { variant, hide: snackBarDuration });

    if (callbackFn && variant === snackBarVariants.success) {
      callbackFn();
    }

    if (callbackErrorFn && variant === snackBarVariants.error) {
      callbackErrorFn();
    }

  }, [message, enqueueSnackbar, variant, callbackFn, callbackErrorFn])
};

export default useShopSnackbar;

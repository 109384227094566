import { Box }    from "@mui/material";
import { Outlet } from "react-router-dom";

const EmptyWrapper = () => {
  return(
    <Box sx={{backgroundColor:"grey.50"}}>
      <Box justifyContent={"space-between"} sx={{ px: { xs: 2, sm: 3, md: 10.5, lg: 0 }, mx: "auto", py:4, maxWidth: "1152px"}}>
        <Outlet />
      </Box>
    </Box>
  )
};

export default EmptyWrapper;

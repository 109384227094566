import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShopInfoAsyncActions from "store/shopInfo/ShopInfoAsyncActions";
import customerSelectors from "store/customer/customerSelectors";
import shopInfoSelectors from "./shopInfo/shopInfoSelectors";

export const useShopDispatch = () => useDispatch();

export const useShopSelector = useSelector;

export const useShopInitialize = () => {
  const shopDispatch = useShopDispatch();
  const isLoggedIn = useSelector(customerSelectors.getIsLoggedIn);

  useEffect(() => {
    shopDispatch(ShopInfoAsyncActions.fetchShopInitializeData({ isLoggedIn, saveLocally: true }));

    return () => {
    }
  }, [shopDispatch, isLoggedIn]);

  const { headerHTML } = useShopSelector(shopInfoSelectors.getCompanyInfo);
  const head = useRef(document.querySelector("head"));

  useEffect(() => {
    const range = document.createRange();
    range.selectNode(head.current);
    const documentFragment = range.createContextualFragment(headerHTML);
    head.current.appendChild(documentFragment);
  }, [headerHTML]);

};

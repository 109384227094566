import { Box, CardMedia }               from "@mui/material";
import { Swiper, SwiperSlide }          from "swiper/react";
import { Autoplay, Pagination }         from "swiper";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/autoplay";
import { theme }                        from "components/theme/constants";
import { useShopSelector }              from "store/hooks";
import shopInfoSelectors                from "store/shopInfo/shopInfoSelectors";
import { useEffect, useMemo, useState } from "react";
import { Link }                         from "react-router-dom";

const MainSlider = () => {
  const mainSliderImages = useShopSelector(shopInfoSelectors.getMainSliderImages);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const images = useMemo(() => {
    if (screenWidth < 450) {
      return mainSliderImages.filter(i => i.isMobile);
    } else {
      return mainSliderImages.filter(i => !i.isMobile);
    }
  }, [mainSliderImages, screenWidth]);

  return (
    <Box>
      <Swiper
        style={{
          "--swiper-pagination-color": theme.palette.primary.main,
          "--swiper-pagination-bullet-width": "10px",
          "--swiper-pagination-bullet-height": "10px",
          "--swiper-pagination-bullet-inactive-color": theme.palette.secondary.contrastText

        }}
        slidesPerView={1}
        slidesPerGroup={1}
        pagination={{
          type: "bullets",
          enabled: true,
          clickable: true
        }}
        allowTouchMove={true}
        speed={2000}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }}
        loop={true}
        modules={[Pagination, Autoplay]}
      >
        {images.map(image => <SwiperSlide key={image.id}>
            {
              !!image.link ?
                <Link to={`${image.link}`}>
                  <CardMedia
                    sx={{ borderRadius: "4px" }}
                    component="img"
                    width="100%"
                    image={image.uri}
                    alt={image.name}
                  />
                </Link>
                :
                <CardMedia
                  sx={{ borderRadius: "4px" }}
                  component="img"
                  width="100%"
                  image={image.uri}
                  alt={image.name}
                />
            }
          </SwiperSlide>
        )}
      </Swiper>
    </Box>
  );
};

export default MainSlider;

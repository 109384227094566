import { TextField } from "@mui/material";
import { emailValidationRegex } from "pages/constants";
import PropTypes from "prop-types";

const EmailField = ({ register, errors, ...props }) => {
  return(
    <TextField
      margin="normal"
      required
      fullWidth
      label="Email"
      id="emailAddress"
      name="emailAddress"
      autoComplete="email"
      {...register("emailAddress", {
        required: "Email is required.",
        pattern: {
          value: emailValidationRegex,
          message: "Email should be valid"
        }
      })}
      {...errors.emailAddress &&
      {
        error: true,
        helperText: errors.emailAddress.message
      }
      }
      {...props}
    />
  );
};

EmailField.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default EmailField;

import { deleteAPI, getAPI, postAPI, putAPI } from "services/api/NetService";
import {
  ADDRESS_VALIDATION_PATH,
  ADDRESSES_API_PATH,
  ADDRESSES_ID_API_PATH,
  ADDRESSES_STATES_API_PATH,
  ZIPCODE_ID_API_PATH
} from "services/api/addresses/constants";
import ShopIndexedDb from "indexedDB/ShopIndexedDb";

const ADDRESS_VALID_KEY = "isValidAddress";

export const getUSStatesRequest = () =>
  getAPI(ADDRESSES_STATES_API_PATH);

export const getAllMyAddressesRequest = (isLoggedIn) => {
  if (isLoggedIn) {
    return getAPI(ADDRESSES_API_PATH);
  }

  return ShopIndexedDb.getAllAddresses()
    .then(({ data: allAddresses }) => {

      const allAddressesValidations = allAddresses.reduce((promises, currentAddress) => {
        if (currentAddress.hasOwnProperty(ADDRESS_VALID_KEY)) {
          promises.push(Promise.reject());
        } else {
          promises.push(postAPI(ADDRESS_VALIDATION_PATH, currentAddress));
        }

        return promises;
      }, []);

      return Promise.allSettled(allAddressesValidations)
        .then((validationResponses) => {
          validationResponses.forEach(({ value }, index) => {
            if (value) {
              allAddresses[index].isValid = value.success;
            }
          });

          return { data: allAddresses };
        });
    });
};

export const getAddressesByIdRequest = (id) => {
  return ShopIndexedDb.getAddressById(id);
};

export const createAddressRequest = ({ data, isLoggedIn }) => {
  if (isLoggedIn) {
    return postAPI(ADDRESSES_API_PATH, data)
      .then(({ data, success, title }) => {
        if (success) {
          return { data, success };
        }
        return Promise.reject(title)
      });
  }

  return postAPI(ADDRESS_VALIDATION_PATH, data)
    .then(({ success, title }) => {
      if (success) {
        return ShopIndexedDb.addAddress({ ...data, [ADDRESS_VALID_KEY]: true, isValid: true });
      }
      return Promise.reject(title);
    });
};

export const updateAddressRequest = ({ data, isLoggedIn }) => {
  if (isLoggedIn) {
    return putAPI(ADDRESSES_ID_API_PATH(data.id), data).then(({ data, success, title }) => {
      if (success) {
        return { data, success };
      }
      return Promise.reject(title);
    });
  }

  return postAPI(ADDRESS_VALIDATION_PATH, data)
    .then(({ success, title }) => {
      if (success) {
        return ShopIndexedDb.putAddress({ ...data, [ADDRESS_VALID_KEY]: true, isValid: true });
      }
      return Promise.reject(title);
    })
};

export const deleteAddressRequest = ({ id, isLoggedIn }) => {
  if (isLoggedIn) {
    return deleteAPI(ADDRESSES_ID_API_PATH(id));
  }
  return ShopIndexedDb.deleteAddress(id);
}

export const validateZipCodeRequest = (zipcode) => {
  return getAPI(ZIPCODE_ID_API_PATH(zipcode))
    .then(({ data, success, title }) => {
      if (success) {
        return data;
      }

      return false;
    });
};

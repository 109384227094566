import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, RadioGroup } from "@mui/material";
import { SH_FIELD_NAMES } from "pages/main/Product/components/ProductForm/constants";
import ProductOrderReadyItem from "pages/main/Product/components/ProductForm/ProductOrderReadyItem";


const ProductOrderReady = ({ register, price, setPurchasePrice, setValue, getValues, printing: readyOptions, designPrice }) => {
  const { printing, isArtwork } = SH_FIELD_NAMES;
  const defaultValue = getValues(printing);
  const isArtworkValue = getValues(isArtwork);
  const [currentCheckedValue, setCurrentCheckedValue] = useState(0)

  useEffect(() => {
    if (defaultValue) {
      const selectedIndex = readyOptions.findIndex(({ name, coefficient }) =>
        name === defaultValue.name && coefficient === defaultValue.coefficient);
      setCurrentCheckedValue(selectedIndex);
    }
  }, [defaultValue, readyOptions]);

  const onChange = useCallback((ev, val) => {
    setCurrentCheckedValue(+val);
    setPurchasePrice()
  }, [ setPurchasePrice]);

  return (
    <FormControl sx={{ width: "100%", my: 1 }}>
      <RadioGroup
        name={printing}
        id={printing}
        onChange={onChange}
        sx={{ display: "flex", flexDirection:"row", justifyContent: "space-between" }}
      >
        {readyOptions?.map((readyOpt, index) => {
          const checked = currentCheckedValue === index;
          const frontPrice = +(price.total * readyOpt.coefficient + (isArtworkValue ? designPrice : 0)).toFixed(2);

          if (checked) {
            setValue(printing, readyOptions[currentCheckedValue])
          }

          return (
            <ProductOrderReadyItem
              key={index}
              checked={checked}
              index={index}
              register={register}
              frontPrice={frontPrice}
              readyOpt={readyOpt}
            />
          );
        })
        }
      </RadioGroup>
    </FormControl>
  );
};

ProductOrderReady.propTypes = {
  register: PropTypes.func.isRequired,
  designPrice: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  printing: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    coefficient: PropTypes.number
  })).isRequired
};

export default ProductOrderReady;

import { useParams }       from "react-router-dom";
import { Box, Typography } from "@mui/material";
import WithProgress        from "../../../components/main/WithProgress";
import useProduct          from "./useProduct";
import { ProductTabs }     from "./components";
import SEO                 from "components/main/SEO";
import ProductSlider       from "pages/main/Product/components/ProductSlider";
import ProductWrapper      from "pages/main/Product/components/ProductForm/ProductWrapper";
import OrderDigitalOptions from "pages/main/Product/components/ProductForm/DigitalProduct/OrderDigitalOptions";
import OrderLargeOptions   from "pages/main/Product/components/ProductForm/LargeProduct/OrderLargeOptions";
import { useMemo }         from "react";
import OrderOtherOptions   from "./components/ProductForm/OtherProduct/OrderOtherOptions";

const Product = () => {
  const { id, name: productName, shoppingBagItemId } = useParams();

  const {
    isLoaded, name, isPrinting, images = [], mainImageURI, mainImageAltText, metaDescriptionEN, metaKeywordsEN, metaTitleEN, header,
    basePrice, designPrice, description, productOptionTypeGrouped: fields = [], shoppingBagItemData, printing,
    calculationType, templates
  } = useProduct(productName, id, shoppingBagItemId);

  const ProductOptions = useMemo(() => {
    switch (calculationType) {
      case 0:
        return OrderDigitalOptions;
      case 1:
        return OrderLargeOptions;
      case 2:
        return OrderOtherOptions;
      default:
        return <></>;
    }
  }, [calculationType]);

  return (
    <Box sx={{ px: { xs: 2, sm: 3, md: 10.5, lg: 0 }, maxWidth: "1152px", m: "auto", pt: 4, pb: 9 }}>
      <WithProgress inProgress={!isLoaded} fullHeight={true}>
          <SEO description={metaDescriptionEN} keywords={metaKeywordsEN} title={metaTitleEN}/>
          <Typography variant={"h3"} sx={{ pb: { xs: 3 } }}>
            {isPrinting ? `${name} Printing` : name}
          </Typography>
          <Box sx={{ display: { md: "flex" } }}>
            <ProductSlider mainImageAltText={mainImageAltText} images={images} mainImageURI={mainImageURI}/>
            <ProductWrapper
              id={id}
              fields={fields}
              printing={printing}
              basePrice={basePrice}
              ProductOptions={ProductOptions}
              designPrice={designPrice}
              calculationType={calculationType}
              shoppingBagItemData={shoppingBagItemData}
            />
          </Box>
          <ProductTabs header={header} description={description} templates={templates}/>
      </WithProgress>
    </Box>
  );
};

export default Product;

import { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Typography
}                                                    from "@mui/material";
import { ExpandLess, ExpandMore }                    from "@mui/icons-material";
import { getSelectedProductOptions } from "utils/orderHelper";

export const OrderListItem = ({ data }) => {
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => setOpen(!open), [open]);

  const quantity = useMemo(() => {
    let sel = !!data.selectedProductOptions.length && data.selectedProductOptions[0].productOptionType
      ? data.selectedProductOptions
      : getSelectedProductOptions(data.product.productOptionTypeGrouped, data.selectedOptions);

    return sel.find(option => option.productOptionType.name === "Quantity").label;
  }, [data.product.productOptionTypeGrouped, data.selectedOptions, data.selectedProductOptions]);

  return <>
      <Button
        onClick={handleClick}
        sx={{
          px: 0,
          width: "100%",
          display: "flex",
          textTransform: "none",
          color: "primary.contrastText",
          justifyContent: "space-between"
        }}
      >
        <Box sx={{ display: "flex", width: "50%", alignItems: "center", flexGrow: 1 }}>
          {open ? <ExpandLess/> : <ExpandMore/>}
          <Typography variant={"body3"}>
            {data.product.name}
          </Typography>
        </Box>
        <Typography variant={"body3"}>
          {`$ ${data.price || data.priceFront}`}
        </Typography>
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box display={"flex"} sx={{ px: 6, mb:1 }}>
          <Typography variant={"body3"} sx={{ ml: 2 }}>
            {quantity} pieces
          </Typography>
        </Box>
        <Box display={"flex"} sx={{ px: 6, mb:1 }}>
          <Typography variant={"body3"} sx={{ ml: 2 }}>
            {data.isArtwork ? "Design needed" : "Without design"}
          </Typography>
        </Box>
        {!data.isArtwork && <Box display={"flex"} sx={{ px: 6, mb: 1 }}>
          <Typography variant={"body3"} sx={{ ml: 2 }}>
            { data.isProofing ? "Proofing needed" : "Without proof"}
          </Typography>
        </Box>}
        <Box display={"flex"} sx={{ px: 6, mb:1 }}>
          <Typography variant={"body3"} sx={{ ml: 2 }}>
            {data.printing?.name}
          </Typography>
        </Box>
      </Collapse>
    </>;
};

import { TextField } from "@mui/material";
import PropTypes from "prop-types";

const FullNameField = ({register, errors, ...props}) => {
  return (
    <TextField
      margin="normal"
      required
      fullWidth
      label="Full Name"
      id="fullName"
      name="fullName"
      autoComplete="fullName"
      {...register("fullName", { required: "Full Name is required." })}
      {...errors.fullName &&
      {
        error: true,
        helperText: errors.fullName.message
      }
      }
      {...props}
    />
  );
};

FullNameField.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default FullNameField;

import { uSAPhoneNumberValidateRegex, zipCodeValidateRegex } from "pages/constants";
import { validateZipCodeRequest } from "services/api/addresses/myAddressesService";
const COUNTRY = "US";

export const FIELD_TYPES = {
  SELECT: "select",
  CHECKBOX: "checkbox",
};

export const ADDRESS_ACTIONS = {
  DELETE: "DELETE",
  SET_DEFAULT: "SET_DEFAULT",
};

export const getAddressFields = ({ fullName, address1, address2, phone, city, country, state, zipCode, isDefault }) => {
  return [
    {
      label: "Full Name",
      name: "fullName",
      id: "fullName",
      required: true,
      defaultValue: fullName,
    },
    {
      label: "Address Line 1",
      name: "address1",
      id: "address1",
      required: true,
      defaultValue: address1,
    },
    {
      label: "Address Line 2",
      name: "address2",
      id: "address2",
      required: false,
      defaultValue: address2,
    },
    {
      label: "Phone",
      name: "phone",
      id: "phone",
      required: true,
      defaultValue: phone,
      pattern: uSAPhoneNumberValidateRegex,
    },
    {
      label: "Zip code",
      name: "zipCode",
      id: "zipCode",
      required: true,
      defaultValue: zipCode,
      valueAsNumber: true,
      pattern: zipCodeValidateRegex,
      validate: validateZipCodeRequest
    },
    {
      label: "City",
      name: "city",
      id: "city",
      required: true,
      defaultValue: city,
    },
    {
      label: "State",
      name: "state",
      id: "state",
      required: true,
      defaultValue: state,
      type: FIELD_TYPES.SELECT,
      values: [],
    },
    {
      label: "Country",
      name: "country",
      id: "country",
      required: true,
      defaultValue: country || COUNTRY,
      disabled: true,
    },
    {
      label: "Set as default address",
      name: "isDefault",
      id: "isDefault",
      required: false,
      defaultValue: isDefault,
      type: FIELD_TYPES.CHECKBOX,
    },
  ];
};

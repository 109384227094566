import PropTypes from "prop-types";
import { Avatar, Box, Container, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { shopPaths } from "routing/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { theme } from "components/theme/constants";

const AuthPagesWrapper = ({
  children,
  success,
  header,
  headerAlign,
  HeaderIcon,
  description,
  showSignIn,
  showSignUp,
  showForget,
  showResend,
  showBackLink
}) => {

  return (
    <Container
      maxWidth="xs"
      sx={{
        mt: 8,
        mb: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          mb: 3,
          textAlign: headerAlign
        }}
      >
        {HeaderIcon &&
          <Avatar
            sx={{
              backgroundColor: success ? theme.palette.success.success30 : theme.palette.primary.main30,
              width: "48px",
              height: "48px",
              mb: 3,
              mx: "auto"
            }}
          >
            <HeaderIcon color={success ? "success" : "primary"} />
          </Avatar>
        }
        <Typography variant="h3" gutterBottom>
          {header}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Box>
      {children}
      {(showSignIn || showSignUp || showForget || showResend) &&
        <Divider sx={{mt: 3, mb: 3, width: "100%",}}>OR</Divider>
      }
      <Box>
        {showSignIn &&
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Have an account? <Link to={shopPaths.unAuth.login.path}>Sign in</Link>
          </Typography>
        }
        {showSignUp &&
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Don't have account? <Link to={shopPaths.unAuth.signUp.path}>Sign Up</Link>
          </Typography>
        }
        {showForget &&
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Forgot password? <Link to={shopPaths.unAuth.forgot.path}>Reset it</Link>
          </Typography>
        }
        {showResend &&
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Resend confirmation email? <Link to={shopPaths.unAuth.resend.path}>Resend email</Link>
          </Typography>
        }
        {showBackLink &&
          <Link to={shopPaths.unAuth.login.path}>
            <Typography sx={{ mb: 2, mt: 4, display: "flex",  alignItems: "center"}}>
              <ArrowBackIcon sx={{ mr: 2 }} />
              Back to log in
            </Typography>
          </Link>
        }
      </Box>
    </Container>
  )
};

AuthPagesWrapper.propTypes = {
  header: PropTypes.string,
  HeaderIcon: PropTypes.object,
  headerAlign: PropTypes.oneOf(["start", "center", "end"]),
  description: PropTypes.string,
  success: PropTypes.bool,
  showSignIn: PropTypes.bool,
  showSignUp: PropTypes.bool,
  showForget: PropTypes.bool,
  showResend: PropTypes.bool,
  showBackLink: PropTypes.bool
};

AuthPagesWrapper.defaultProps = {
  headerAlign: "start"
}

export default AuthPagesWrapper;

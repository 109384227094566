import PropTypes from "prop-types";
import { useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import VisibilityButton from "./VisibilityButton";

const ConfirmPasswordField = ({ register, required=true, errors, password, ...props }) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  return (
    <TextField
      margin="normal"
      required
      fullWidth
      label="Confirm Password"
      id="confirmPassword"
      name="confirmPassword"
      autoComplete="confirmPassword"
      type={show ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <VisibilityButton
              show={show}
              onClick={toggleShow}
            />
          </InputAdornment>
        ),
      }}
      {...register("confirmPassword", {
        required: !!required && "Confirm Password is required.",
        validate: (value) => {
          return value === password || "The passwords do not match"
        }
      })}
      {...errors.confirmPassword &&
      {
        error: true,
        helperText: errors.confirmPassword.message
      }
      }
      {...props}

    />
  );
};

ConfirmPasswordField.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  password: PropTypes.string,
};

export default ConfirmPasswordField;

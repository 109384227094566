import { getAPI }            from "../NetService";
import { PROJECTS_PATH }     from "./constants";

export const getCompletedProjectsRequest = () => {
  return getAPI(PROJECTS_PATH)
  .then(({ data: projects, success, title }) => {
    if (success) {
      return projects;
    }

    return Promise.reject(title)
  })
};

export const getProjectRequest = (id) => {
  return getAPI(`${PROJECTS_PATH}/${id}`)
  .then(({ data: project, success, title }) => {
    if (success) {
      return project;
    }

    return Promise.reject(title)
  })
};
export const SHOPPING_BAG_KEY = "shoppingBag";
export const WITHOUT_VALIDATION_KEY = "withoutValidation";

export const SH_FIELD_NAMES = {
  productId: `${SHOPPING_BAG_KEY}.productId`,
  printing: `${SHOPPING_BAG_KEY}.printing`,
  selectedOptions: `${SHOPPING_BAG_KEY}.selectedOptions`,
  selectedProductOptions: `${SHOPPING_BAG_KEY}.selectedProductOptions`,
  priceFront: `${SHOPPING_BAG_KEY}.priceFront`,
  isArtwork: `${SHOPPING_BAG_KEY}.isArtwork`,
  isProofing: `${SHOPPING_BAG_KEY}.isProofing`,
  notes: `${SHOPPING_BAG_KEY}.notes`,
  itemName: `${SHOPPING_BAG_KEY}.itemName`,
  customerUploads: `${SHOPPING_BAG_KEY}.customerUploads`,
  file: "file",
};

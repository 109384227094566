import { Box, ImageListItem, Typography } from "@mui/material";

const AboutUs = () => {
  return <Box
    sx={{ maxWidth: "1152px", mx: "auto", py: { xs: 8, md: 12 }, px: { xs: 2, sm: 3, md: 10.5, lg: 0 } }}>
    <Typography variant="h2" component="div" sx={{ mb: { xs: 3, md: 4 }, textAlign: { xs: "center", md: "left" } }}>
      About Us
    </Typography>
    <Box sx={{ display: { lg: "flex" }, alignItems: { lg: "center" } }}>
      <Box
        sx={{ mr: { lg: 8 }, pb: { xs: 6, md: 8, lg: 0 }, width: { lg: "50%" }, textAlign: { xs: "center", md: "left" } }}>
        <Typography variant="body1" sx={{ pb: 3, color: "primary.contrastText" }}>
          Founding Director: Gnel Chibukhchyan
        </Typography>
        <Typography variant="body2" component="p"
                    sx={{ whiteSpace: "pre-line", fontStyle: "italic", color: "grey.600" }}>
          “{SHOP_TITLE}” is an online printing design and production business, passionate about the great design
          and excellence we can bring to our customers.<br/><br/>
          Our team of creatives is born from the same passion and goals that drive “{SHOP_TITLE}”. We provide our
          customers with high-level printing services with extensive practical skills and solid work
          experience.<br/><br/>
          We provide a personalized approach to every customer. Keeping an eye on the latest trends and market demand,
          we create unique and powerful designs for your business.<br/><br/>
          Guided by sustainable business principles over the years of its existence, “{SHOP_TITLE}” has managed
          to acquire loyal partners and friends for long-term collaboration.
        </Typography>
      </Box>
      <ImageListItem sx={{ width: { xs: "100%", lg: "50%" }, position: "relative" }}>
        <Box sx={{ position: "absolute", bottom: "-150px", right: "-50px" }}>
          <svg width="280" height="270" viewBox="0 0 293 284" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b_9917_89401)">
              <path d="M280.93 11.5578L195.719 268.145L16.1133 66.0564L280.93 11.5578Z" stroke="#AF995A"
                    strokeOpacity="0.3" strokeWidth="16"/>
            </g>
            <defs>
              <filter id="filter0_b_9917_89401" x="-44.0703" y="-44.0713" width="381.961" height="372.888"
                      filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0.9" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImage" stdDeviation="22.5"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9917_89401"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_9917_89401" result="shape"/>
              </filter>
            </defs>
          </svg>
        </Box>
        <img src={"about.jpg"} alt={"about us"}/>
      </ImageListItem>
    </Box>
  </Box>;
};

export default AboutUs;

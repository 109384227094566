import { useCallback, useEffect, useMemo, useReducer }                       from "react";
import useShopSnackbar                                                       from "../../../../hooks/useShopSnackbar";
import { MESSAGES } from "../../messages";
import { calculateTotalPrice } from "../helper";
import { PLACE_ORDER_INITIAL_STATE, PLACE_ORDER_ACTIONS, placeOrderReducer } from "./placeOrderReducer";
import { useNavigate }                                                       from "react-router-dom";
import { shopPaths }                                                         from "routing/constants";
import { useMyAddresses }                                                    from "../../MyAddresses/useMyAddresses";
import { createGuestOrder, createOrder }                                     from "services/api/Orders/OrdersService";
import { useForm }                                                           from "react-hook-form";
import {
  BILLING_ADDRESS_ID_KEY,
  BILLING_ADDRESS_KEY,
  CARD_CODE_ERROR_KEY,
  CARD_CODE_KEY,
  CARD_DETAILS_KEY,
  CARD_NUM_ERROR_KEY,
  CARD_NUMBER_KEY,
  cardNumber,
  EXPIRATION_DATE_KEY,
  expirationDateMonth,
  expirationDateYear,
  IS_WITHOUT_SHIPPING_KEY,
  isWithoutShipping,
  ORDER_KEY,
  ORDER_TABS,
  SELECTED_SHIPPING_RATE_KEY,
  SELECTED_SHIPPING_TOTAL_CHARGES_KEY,
  SHIPPING_ADDRESS_ID_KEY,
  SHIPPING_ADDRESS_KEY,
  SHIPPING_RATE_SELECT_NAME_KEY,
} from "pages/customer/ShoppingBag/PlaceOrder/constants";
import { useShopDispatch, useShopSelector }                                  from "store/hooks";
import customerSelectors                                                     from "store/customer/customerSelectors";
import ShopInfoAsyncActions                                                  from "store/shopInfo/ShopInfoAsyncActions";
import {
  deleteShoppingBagItem,
  updateShoppingBagItem
}                                                                            from "services/api/shoppingBag/ShoppingBagService";

export const TAX = 9.5;

export const usePlaceOrder = (stateData) => {
  const navigate = useNavigate();
  const isLoggedIn = useShopSelector(customerSelectors.getIsLoggedIn);

  useEffect(() => {
    if (!stateData?.data?.length) {
      const navigateTo = isLoggedIn
        ? shopPaths.auth.shoppingBag.fullPath
        : shopPaths.auth.shoppingBag.unAuthPath;
      navigate(navigateTo);
    }
  }, [stateData?.data, navigate, isLoggedIn]);

  const [state, dispatch] = useReducer(placeOrderReducer,
    {
      ...PLACE_ORDER_INITIAL_STATE,
      data: stateData?.data ?? [],
      total: stateData?.data.reduce((sum, cur) => sum + +cur[isLoggedIn ? "price" : "priceFront"], 0) ?? 0,
      isOrderShippable: stateData?.data.findIndex((currentItem) => currentItem.product.isShippable !== true) === -1,
    });

  useShopSnackbar(state.serverMessage, state.serverMessageType);
  const addressesData = useMyAddresses();
  const shopDispatch = useShopDispatch();

  const {
    reset,
    register,
    unregister,
    setValue,
    setError,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      [ORDER_KEY]: {
        [isWithoutShipping]: state.selectedTab === ORDER_TABS.PICKUP,
      }
    }
  });

  const billingAddressKeyName = useMemo(() => isLoggedIn ? BILLING_ADDRESS_ID_KEY : BILLING_ADDRESS_KEY,
    [isLoggedIn]);

  const shippingAddressKeyName = useMemo(() => isLoggedIn ? SHIPPING_ADDRESS_ID_KEY : SHIPPING_ADDRESS_KEY,
    [isLoggedIn]);

  watch(billingAddressKeyName);
  watch(shippingAddressKeyName);
  watch(SELECTED_SHIPPING_RATE_KEY);
  watch(IS_WITHOUT_SHIPPING_KEY);

  /** ========================== address changes handle start ============ */
  const handleSameAsShippingChange = useCallback((e, checked = false) => {
    const checkedNewVal = e?.currentTarget.checked ?? checked;
    dispatch({
      type: PLACE_ORDER_ACTIONS.CHANGE_DATA,
      payload: { sameAsShipping : checkedNewVal }
    });
  }, []);

  const handlePickupToShippingChange = useCallback((ev, newTabValue) => {
    dispatch({
      type: PLACE_ORDER_ACTIONS.CHANGE_DATA,
      payload: {
        selectedTab: newTabValue,
      }
    });
  }, []);

  const getAddressValue = useCallback((addressId) => {
    return isLoggedIn
      ? addressId
      : addressesData.myAddresses.find(({ id }) => id === addressId);
  }, [addressesData.myAddresses, isLoggedIn]);

  const handleShippingAddressChange = useCallback((_ev, addressId) => {
    setValue(shippingAddressKeyName, getAddressValue(+addressId));
  }, [getAddressValue, setValue, shippingAddressKeyName]);

  const handleBillingAddressChange = useCallback((_ev, addressId) => {
    setValue(billingAddressKeyName, getAddressValue(+addressId));
  }, [billingAddressKeyName, getAddressValue, setValue]);

  const currentShippingAddressValue = getValues(shippingAddressKeyName);
  const currentBillingAddressValue = getValues(billingAddressKeyName);
  useEffect(() => {
    if (state.sameAsShipping) {
      setValue(billingAddressKeyName, currentShippingAddressValue);
    }
  }, [billingAddressKeyName, currentShippingAddressValue, setValue, state.sameAsShipping]);

  useEffect(() => {
    let sameAsShipping;
    if (isLoggedIn) {
      sameAsShipping = currentShippingAddressValue === currentBillingAddressValue;
    } else {
      sameAsShipping = currentShippingAddressValue?.id === currentBillingAddressValue?.id;
    }

    handleSameAsShippingChange(null, sameAsShipping);
  }, [currentShippingAddressValue, currentBillingAddressValue, handleSameAsShippingChange]);
  /** ========================== address changes handle end ============ */

  const defaultOrFirstValidAddress = useMemo(() => {
    let addr = null;
    if (addressesData.myAddresses.length) {
      const myAddresses = addressesData.myAddresses;
      addr = myAddresses[0]?.isDefault && myAddresses[0];

      if (!addr) {
        addr = myAddresses.find(({ isValid }) => !!isValid);
      }

      if (isLoggedIn && addr) {
        addr = addr.id;
      }
    }

    return addr;
  }, [addressesData.myAddresses, isLoggedIn]);

  // const handleSameAsShippingChange = useCallback((e, checked = false) => {
  //   const checkedNewVal = e?.currentTarget.checked ??  checked;
  //   dispatch({
  //     type: PLACE_ORDER_ACTIONS.CHANGE_DATA,
  //     payload: { sameAsShipping : checkedNewVal }
  //   });
  //
  //     if (e) {
  //       let newVal;
  //       if (checkedNewVal) {
  //         newVal = getValues(shippingAddressKeyName);
  //       } else {
  //         newVal = defaultOrFirstValidAddress;
  //       }
  //
  //       setValue(billingAddressKeyName, newVal);
  //     }
  //
  // }, [billingAddressKeyName, defaultOrFirstValidAddress, getValues, setValue, shippingAddressKeyName]);

  useEffect(() => {
    if (!addressesData.requestLoading && addressesData.myAddresses.length && defaultOrFirstValidAddress) {
      setValue(billingAddressKeyName, defaultOrFirstValidAddress);
      setValue(shippingAddressKeyName, defaultOrFirstValidAddress);
    }
  }, [addressesData.myAddresses.length, addressesData.requestLoading, billingAddressKeyName, defaultOrFirstValidAddress, setValue, shippingAddressKeyName]);

  useEffect(() => {
    const isPickup = state.selectedTab === ORDER_TABS.PICKUP;
    setValue(IS_WITHOUT_SHIPPING_KEY, isPickup);

    register(billingAddressKeyName, { required: MESSAGES.BLL_ADD_RQR, shouldUnregister: true });
    if (!isPickup) {
      register(shippingAddressKeyName, { required: MESSAGES.SHP_ADD_RQR, shouldUnregister: true });
    }

    return () => {
      // unregister(billingAddressKeyName);
      // unregister(shippingAddressKeyName);
    };
  }, [state.selectedTab, setValue, billingAddressKeyName, register, shippingAddressKeyName, unregister]);

  const confirmFunc = useCallback(() => {
    let data = state.data;
    if (state.changedProducts.length) {
      state.changedProducts.forEach(changed => {
        const index = data.findIndex(item => item.id === changed.id);
        data[ index ] = { ...data[ index ], priceFront: changed.price, price: changed.price };
        updateShoppingBagItem({ data: {shoppingBag: data[index] }, isLoggedIn });
      });
    }
    if (state.removedProducts.length) {
      state.removedProducts.forEach(removed => {
        data = data.filter(item => item.id !== removed.id);
        deleteShoppingBagItem(removed.id, isLoggedIn)
      });
    }
    shopDispatch(ShopInfoAsyncActions.fetchShoppingBagItemsCount({ isLoggedIn }));

    if (!data.length) {
      const navigateTo = isLoggedIn
        ? shopPaths.auth.shoppingBag.fullPath
        : shopPaths.auth.shoppingBag.unAuthPath;
      navigate(navigateTo);
    }

    dispatch({
      type: PLACE_ORDER_ACTIONS.CLOSE_CONFIRM,
      payload: {
        serverMessage: "",
        serverMessageType: "",
        changedProducts: [],
        removedProducts: [],
        ratesChanged: [],
        data: data,
        total: data.reduce((sum, cur) => sum + +cur[ "priceFront" ], 0)
      }
    });

  }, [isLoggedIn, navigate, shopDispatch, state.removedProducts, state.changedProducts, state.data]);

  const isReseller = useShopSelector(customerSelectors.getIsReseller);

  const addedTaxes = useMemo(() => {
    if (isReseller) {
      return 0;
    }
    return +(state.total * TAX / 100).toFixed(2);
  }, [state.total, isReseller]);

  const handleCardDateChange = useCallback(({ target: { name, value } }) => {
    setValue(name, value);
    const [monthValue, yearValue] = getValues([expirationDateMonth, expirationDateYear]);
    setValue(EXPIRATION_DATE_KEY, `${monthValue}${yearValue}`);
  }, [getValues, setValue])

  const handleCreateOrder = useCallback((submitData) => {

    dispatch({ type: PLACE_ORDER_ACTIONS.FETCH_START });
    const createRequest = isLoggedIn ? createOrder : createGuestOrder;

    const clonedSubmitData = window.structuredClone(submitData);
    if (clonedSubmitData.order.isWithoutShipping) {
      const keys = shippingAddressKeyName.split(".");
      delete clonedSubmitData[keys[0]][keys[1]];
      delete clonedSubmitData[SELECTED_SHIPPING_RATE_KEY];
    }

    createRequest(clonedSubmitData)
      .then(async (data) => {
        if (!isLoggedIn) {
          const deleteIdsCalls = data.shoppingBagItems
            .filter(({ frontId }) => deleteShoppingBagItem(frontId, isLoggedIn));

          await Promise.allSettled(deleteIdsCalls);
        }

        return data;
      })
      .then(data => {
      shopDispatch(ShopInfoAsyncActions.fetchShoppingBagItemsCount({ isLoggedIn }));
      navigate(isLoggedIn
          ? `${shopPaths.auth.trackOrder.fullPath}/${data.id}`
          : `${shopPaths.auth.trackOrder.unAuthPath}/${data.id}`, { state: { newOrder: true } });

    }).catch(error => {
      const removedProducts = error.doesNotExist?.map(removed => {
        return state.data.find(or => or.id === removed.id);
      });
      const ratesChanged = error.ratesChanged ? [ ...error.ratesChanged] : []
      const priceChanged = error.priceChanged ? [...error.priceChanged] : []
      const showModal = !!removedProducts?.length || !!priceChanged.length || !!ratesChanged.length
      dispatch({
        type: PLACE_ORDER_ACTIONS.FETCH_ERROR,
        payload: {
          changedProducts: priceChanged,
          removedProducts: removedProducts || [],
          ratesChanged: ratesChanged,
          serverMessage: error.message || error.title || error,
          serverMessageType: "error",
          openConfirm: showModal
        }
      });

        if (error?.errors?.[CARD_CODE_ERROR_KEY]) {
          setError(CARD_CODE_KEY, {type: "server", message: error.errors[CARD_CODE_ERROR_KEY][0] })
        } else if (error?.errors?.[CARD_NUM_ERROR_KEY]) {
          setError(CARD_NUMBER_KEY, {type: "server", message: error.errors[CARD_NUM_ERROR_KEY][0] })
        }
      })
  }, [isLoggedIn, navigate, setError, shopDispatch, state.data, shippingAddressKeyName]);

  const handlePurchaseSubmit = useCallback(({ expirationDateMonth, expirationDateYear, ...data }) => {
    const orders = state.data.map(order =>{
      const {product, ...rest} = order
      return rest
    });

    const total = calculateTotalPrice(state.total, addedTaxes, data[SELECTED_SHIPPING_TOTAL_CHARGES_KEY]);

    delete data[SELECTED_SHIPPING_TOTAL_CHARGES_KEY];
    delete data[SHIPPING_RATE_SELECT_NAME_KEY];

    const submitData = {
      ...data,
      order: {
        ...data.order,
        ...isLoggedIn
          ? {shoppingBagIds: state.data.map(({id}) =>id)}
          : {shoppingBagItems: orders},
        total,
      }
    };
    submitData[CARD_DETAILS_KEY][cardNumber] = submitData[CARD_DETAILS_KEY][cardNumber].replace(/(\D)/g, "");

    handleCreateOrder(submitData);

  }, [addedTaxes, state.total, isLoggedIn, state.data, handleCreateOrder])

  const toggleDialog = useCallback((open = true) => {
    if (open) {
      dispatch({ type: PLACE_ORDER_ACTIONS.OPEN_CONFIRM })
    } else {
      dispatch({ type: PLACE_ORDER_ACTIONS.CLOSE_CONFIRM })
    }
  }, []);

  return {
    ...state,
    addedTaxes,
    addressesData,
    reset,
    errors,
    confirmFunc,
    toggleDialog,
    register,
    watch,
    setValue,
    getValues,
    handleSubmit,
    handleCardDateChange,
    handlePurchaseSubmit,
    handlePickupToShippingChange,
    isWithoutShipping: getValues(IS_WITHOUT_SHIPPING_KEY),
    handleSameAsShippingChange,
    billingAddressKeyName,
    shippingAddressKeyName,
    handleBillingAddressChange,
    handleShippingAddressChange,
  };
};

import { deleteShoppingBagItem, getShoppingBag }                                from "services/api/shoppingBag/ShoppingBagService";
import { useCallback, useEffect, useReducer }                                   from "react";
import { setErrorAlert, setSuccessAlert }                                       from "utils";
import useShopSnackbar                                                          from "../../../hooks/useShopSnackbar";
import { SHOPPING_BAG_ACTIONS, SHOPPING_BAG_INITIAL_STATE, shoppingBagReducer } from "./shoppingBagReducer";
import { MESSAGES }                                                             from "../messages";
import { MY_ADDRESSES_ACTIONS }                                                 from "../MyAddresses/myAddressesReducer";
import { useNavigate }                                                          from "react-router-dom";
import { shopPaths }                                                            from "routing/constants";
import { useSelector } from "react-redux";
import customerSelectors from "store/customer/customerSelectors";
import ShopInfoAsyncActions from "store/shopInfo/ShopInfoAsyncActions";
import { useShopDispatch } from "store/hooks";

const useShoppingBag = () => {
  const shopDispatch = useShopDispatch();
  const isLoggedIn = useSelector(customerSelectors.getIsLoggedIn);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(shoppingBagReducer, SHOPPING_BAG_INITIAL_STATE);
  useShopSnackbar(state.serverMessage, state.serverMessageType);

  const getTotal = (data = []) => {
    const total = data.reduce((prev, current) => {
      return prev + Number(current.price || current.priceFront);
    }, 0);
    return Math.round(total * 100) / 100;
  };

  const getData = useCallback(() => {
    dispatch({
      type: SHOPPING_BAG_ACTIONS.FETCH_START
    });

    getShoppingBag(isLoggedIn)
      .then(({ shoppingBag, title }) => {
      const error = title ? setErrorAlert(title) : {}

      dispatch({
          type: SHOPPING_BAG_ACTIONS.FETCH_END,
          payload: {
            data: shoppingBag,
            checkedItems: shoppingBag,
            totalPrice: getTotal(shoppingBag),
            ...error
          }
        });
      shopDispatch(ShopInfoAsyncActions.fetchShoppingBagItemsCount({ isLoggedIn }));
    });
  }, [isLoggedIn]); // eslint-disable-line

  useEffect(() => {
    getData();
  }, [getData]);

  const removeItem = useCallback(() => {
    dispatch({ type: SHOPPING_BAG_ACTIONS.START_CONFIRM });

    deleteShoppingBagItem(state.selectedItem.id, isLoggedIn)
      .then(({ success, title }) => {
        if (success) {
          const checkedData = state.checkedItems.filter(({ id }) => id !== state.selectedItem.id);
          dispatch({
            type: SHOPPING_BAG_ACTIONS.END_CONFIRM,
            payload: {
              data: state.data.filter(({ id }) => id !== state.selectedItem.id),
              checkedItems: checkedData,
              totalPrice: getTotal(checkedData),
              ...setSuccessAlert(MESSAGES.PRODUCT_DELETED)
            }
          });

          shopDispatch(ShopInfoAsyncActions.fetchShoppingBagItemsCount({ isLoggedIn }));

        } else {
          return Promise.reject(title);
        }
    })
      .catch((er) => {
        const msg = er?.message || er;
        dispatch({
          type: SHOPPING_BAG_ACTIONS.END_CONFIRM,
          payload: setErrorAlert(msg)
        });
        return Promise.reject(msg);
    });

    window.scrollTo(0, 0);
  }, [isLoggedIn, shopDispatch, state.checkedItems, state.data, state.selectedItem]);

  const toggleDialog = useCallback((open, selectedItem) => {
    dispatch({
      type: open ? MY_ADDRESSES_ACTIONS.OPEN_CONFIRM : MY_ADDRESSES_ACTIONS.CLOSE_CONFIRM,
      payload: {
        selectedItem: open ? selectedItem : null
      }
    });

  }, []);

  const onCheckout = useCallback((ev, oneItem) => {
    const navigateTo = isLoggedIn
      ? shopPaths.auth.placeOrder.fullPath
      : shopPaths.auth.placeOrder.unAuthPath;
    navigate(navigateTo, { state: { data: oneItem ? [oneItem] : state.checkedItems } });
  }, [navigate, state.checkedItems, isLoggedIn]);

  const toggleCheck = (id) => {
    let selected = [...state.checkedItems];
    const exists = selected.find(item => item.id === id);
    if (exists) {
      selected = selected.filter(item => item.id !== id);
    } else {
      selected.push(state.data.find(item => item.id === id));
    }
    dispatch({
      type: SHOPPING_BAG_ACTIONS.CHECK,
      payload: {
        checkedItems: selected,
        totalPrice: getTotal(selected),
        allChecked: selected.length === state.data.length
      }
    });
  };

  const toggleAllChecked = () => {
    if (state.allChecked) {
      dispatch({
        type: SHOPPING_BAG_ACTIONS.CHECK,
        payload: {
          checkedItems: [],
          totalPrice: 0,
          allChecked: false
        }
      });
    } else {
      dispatch({
        type: SHOPPING_BAG_ACTIONS.CHECK,
        payload: {
          checkedItems: state.data,
          totalPrice: getTotal(state.data),
          allChecked: true
        }
      });
    }
  };

  return { ...state, navigate, removeItem, toggleCheck, toggleDialog, toggleAllChecked, onCheckout };
};

export default useShoppingBag;
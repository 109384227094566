import TableHead                      from "@mui/material/TableHead";
import TableRow                       from "@mui/material/TableRow";
import TableCell                      from "@mui/material/TableCell";
import { headCells, mobileHeadCells } from "./constants";
import TableSortLabel                 from "@mui/material/TableSortLabel";
import Box                            from "@mui/material/Box";
import { visuallyHidden }             from "@mui/utils";
import * as React                     from "react";
import PropTypes                      from "prop-types";

export function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ display: { xs: "none", md: "table-row" } }}>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : headCells.length - 1 === index ? "checkbox" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ whiteSpace: "nowrap" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
      <TableRow sx={{ display: { xs: "table-row", md: "none" } }}>
        {mobileHeadCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={index === mobileHeadCells.length - 1 ? "right" : "left"}
            padding={"checkbox"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ whiteSpace: "nowrap" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

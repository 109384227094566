import { alpha, styled }              from "@mui/material/styles";
import InputBase                      from "@mui/material/InputBase";
import SearchIcon                     from "@mui/icons-material/Search";
import {
  Box,
  Paper,
  Typography,
  List, ListItem, ListItemButton,
  ImageListItem, ListItemText,
  CircularProgress,
}                                     from "@mui/material";
import { searchByProductNameRequest } from "../../../services/api/products/productsService";
import { useCallback, useRef, useState } from "react";
import { theme }                      from "../../theme/constants";
import { useNavigate }                from "react-router-dom";
import { productPathBuilder } from "../../../utils/productHelper";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey[ 300 ]}`,
  display: { xs: "none", md: "flex" },

  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: {xs:0, md:theme.spacing(2)},
  marginLeft: 0,
  width: "100%",
  [ theme.breakpoints.up("md") ]: {
    marginLeft: theme.spacing(11),
    width: "70%"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  }
}));

const SearchBar = () => {
  const [openSearch, setOpenSearch] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  let searchTimeout = useRef(0);

  const handleSearch = useCallback((e) => {
    const searchedValue = e.target.value;

    if (!searchedValue || !searchedValue.trim()) {
      clearTimeout(searchTimeout.current);
      setProducts([]);
      setOpenSearch(false);
      return;
    }

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      setOpenSearch(true);
      setIsSearchLoading(true);
      searchByProductNameRequest(searchedValue)
        .then(searchedProducts => {
          setProducts(searchedProducts);
        })
        .finally(() => setIsSearchLoading(false))
    }, 800);
  }, []);

  const menuEnter = () => {
    products.length && setOpenSearch(true);
  };
  const wholeMenuLeaveHAndler = () => {
    setOpenSearch(false);
  };
  const menuItemClickHandler = useCallback((e, name, id) => {
    e.stopPropagation();
    navigate(productPathBuilder(name, id));
    setOpenSearch(false);
  }, [navigate]);

  return (

      <Search onMouseLeave={wholeMenuLeaveHAndler}
              onMouseEnter={menuEnter}>
        <SearchIconWrapper>
          <SearchIcon/>
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          onChange={handleSearch}
          sx={{ width: "100%" }}
        />
        {
          openSearch &&
          <Paper elevation={6} sx={{
            position: "absolute",
            top: "42px",
            right: 0,
            zIndex: 999,
            marginLeft: theme.spacing(11),
            width: "100%"
          }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", p: 2 }}>
              <List>
                {isSearchLoading
                  ?
                  <CircularProgress sx={{ color: "primary.main" }} size="24px" />
                  :
                  !!products.length
                  ? products.map(product =>
                    <ListItem disablePadding key={product.id} onClick={(e) => menuItemClickHandler(e, product.name, product.id)}>
                      <ListItemButton>
                        <ImageListItem sx={{ width: {xs: "30px", md:"50px" }, mr: 2 }}>
                          <img src={product.mainImageURI} alt={product.name}/>
                        </ImageListItem>
                        <ListItemText color="primary.contrastText" >
                          <Typography variant="body2" component="span">
                            {product.name}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  )
                  : <Typography color="primary.contrastText" variant="body2" component="span">
                    No results Found
                  </Typography>
                }
              </List>
            </Box>
          </Paper>
        }
      </Search>
  )
}

export default SearchBar;

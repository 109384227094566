import { Box, Grid } from "@mui/material";
import { theme } from "components/theme/constants";
import LocationHeader from "pages/main/ContactUs/components/LocationInfo/LocationHeader";
import PropTypes from "prop-types";
import LocationMap from "./LocationMap";

const LocationInfo = ({ city, address, workingDays, workingHours }) => {
  return (
    <Grid container justifyContent="center" sx={{
      backgroundColor: theme.palette.primary.main04,
      p: {xs: 8, md: 12},
      px: {xs: 2,sm:3, md:10.5, lg:0},
    }}>
      <LocationHeader
        city={city}
        address={address}
        workingDays={workingDays}
        workingHours={workingHours}
      />
      <Grid item xs={12}>
        <Box
          sx={{
            mt: 7,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <LocationMap />
        </Box>
      </Grid>
    </Grid>
  );
};

LocationInfo.propTypes = {
  address: PropTypes.string,
  workingDays: PropTypes.string,
  workingHours: PropTypes.string,
};

export default LocationInfo;

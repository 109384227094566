import { useCallback, useEffect, useMemo, useState } from "react";
import customerSelectors from "../../../../../store/customer/customerSelectors";
import { useShopSelector } from "../../../../../store/hooks";

const MIN_ADDRESS_COUNT = 2;

export const useShowAddresses = (myAddresses, selectedOne, show = false) => {
  const isLoggedIn = useShopSelector(customerSelectors.getIsLoggedIn);

  const sortedAddresses = useMemo(() => {
    return [...myAddresses.sort(({ id }) => id === selectedOne ? -1 : 1)];
  }, [myAddresses, selectedOne]);

  const selectedAddress = useMemo(() => {
    return isLoggedIn
      ? sortedAddresses.find(({ id }) => id === selectedOne)
      : selectedOne;
  }, [isLoggedIn, selectedOne, sortedAddresses]);

  const [showAddresses, setShowAddresses] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    show && setShowAddresses(true);
  }, [show]);

  const handleShowMore = useCallback(() => setShowMore(!showMore), [showMore]);

  const shouldShowMore = useMemo(() => sortedAddresses.length > MIN_ADDRESS_COUNT, [sortedAddresses.length]);

  const showAddressesView = useCallback(() => {
    setShowAddresses(true)
  }, []);
  const hideAddressesView = useCallback(() => {
    setShowAddresses(false)
  }, []);

  const addresses = useMemo(() => {
    if (!showMore && shouldShowMore) {
      return [...sortedAddresses.slice(0, 2)];
    }
    return [...sortedAddresses];
  }, [showMore, sortedAddresses, shouldShowMore]);

  return {
    showAddresses,
    hideAddressesView,
    showAddressesView,
    showMore,
    setShowMore,
    handleShowMore,
    shouldShowMore,
    addresses,
    selectedAddress,
  }
};

import { useState }                              from "react";
import PropTypes                                 from "prop-types";
import { FormControl, InputLabel, NativeSelect } from "@mui/material";
import { SH_FIELD_NAMES }                        from "pages/main/Product/components/ProductForm/constants";

const OrderDigitalOptions = ({ sortedFields, register, handleOptionsChange, selectedOptions }) => {
  const [defaultValues, setDefaultValues] = useState({});
  return (
    sortedFields.map((item, index) => {
      const { id, productOptions, name } = item;
      return <FormControl key={id} sx={{ display: "flex", flexDirection: "column" }}>
        <InputLabel htmlFor={name}
                    sx={{ transform: "translateY(-9px) scale(0.75)" }}>
          { name}
        </InputLabel>
        <NativeSelect
          defaultValue={defaultValues[ id ]}
          fullWidth
          id={name}
          sx={{ my: 2 }}
          {...register(`${SH_FIELD_NAMES.selectedOptions}.${index}`, {
            valueAsNumber: true
          })}
          key={index}
          onChange={handleOptionsChange}
        >
          {productOptions?.map(({ id: optionId, label }) => {
            const defaultValue = selectedOptions?.includes(optionId) ? optionId : "";
            if (!defaultValues[ id ] && defaultValue) {
              setDefaultValues({
                ...defaultValues,
                [ id ]: defaultValue
              });
            }

            return (
              <option key={optionId} value={optionId}>{label}</option>
            );
          })}
        </NativeSelect>
      </FormControl>;
    })
  );
};

OrderDigitalOptions.propTypes = {
  sortedFields: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  register: PropTypes.func.isRequired,
  handleOptionsChange: PropTypes.func.isRequired
};

OrderDigitalOptions.defaultProps = {
  selectedOptions: []
};

export default OrderDigitalOptions;

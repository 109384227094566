import { useQuery }            from "@tanstack/react-query";
import { getProductRequest }   from "services/api/products/productsService";
import { getShoppingBagItemById } from "services/api/shoppingBag/ShoppingBagService";
import { useSelector } from "react-redux";
import customerSelectors from "store/customer/customerSelectors";
import { useNavigate }            from "react-router-dom";
import { shopPaths }              from "../../../routing/constants";
import { productNameConverter } from "../../../utils/productHelper";

const useProduct = (productName, id, shoppingBagItemId) => {
  const isLoggedIn = useSelector(customerSelectors.getIsLoggedIn);
  const navigate = useNavigate();
  const enabledProduct = !!id;
  const enabled = !!shoppingBagItemId;
  const { data: productData, isFetched } = useQuery(["productById", id], () => getProductRequest(id),
    { enabled: enabledProduct, initialData: {}, refetchOnWindowFocus: false });
  const { data: shoppingBagItemData, isFetching: shoppingBagItemFetched } = useQuery(["shoppingBagItemId", shoppingBagItemId],
    () => getShoppingBagItemById(shoppingBagItemId, isLoggedIn),
    { enabled, refetchOnWindowFocus: false });

  if (isFetched && productData &&
      (!Object.keys(productData).length || productNameConverter(productData.name) !== productName)
  ) {
    navigate(`${shopPaths.unAuth.home.path}`);
  }
  const isLoaded = isFetched && ((!shoppingBagItemFetched && enabled) || !enabled);
  return {
    ...productData,
    isLoaded,
    shoppingBagItemData
  };
};

export default useProduct;
import { shopPaths } from "routing/constants";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import customerSelectors from "store/customer/customerSelectors";

const useMainNavigation = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(customerSelectors.getIsLoggedIn);

  const goToShoppingBag = useCallback(() =>
      navigate(isLoggedIn ? shopPaths.auth.shoppingBag.fullPath :  shopPaths.auth.shoppingBag.unAuthPath),
    [navigate, isLoggedIn]);

  const goToContactsUs = useCallback(() =>
      navigate(shopPaths.unAuth.contactUs.path),
    [navigate]);

  const goToAccount = useCallback(() =>
      navigate(shopPaths.auth.myOrders.fullPath),
    [navigate]);

  const goToLogin = useCallback(() =>
      navigate(shopPaths.unAuth.login.path),
    [navigate])

  return {
    goToShoppingBag,
    goToContactsUs,
    goToAccount,
    goToLogin
  }
};

export default useMainNavigation;

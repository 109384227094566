const shopSaveLocally = store => next => action => {
  if (action.meta?.arg?.saveLocally && action.payload) {
    const { payload } = action
    for (const [key, value] of Object.entries(payload)) {
      if (value) {
        localStorage.setItem(key, JSON.stringify(value))
      }
    }
  }

  return next(action);
}

export default shopSaveLocally;

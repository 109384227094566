import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";

const ImageCard = ({ title, description = null, url, sx, altText }) => {

  return <Card sx={{boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.24)", ...sx} }>
    <CardActionArea>
      <CardMedia
        component="img"
        width="100%"
        image={url}
        alt={altText || title}
      />
      <CardContent>
        <Typography color="primary.contrastText" variant="h4" component="div">
          {title}
        </Typography>
        {!!description && <Typography color="grey.600" variant="body1" component="div" sx={{mt:3, whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis"}}>
          {description}
        </Typography>}
      </CardContent>
    </CardActionArea>
  </Card>;
};

export default ImageCard;

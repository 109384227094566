import { getAPI }                                           from "../NetService";
import { CATEGORIES_FULL_PATH, CATEGORIES_PATH} from "../categories/constants";

export const getCategoriesRequest = () => {
  return getAPI(CATEGORIES_PATH)
  .then(({ data: categories, success, title }) => {
    if (success) {
      return categories;
    }

    return Promise.reject(title)
  })
};

export const getCategoriesFullRequest = () => {
  return getAPI(CATEGORIES_FULL_PATH)
  .then(({ data: categoriesFull, success, title }) => {
    if (success) {
      return categoriesFull;
    }

    return Promise.reject(title)
  })
};

import { getAPI, postAPI } from "services/api/NetService";
import {
  CUSTOMER_EMAIL_CONFIRM_PATH,
  CUSTOMER_FORGOT_PASS_PATH,
  CUSTOMER_RES_PASS_PATH,
  CUSTOMER_RESEND_PASS_PATH,
  CUSTOMER_SIGN_IN_PATH,
  CUSTOMER_SIGN_UP_PATH
} from "services/api/auth/constants";

const TOKEN_KEY = "token";

const getSecurityToken = () => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(TOKEN_KEY);
};

export const customerSingUpRequest = data =>
  postAPI(CUSTOMER_SIGN_UP_PATH, data);

export const customerEmailConfirmRequest = () =>
  getAPI(CUSTOMER_EMAIL_CONFIRM_PATH, { token: getSecurityToken() });

export const customerSingInRequest = data =>
  postAPI(CUSTOMER_SIGN_IN_PATH, data);

export const customerForgotPassRequest = data =>
  postAPI(CUSTOMER_FORGOT_PASS_PATH, data);

export const customerEmailResendRequest = data =>
  postAPI(CUSTOMER_RESEND_PASS_PATH, data);

export const customerResetPassRequest = data =>
  postAPI(CUSTOMER_RES_PASS_PATH, data, { token: getSecurityToken() });
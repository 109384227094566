import { Box } from "@mui/material";
import { memo } from "react";
import { CARDS_MAP } from "pages/customer/ShoppingBag/PlaceOrder/constants";

const PaymentCardImages = memo(() => {
  return (
    <Box>
      {
        (Object.values(CARDS_MAP)).map(name =>
          <img key={name} width="50px" style={{ marginRight: "0.5rem"}} src={`/payment-cards/${name}.png`} alt={name} />
        )
      }
    </Box>
  );
});

export default PaymentCardImages;

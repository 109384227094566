import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { theme } from "components/theme/constants";
import { Box, CardMedia } from "@mui/material";
import { Autoplay, Navigation }                         from "swiper";
import { Swiper, SwiperSlide }                          from "swiper/react";


const ProductSlider = ({ images, mainImageURI, mainImageAltText }) => {
  const [mainImage, setMainImage] = useState(mainImageURI);
  const hasMultipleImages = images.length > 1;

  return (
    <Box sx={{ mb: { xs: 4 }, width: { md: "50%" }, position: { md: "sticky" }, height: "fit-content", top: 0 }}>
      <CardMedia
        sx={{ mb: 2, display: { md: "block", ...hasMultipleImages && { xs: "none" } }, borderRadius: "4px" }}
        component="img"
        width="100%"
        image={mainImage}
        alt={mainImageAltText || "Main image"}
      />
      {hasMultipleImages &&
        <Swiper
          style={{
            "--swiper-navigation-color": theme.palette.primary.main,
            "--swiper-navigation-size": "20px",
            padding: "0 30px"
          }}
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={35}
          allowTouchMove={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
          loop={true}
          navigation={true}
          modules={[Navigation, Autoplay]}
          breakpoints={{
            600: {
              slidesPerView: images.length < 2 ? images.length : 2
            },
            900: {
              slidesPerView: images.length < 3 ? images.length : 3
            }
          }}
        >
          {images.map(image => <SwiperSlide key={image.id}>
            <CardMedia
              sx={{ borderRadius: "4px", cursor: "pointer" }}
              component="img"
              width="100%"
              image={image.uri}
              alt={image.altText || image.name}
              onClick={() => setMainImage(image.uri)}
            />
            </SwiperSlide>
          )}
        </Swiper>
      }
    </Box>
  );
};

ProductSlider.propTypes = {
  images: PropTypes.array,
  mainImageURI: PropTypes.string,
};
ProductSlider.defaultProps = {
  images: [],
};
export default ProductSlider;
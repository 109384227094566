import { Box, Link, List, ListItem, Typography } from "@mui/material";
import { useShopSelector } from "store/hooks";
import shopInfoSelectors from "store/shopInfo/shopInfoSelectors";

const PrivacyPoilicy = () => {
  const { email } = useShopSelector(shopInfoSelectors.getCompanyInfo);

  return (
    <Box sx={{ px: { xs: 2, sm: 3, md: 10.5, lg: 0 }, mx: "auto", my: 12, maxWidth: "1152px" }}>
      <Typography variant="h1" sx={{ textAlign: "center" }} >
        Privacy Policy
      </Typography>
      <Typography variant="subtitle2" sx={{ textAlign: "center", mt: 2 }} color={"primary"}>
        Last updated at 29.09.2022
      </Typography>

      <Box sx={{ mt: 7 }}>
        <Typography variant="h2" color={"primary"}>
          1. WHAT INFORMATION DO WE COLLECT?
        </Typography>
        <Typography variant="h3" sx={{ my: 3 }}>
          The personal information you disclose to us
        </Typography>
        <Typography variant="body1">
          <b>In Short:</b> We collect personal information that you provide to us.
        </Typography>
        <Typography variant="body1" sx={{ my: 3 }}>
          We collect personal information that you voluntarily provide to us when you register on the Services, express
          an interest in obtaining information about us or our products and Services, when you participate in activities
          on the Services, or otherwise when you contact us.
        </Typography>
        <Typography variant="body1">
          <b>Sensitive Information.</b> <i>We do not process sensitive information.</i>
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          All the personal information that you provide to us must be true, complete, and accurate, and you must notify
          us of any changes to such personal information.
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: "bold", my: 3 }}>
          Information automatically collected
        </Typography>
        <Typography variant="body1">
          <b>In Short:</b> <i>Some information — such as your Internet Protocol (IP) address and/or browser and device
          characteristics —
          is collected automatically when you visit our Services.</i>
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          We automatically collect certain information when you visit, use, or navigate the Services. This information
          does not reveal your specific identity (like your name or contact information) but may include device and
          usage information, such as your IP address, browser, and device characteristics, operating system, language
          preferences, referring URLs, device name, country, location, information about how and when you use our
          Services, and other technical information. This information is primarily needed to maintain the security and
          operation of our Services, and for our internal analytics and reporting purposes.
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          Like many businesses, we also collect information through cookies and similar technologies.
        </Typography>
      </Box>

      <Box sx={{ mt: 7 }}>
        <Typography variant="h2" color={"primary"}>
          2. HOW DO WE PROCESS YOUR INFORMATION?
        </Typography>
        <Typography variant="body1" sx={{ my: 3 }}>
          <b>In Short:</b> <i>We process your information to provide, improve, and administer our Services, communicate
          with you, for security and fraud prevention, and comply with the law. We may also process your information for
          other purposes with your consent. </i>
        </Typography>
        <Typography variant="body1">
          <b>We process your personal information for a variety of reasons, depending on how you interact with our
            Services, including:</b>
        </Typography>
      </Box>

      <Box sx={{ mt: 7 }}>
        <Typography variant="h2" color={"primary"}>
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </Typography>
        <Typography variant="body1" sx={{ my: 3 }}>
          <b>In Short:</b> <i>We may share information in specific situations described in this section and/or with the
          following third parties.</i>
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          We may need to share your personal information in the following situations:
        </Typography>
        <List sx={{ listStyleType: "disc", pl: 4, ml: 2 }}>
          <ListItem sx={{ display: "list-item", p: 0 }}>
            <Typography variant="body1">
              <b>Business Transfers.</b> We may share or transfer your information in connection with, or during
              negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our
              business to another company.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0 }}>
            <Typography variant="body1">
              <b>Affiliates.</b> We may share your information with our affiliates, in which case we will require those
              affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint
              venture partners, or other companies that we control or that are under common control with us.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item", p: 0 }}>
            <Typography variant="body1">
              <b>Business Partners.</b> We may share your information with our business partners to offer you certain
              products, services, or promotions.
            </Typography>
          </ListItem>
        </List>
      </Box>

      <Box sx={{ mt: 7 }}>
        <Typography variant="h2" color={"primary"}>
          4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </Typography>
        <Typography variant="body1" sx={{ my: 3 }}>
          <b>In Short:</b> <i>We may use cookies and other tracking technologies to collect and store your
          information. </i>
        </Typography>
        <Typography variant="body1">
          We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
          information. Specific information about how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </Typography>
      </Box>

      <Box sx={{ mt: 7 }}>
        <Typography variant="h2" color={"primary"}>
          5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </Typography>
        <Typography variant="body1" sx={{ my: 3 }}>
          <b>In Short:</b> <i>If you choose to register or log in to our services using a social media account, we may
          have access to certain information about you.</i>
        </Typography>
        <Typography variant="body1">
          Our Services offer you the ability to register and log in using your third-party social media account details
          (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile information we receive may vary depending
          on the social media provider concerned, but will often include your name, email address, friends list, and
          profile picture, as well as other information you choose to make public on such a social media platform.
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          We will use the information we receive only for the purposes that are described in this privacy notice or that
          are otherwise made clear to you in the relevant Services. Please note that we do not control, and are not
          responsible for, other uses of your personal information by your third-party social media provider. We
          recommend that you review their privacy notice to understand how they collect, use, and share your personal
          information, and how you can set your privacy preferences on their sites and apps.
        </Typography>
      </Box>

      <Box sx={{ mt: 7 }}>
        <Typography variant="h2" color={"primary"}>
          6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
        </Typography>
        <Typography variant="body1" sx={{ my: 3 }}>
          <b>In Short:</b> <i>We may transfer, store, and process your information in countries other than your
          own. </i>
        </Typography>
        <Typography variant="body1">
          Our servers are located in. If you are accessing our Services from outside, please be aware that your
          information may be transferred to, stored, and processed by us in our facilities and by those third parties
          with whom we may share your personal information (see "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
          INFORMATION?" above), in and other countries.
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          If you are a resident in the European Economic Area (EEA) or the United Kingdom (UK), then these countries may
          not necessarily have data protection laws or other similar laws as comprehensive as those in your country.
          However, we will take all necessary measures to protect your personal information in accordance with this
          privacy notice and applicable law.
        </Typography>
      </Box>

      <Box sx={{ mt: 7 }}>
        <Typography variant="h2" color={"primary"}>
          7. HOW LONG DO WE KEEP YOUR INFORMATION?
        </Typography>
        <Typography variant="body1" sx={{ my: 3 }}>
          <b>In Short:</b> <i>We keep your information for as long as necessary to fulfill the purposes outlined in this
          privacy notice unless otherwise required by law.</i>
        </Typography>
        <Typography variant="body1">
          We will only keep your personal information for as long as it is necessary for the purposes set out in this
          privacy notice unless a longer retention period is required or permitted by law (such as tax, accounting, or
          other legal requirements).
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          When we have no ongoing legitimate business need to process your personal information, we will either delete
          or anonymize such information; or, if this is not possible (for example, because your personal information has
          been stored in backup archives), we will securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </Typography>
      </Box>

      <Box sx={{ mt: 7 }}>
        <Typography variant="h2" color={"primary"}>
          8. DO WE COLLECT INFORMATION FROM MINORS?
        </Typography>
        <Typography variant="body1" sx={{ my: 3 }}>
          <b>In Short:</b> <i>We do not knowingly collect data from or market to children under 18 years of age. </i>
        </Typography>
        <Typography variant="body1">
          {`We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you
          represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such
          minor dependent's use of the Services. If we learn that personal information from users less than 18 years of
          age has been collected, we will deactivate the account and take reasonable measures to promptly delete such
          data from our records. If you become aware of any data we may have collected from children under the age of
          18, please contact us at ${email}`}
        </Typography>
      </Box>

      <Box sx={{ mt: 7 }}>
        <Typography variant="h2" color={"primary"}>
          9. WHAT ARE YOUR PRIVACY RIGHTS?
        </Typography>
        <Typography variant="body1" sx={{ my: 3 }}>
          <b>In Short:</b> <i>You may review, change, or terminate your account at any time.</i>
        </Typography>
        <Typography variant="body1">
          If you are located in the EEA or UK and you believe we are unlawfully processing your personal information,
          you also have the right to complain to your local data protection supervisory authority. You can find their
          contact details
          <Link src={"https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"} sx={{ ml: 1 }}>
            here.
          </Link>
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          If you are located in Switzerland, the contact details for the data protection authorities are available
          <Link src={"https://www.edoeb.admin.ch/edoeb/en/home.html."} sx={{ ml: 1 }}>
            here.
          </Link>
        </Typography>
        <Typography variant="body1" sx={{ my: 3 }}>
          <b><u>Withdrawing your consent:</u></b> If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on the applicable law, you have the right
          to withdraw your consent at any time. You can withdraw your consent at any time by contacting us using the
          contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
        </Typography>
        <Typography variant="body1">
          However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,
          when applicable law allows, will it affect the processing of your personal information conducted in reliance
          on lawful processing grounds other than consent.
        </Typography>
        <Typography variant="body1" sx={{ my: 3 }}>
          <b>Account Information</b>
        </Typography>
        <Typography variant="body1">
          If you would at any time like to review or change the information in your account or terminate your account,
          you can:
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          Upon your request to terminate your account, we will deactivate or delete your account and information from
          our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot
          problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal
          requirements.
        </Typography>
      </Box>

      <Box sx={{ mt: 7 }}>
        <Typography variant="h2" color={"primary"}>
          11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </Typography>
        <Typography variant="body1" sx={{ my: 3 }}>
          <b>In Short:</b> <i>Yes, if you are a resident of California, you are granted specific rights regarding access
          to your personal information.</i>
        </Typography>
        <Typography variant="body1">
          California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are
          California residents to request and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third parties for direct marketing purposes and
          the names and addresses of all third parties with which we shared personal information in the immediately
          preceding calendar year. If you are a California resident and would like to make such a request, please submit
          your request in writing to us using the contact information provided below.
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          If you are under 18 years of age, reside in California, and have a registered account with Services, you have
          the right to request the removal of unwanted data that you publicly post on the Services. To request the
          removal of such data, please contact us using the contact information provided below and include the email
          address associated with your account and a statement that you reside in California. We will make sure the data
          is not publicly displayed on the Services, but please be aware that the data may not be completely or
          comprehensively removed from all our systems (e.g., backups, etc.).
        </Typography>
      </Box>

      <Box sx={{ mt: 7 }}>
        <Typography variant="h2" color={"primary"}>
          12. DO WE MAKE UPDATES TO THIS NOTICE?
        </Typography>
        <Typography variant="body1" sx={{ my: 3 }}>
          <b>In Short:</b> <i>Yes, we will update this notice as necessary to stay compliant with relevant laws.</i>
        </Typography>
        <Typography variant="body1">
          We may update this privacy notice from time to time. The updated version will be indicated by an updated
          "Revised" date, and the updated version will be effective as soon as it is accessible. If we make material
          changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by
          directly sending you a notification. We encourage you to review this privacy notice frequently to be informed
          of how we are protecting your information.
        </Typography>
      </Box>

      <Box sx={{ mt: 7 }}>
        <Typography variant="h2" color={"primary"}>
          13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </Typography>
        <Typography variant="body1" sx={{ mt: 3 }}>
          {`If you have questions or comments about this notice, you may email us at ${email}`}
        </Typography>
      </Box>

    </Box>
  );
};

export default PrivacyPoilicy;
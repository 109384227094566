import { Box, TextField, Typography } from "@mui/material";
import LoadingButton                  from "@mui/lab/LoadingButton";
import { theme }                      from "../../../../../components/theme/constants";
import { useForm }                    from "react-hook-form";
import { emailValidationRegex }       from "pages/constants";
import { postSubscribeRequest }       from "../../../../../services/api/subscribe/subscribeService";
import { useCallback, useState }      from "react";

const Subcribe = () => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [loading, setLoading] = useState(false);
  const handleSubscribe = useCallback((data) => {
    setLoading(true);
    postSubscribeRequest(data).then(() => {
      reset();                  // todo success or not MESSAGE
    });
    setLoading(false);
  }, [reset]);
  return <Box
    sx={{ textAlign: "center", py: 8, px: 3, borderRadius: 4, backgroundColor: theme.palette.primary.main12 }}>
    <Typography variant="h2" component="div" sx={{ pb: 3 }}>
      Interested in partnering?
    </Typography>
    <Typography variant="body1" component="div" sx={{ pb: 4 }}>
      Sign up to receive special offers and promotions
    </Typography>
    <Box sx={{ display: { md: "flex" }, justifyContent: { md: "center" }, alignItems: { md: "flex-start" } }}
         component="form"
         onSubmit={handleSubmit(handleSubscribe)}
         noValidate>
      <Box sx={{ maxWidth: "360px", m: { xs: "auto", md: 0 }, mr: { md: 2 } }}>
        <TextField
          margin="normal"
          inputProps={{ sx: { py: 1 } }}
          fullWidth
          sx={{ backgroundColor: "secondary.contrastText", m: 0 }}
          id="email"
          placeholder="Enter your email"
          name="email"
          {...register("email", {
            pattern: {
              value: emailValidationRegex,
              message: "Email should be valid"
            }
          })}
          {...errors.email &&
          {
            error: true,
            helperText: errors.email.message,
            sx: { backgroundColor: "transparent", mt: 0 },
            inputProps: { sx: { backgroundColor: "white", py: 1 } }
          }
          }
        />

      </Box>
      <LoadingButton
        loading={loading}
        type={"submit"}
        variant="contained"
        size={"large"}
        fullWidth
        sx={{ mt: { xs: 2, md: 0 }, maxWidth: { xs: "360px", md: "115px" } }}
      >
        Subscribe
      </LoadingButton>
    </Box>
    <Typography variant="body3" component="div" sx={{mt:3}}>
      We care about your privacy
    </Typography>
  </Box>;
};

export default Subcribe;

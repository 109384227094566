import { useCallback } from "react";
import { productPathBuilder } from "../../../utils/productHelper";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box, Typography, List, ListItem, Link
}                                      from '@mui/material';
import ExpandMoreIcon                  from '@mui/icons-material/ExpandMore';
import { sortProductsBySubCategories } from "utils";
import { useShopSelector } from "store/hooks";
import shopInfoSelectors from "store/shopInfo/shopInfoSelectors";
import MoreInfo from "components/main/ShopBar/MoreInfo";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props}/>
))(({ theme }) => ({
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //   transform: 'rotate(90deg)',
  // },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: 0,
  borderBottom: 0,
  boxShadow: "none",
  margin: 0,
  padding: 0
}));

const SubAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  borderTop: 0,
  borderBottom: 0,
  margin: 0,
}));

const MobileIAccordion = ({ hideMenu }) => {
  const navigate = useNavigate();
  const { categories, categoriesFull } = useShopSelector(shopInfoSelectors.getCategoriesWithFull);

  const onProductClick = useCallback((e, name, id) => {
    e.preventDefault();
    navigate(productPathBuilder(name, id));
  }, [navigate]);

  const categorySections = categories.map((category) => {
    const sorted = sortProductsBySubCategories(category.id, categoriesFull);
    const sortedSubCategories = Object.keys(sorted);

    return (
      <Accordion key={category.id} sx={{border:"none"}}>
        <MuiAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{category.name}</Typography>
        </MuiAccordionSummary>
        <AccordionDetails>
          {
            sortedSubCategories.map((subCategoryName) => {
              const currentItems = sorted[subCategoryName];

              return (
                <Accordion key={subCategoryName}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="sub-category-header"
                  >
                    <Typography>{subCategoryName}</Typography>
                  </AccordionSummary>
                  <SubAccordionDetails>
                    <List sx={{p: 0}}>
                      {currentItems.map((item) => {
                        return (
                          <ListItem key={item.id} onClick={hideMenu} sx={{px:1}}>
                            <Link
                              sx={{textTransform: "capitalize", display:"flex", flexWrap:"nowrap", alignItems:"center"}}
                              key={item.id}
                              color="inherit"
                              underline="none"
                              href={productPathBuilder(item.name, item.id)}
                              onClick={(e) => onProductClick(e, item.name, item.id)}
                            >
                              <Typography variant="body3" color={"primary.contrastText"}>
                                {item.name}
                              </Typography>
                              {item.isNew && <Typography fontWeight={500} color={"primary.main"} sx={{ ml: 1.5, fontSize: "12px" }}>
                                New
                              </Typography>}
                            </Link>
                          </ListItem>
                        );
                      })}
                    </List>
                  </SubAccordionDetails>
                </Accordion>
              );
            })
          }
        </AccordionDetails>
      </Accordion>
    )
  });

  return (
    <Box sx={{ width: 300 }}>
      <MoreInfo count={categories.length} />
      {categorySections}
    </Box>
  );
};

MobileIAccordion.propTypes = {
  hideMenu: PropTypes.func.isRequired,
};

export default MobileIAccordion;

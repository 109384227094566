import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCompanyInfoRequest, getHomeSliderImages } from "services/api/company/companyService";
import { getCategoriesFullRequest, getCategoriesRequest } from "services/api/categories/categoriesService";
import { getShoppingBagItemsCount } from "services/api/shoppingBag/ShoppingBagService";

const prefix = "shopInfo";

const SHOP_INFO_KEYS = [
  "companyInfo",
  "categories",
  "categoriesFull",
  "mainSliderImages",
  "shoppingBagItemsCount",
];

export const INITIALIZE_DATA_KEY = `${prefix}/shopInitializeData`;

const ShopInfoAsyncActions = {
  fetchShopInitializeData: createAsyncThunk(
    INITIALIZE_DATA_KEY,
    async ({ isLoggedIn }, { rejectWithValue }) => {
      const fetches = [
        getCompanyInfoRequest(),
        getCategoriesRequest(),
        getCategoriesFullRequest(),
        getHomeSliderImages(),
        getShoppingBagItemsCount(isLoggedIn),
      ];

      return Promise.allSettled(fetches)
        .then((allData) =>
          allData.reduce((dataObj, { value }, index) => {
            dataObj[SHOP_INFO_KEYS[index]] = value;
            return dataObj
          }, {})
        )
        .catch((errMsg) => rejectWithValue(errMsg))
    }
  ),
  fetchCompanyInfo: createAsyncThunk(
    `${prefix}/${SHOP_INFO_KEYS[0]}`,
    async (_, { rejectWithValue }) => {
      return getCompanyInfoRequest()
        .then((result) => ({ [SHOP_INFO_KEYS[0]]: result }))
        .catch((erMsg) => rejectWithValue(erMsg))
    }
  ),

  fetchCategories: createAsyncThunk(
    `${prefix}/${SHOP_INFO_KEYS[1]}`,
    async (_, { rejectWithValue }) => {
      return getCategoriesRequest()
        .then((result) => ({ [SHOP_INFO_KEYS[1]]: result }))
        .catch((errMsg) => rejectWithValue(errMsg))
    }
  ),

  fetchCategoriesFull: createAsyncThunk(
    `${prefix}/${SHOP_INFO_KEYS[2]}`,
    async (_, { rejectWithValue } ) => {
      return getCategoriesFullRequest()
        .then((result) => ({ [SHOP_INFO_KEYS[2]]: result }))
        .catch((errMsg) => rejectWithValue(errMsg))
    }
  ),

  fetchMainSliderImages: createAsyncThunk(
    `${prefix}/${SHOP_INFO_KEYS[3]}`,
    async (_, { rejectWithValue }) => {
      return getHomeSliderImages()
        .then((result) => ({ [SHOP_INFO_KEYS[3]]: result }))
        .catch((errMsg) => rejectWithValue(errMsg))
    }
  ),
  fetchShoppingBagItemsCount: createAsyncThunk(
    `${prefix}/${SHOP_INFO_KEYS[4]}`,
    async ({ isLoggedIn }, { rejectWithValue }) => {
      return getShoppingBagItemsCount(isLoggedIn)
        .then((result) => ({ [SHOP_INFO_KEYS[4]]: result }))
        .catch((errMsg) => rejectWithValue(errMsg))
    }
  )
};

export default ShopInfoAsyncActions;

import { postAPI }           from "../NetService";
import { GET_IN_TOUCH_PATH } from "./constants";

export const postGetInTouchRequest = (data) => {
  return postAPI(GET_IN_TOUCH_PATH, data)
  .then(({ data, success, title }) => {
    if (success) {
      return data
    }
    return Promise.reject(title)
  })
};
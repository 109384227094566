import { useQuery }            from "@tanstack/react-query";
import { getProductListRequest } from "services/api/products/productsService";

const useProducts = (id) => {
  const { data: { products, productSubCategories: subCategories, name }, isFetched } = useQuery(["prodListById", id], () => getProductListRequest(id),
    { initialData: {
      products: [],
      productSubCategories: [],
      }
    }
  );

  const data = subCategories?.map(item => {
      return {...item, products: products?.filter(prod => prod.productSubCategoryId === item.id) || []}}
  ) || []
const withoutSubCategory = products?.filter(prod => !prod.productSubCategoryId)

  !!withoutSubCategory.length &&  data.push({
    name,
    products: withoutSubCategory
  });

  return {
    isLoaded: isFetched,
    data: data.filter(c => !!c.products.length)
  };
}

export default useProducts;

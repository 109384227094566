import { Box, Link as LinkMui, Typography } from "@mui/material";
import LocalPhoneOutlinedIcon               from "@mui/icons-material/LocalPhoneOutlined";
import MailOutlineOutlined                  from "@mui/icons-material/MailOutlineOutlined";
import LocationOn            from "@mui/icons-material/LocationOn";
import CopyrightOutlinedIcon from "@mui/icons-material/CopyrightOutlined";
import FacebookIcon          from "@mui/icons-material/Facebook";
import InstagramIcon         from "@mui/icons-material/Instagram";
import LinkedInIcon          from "@mui/icons-material/LinkedIn";
import TwitterIcon           from "@mui/icons-material/Twitter";
import YouTubeIcon           from "@mui/icons-material/YouTube";
import { Link }              from "react-router-dom";
import { shopPaths }         from "routing/constants";
import { AcceptCookies }     from "components/main";
import customerSelectors from "store/customer/customerSelectors";
import { useShopSelector } from "store/hooks";
import shopInfoSelectors from "store/shopInfo/shopInfoSelectors";

const ShopFooter = () => {
  const categories = useShopSelector(shopInfoSelectors.getCategories);
  const {
    email,
    phone,
    address,
    workingDays,
    workingHours,
    youtube,
    facebook,
    linkedIn,
    twitter,
    instagram
  } = useShopSelector(shopInfoSelectors.getCompanyInfo);
  const isLoggedIn = useShopSelector(customerSelectors.getIsLoggedIn);

  return <Box>
    <AcceptCookies/>
    <Box sx={{ backgroundColor: "primary.contrastText", color: "secondary.contrastText" }}>
      <Box
        sx={{ mx: "auto", maxWidth: "1152px", display: { md: "flex" }, justifyContent: "space-between", py: { xs: 6 }, pt: { md: 8 }, pb: { xs: 3, md: 7 }, px: { xs: 2, sm: 3, md: 10.5, lg: 0 } }}>
        <Box sx={{ pb: { xs: 6 }, mr: { md: 11 }, width: { md: "25%" } }}>
          <Link to="/">
            <img src="/logo512.png" alt="logo" style={{ height: "44px", filter: "invert(100%)" }}/>
          </Link>
          <Typography variant={"body2"} sx={{ my: { xs: 4 } }}>
            Designing and printing unique experiences tailored for your business.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", pb: { xs: 3 }, mr: { md: 3 }, width: { md: "20%" } }}>
          <Typography variant={"h4"} sx={{ pb: { xs: 3 } }}>
            Printing
          </Typography>
          {categories.map(category =>
            <Link key={category.id} to={`/products/${category.id}`} style={{ textDecoration: "none" }}>
              <Typography variant="body3" sx={{ mb: 2, display: "flex" }} color={"secondary.contrastText"}>
                {category.name}
              </Typography>
            </Link>
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", pb: { xs: 3 }, mr: { md: 3 }, width: { md: "20%" } }}>
          <Typography variant={"h4"} sx={{ pb: { xs: 3 } }}>
            Useful Links
          </Typography>

          {isLoggedIn ? <>
            <Link to={shopPaths.auth.myAccountSettings.fullPath}
                  style={{ textDecoration: "none" }}
            >
              <Typography variant="body3" sx={{ mb: 2, display: "flex" }} color={"secondary.contrastText"}>
                Account Settings
              </Typography>
            </Link>
            <Link to={shopPaths.auth.myOrders.fullPath}
                  style={{ textDecoration: "none" }}
            >
              <Typography variant="body3" sx={{ mb: 2, display: "flex" }} color={"secondary.contrastText"}>
                My Orders
              </Typography>
            </Link>
            <Link to={shopPaths.auth.myAddresses.fullPath}
                  style={{ textDecoration: "none" }}
            >
              <Typography variant="body3" sx={{ mb: 2, display: "flex" }} color={"secondary.contrastText"}>
                My Addresses
              </Typography>
            </Link>
          </>: <></>}

          {/*<Link to={shopPaths.unAuth.completedProjects.path}*/}
          {/*      style={{ textDecoration: "none" }}*/}
          {/*>*/}
          {/*  <Typography variant="body3" sx={{ mb: 2, display: "flex" }} color={"secondary.contrastText"}>*/}
          {/*    Completed Projects*/}
          {/*  </Typography>*/}
          {/*</Link>*/}
          <Link to={shopPaths.unAuth.contactUs.path}
                style={{ textDecoration: "none" }}
          >
            <Typography variant="body3" sx={{ mb: 2, display: "flex" }} color={"secondary.contrastText"}>
              Contact Us
            </Typography>
          </Link>
          <Link to={shopPaths.unAuth.privacy.path}
                style={{ textDecoration: "none" }}
          >
            <Typography variant="body3" sx={{ mb: 2, display: "flex" }} color={"secondary.contrastText"}>
              Privacy Policy
            </Typography>
          </Link>
          <Link to={shopPaths.unAuth.terms.path}
                style={{ textDecoration: "none" }}
          >
            <Typography variant="body3" sx={{ mb: 2, display: "flex" }} color={"secondary.contrastText"}>
              Terms & Conditions
            </Typography>
          </Link>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", pb: { xs: 3 }, width: { md: "25%" } }}>
          <Typography variant={"h4"} sx={{ pb: { xs: 3 } }}>
            Contact us
          </Typography>
          <Typography variant="body3"
                      sx={{ mb: { xs: 2 }, alignItems: "center", display: "flex" }}>
            <LocalPhoneOutlinedIcon fontSize={"small"} sx={{ mr: 1 }}/>
            <LinkMui href={`tel:${phone}`} sx={{ textDecoration: "none", color: "secondary.contrastText" }}>
              {phone}
            </LinkMui>
          </Typography>
          <Typography variant={"body3"}
                      sx={{ mb: { xs: 2 }, alignItems: "center", display: "flex", wordBreak: "break-word" }}>
            <MailOutlineOutlined fontSize={"small"} sx={{ mr: 1 }}/>
            <LinkMui href={`mailto:${email}`} sx={{ textDecoration: "none", color: "secondary.contrastText" }}>
              {email}
            </LinkMui>
          </Typography>
          <Typography variant={"body3"} sx={{ mb: { xs: 2 }, alignItems: "center", display: "flex" }}>
            <LocationOn fontSize={"small"} sx={{ mr: 1 }}/>
            {address}
          </Typography>

          <Box
            sx={{ pt: { xs: 1.5 }, display: { md: "flex" }, justifyContent: "space-between", borderTop: "1px solid", borderTopColor: "secondary.contrastText23" }}>
            <Typography variant={"body3"} sx={{ mr: 3 }}>
              {workingDays}
            </Typography>
            <Typography variant={"body3"} textAlign={"right"}>
              {workingHours}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
    <Box
      sx={{
        mx: "auto", maxWidth: "1152px", display: { md: "flex" }, flexDirection: "row-reverse", justifyContent: "space-between",
        alignItems: "center", py: { xs: 3 }, px: { xs: 2, sm: 3, md: 10.5, lg: 0 }
      }}>
      <Box sx={{ color: "action.active", mb: { xs: 3, md: 0 } }}>
        {
          [
            { socialLink: facebook, Icon: FacebookIcon },
            { socialLink: instagram, Icon: InstagramIcon },
            { socialLink: linkedIn, Icon: LinkedInIcon },
            { socialLink: twitter, Icon: TwitterIcon },
            { socialLink: youtube, Icon: YouTubeIcon }
          ].map(({ socialLink, Icon }, index) =>
            socialLink &&
            <a key={index} style={{ marginRight: "24px" }} href={socialLink} target="_blank" rel="noreferrer">
              <Icon sx={{ color: "action.active" }}/>
            </a>
          )
        }
      </Box>
      <Typography variant={"body2"} sx={{ display: "flex", alignItems: "center", color: "grey.600" }}>
        <CopyrightOutlinedIcon sx={{ mr: 1 }}/>
        {new Date().getFullYear()} {SHOP_TITLE}. All rights reserved.
      </Typography>
    </Box>
  </Box>;

};

export default ShopFooter;


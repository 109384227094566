export const ORDER_KEY = "order";
export const GUEST_ORDER_KEY = "guestOrder";
export const EMAIL_ADDRESS_KEY = "emailAddress";
export const shoppingBagIds = "shoppingBagIds";
export const shoppingBagItems = "shoppingBagItems";
export const billingAddress = "billingAddress";
export const billingAddressId = "billingAddressId";
export const shippingAddress = "shippingAddress";
export const shippingAddressId = "shippingAddressId";
export const isWithoutShipping = "isWithoutShipping";
export const SHIPPING_RATE_SELECT_NAME_KEY = "shippingRate";
export const SELECTED_SHIPPING_RATE_KEY = "selectedShippingRates";
export const SELECTED_SHIPPING_TOTAL_CHARGES_KEY = "selectedShippingTotalCharges";
export const BILLING_ADDRESS_KEY = `${ORDER_KEY}.${billingAddress}`;
export const SHIPPING_ADDRESS_KEY = `${ORDER_KEY}.${shippingAddress}`;
export const BILLING_ADDRESS_ID_KEY = `${ORDER_KEY}.${billingAddressId}`;
export const SHIPPING_ADDRESS_ID_KEY = `${ORDER_KEY}.${shippingAddressId}`;
export const IS_WITHOUT_SHIPPING_KEY = `${ORDER_KEY}.${isWithoutShipping}`;

export const CARD_DETAILS_KEY = "cardDetails";
export const cardCode = "cardCode";
export const cardNumber = "cardNumber";
export const expirationDate = "expirationDate";
export const expirationDateMonth = "expirationDateMonth";
export const expirationDateYear = "expirationDateYear";
export const CARD_CODE_KEY = `${CARD_DETAILS_KEY}.${cardCode}`;
export const CARD_NUMBER_KEY = `${CARD_DETAILS_KEY}.${cardNumber}`;
export const EXPIRATION_DATE_KEY = `${CARD_DETAILS_KEY}.${expirationDate}`;

export const CARD_CODE_ERROR_KEY = "CardDetails.CardCode";
export const CARD_NUM_ERROR_KEY = "CardDetails.CardNumber";

export const MONTHS = {
  "Jan - 01": "01",
  "Feb - 02": "02",
  "Mar - 03": "03",
  "Apr - 04": "04",
  "May - 05": "05",
  "Jun - 06": "06",
  "Jul - 07": "07",
  "Aug - 08": "08",
  "Sep - 09": "09",
  "Oct - 10": "10",
  "Nov - 11": "11",
  "Dec - 12": "12",
};

/**
Visa cards begin with a 4 and have 13 or 16 digits
Mastercard cards begin with a 5 and has 16 digits
American Express cards begin with a 3, followed by a 4 or a 7 has 15 digits
Discover cards begin with a 6 and have 16 digits
*/
export const CARDS_MAP = {
  3: "american-express",
  4: "visa",
  5: "master",
  6: "discover",
};

export const YEARS = (function getYears() {
  const twoDigits = +new Date().getFullYear().toString().slice(-2);
  const years = [twoDigits];
  const cardYears = 10;

  for (let i = 1; i < cardYears; i++) {
    years.push(twoDigits + i)
  }

  return years;
})();

export const ORDER_TABS = {
  PICKUP: "PICKUP",
  SHIPPING: "SHIPPING",
};

export const ADDRESS_TYPES = {
  SHIPPING: "SHIPPING",
  BILLING: "BILLING"
};

export const CURRENCY_SYMBOL = "$";

export const SELECT_TIME = 300;

import { Typography, Box, Link } from "@mui/material";
import LocalPhoneOutlinedIcon    from "@mui/icons-material/LocalPhoneOutlined";
import MailOutlineOutlined       from "@mui/icons-material/MailOutlineOutlined";
import { theme }                 from "components/theme/constants";
import { useShopSelector } from "store/hooks";
import shopInfoSelectors from "store/shopInfo/shopInfoSelectors";

const TopBar = () => {
  const { email, phone } = useShopSelector(shopInfoSelectors.getCompanyInfo);

  return (
    <Box sx={{
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.contrastText,
      alignItems: "center",
      py: 1.2,
      display: { xs: "none", md: "flex" }
    }}>
      <Box sx={{ display: "flex", width: "100%", maxWidth: "1152px", mx: "auto", px: { xs: 2, sm: 3, md: 10.5 } }}>
        <Typography variant="body3"
                    sx={{ alignItems: "center", display: "flex", mr: 3 }}>
          <LocalPhoneOutlinedIcon fontSize={"small"} sx={{ mr: 1 }}/>
          <Link href={`tel:${phone}`} sx={{ textDecoration: "none", color:"secondary.contrastText" }}>
            {phone}
          </Link>
        </Typography>
        <Typography variant="body3" sx={{ alignItems: "center", display: "flex" }}>
          <MailOutlineOutlined fontSize={"small"} sx={{ mr: 1 }}/>
          <Link href={`mailto:${email}`} sx={{ textDecoration: "none", color:"secondary.contrastText"}}>
            {email}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default TopBar;

import { Box, TextField, Typography } from "@mui/material";
import LoadingButton                  from "@mui/lab/LoadingButton";
import { useForm }                    from "react-hook-form";
import { useCallback }                from "react";
import { postGetInTouchRequest }      from "services/api/getInTouch/getInTouchService";
import { EmailField, FullNameField } from "components/main/form";

const GetInTouch = () => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm();
  const handleTouch = useCallback((data) => {
    return postGetInTouchRequest(data).then(() => {
      reset();                                    // todo success or not MESSAGE
    });
  }, [reset]);

  return (
    <Box component="form"
         onSubmit={handleSubmit(handleTouch)}
         noValidate>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Get in touch
      </Typography>
      <Typography variant="body1" color={"grey.600"} sx={{ mb: { xs: 4 } }}>
        We’d love to hear from you. Please fill out this form.
      </Typography>
      <FullNameField
        register={register}
        errors={errors}
      />
      <EmailField
        register={register}
        errors={errors}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        multiline
        rows={4}
        id="subject"
        label="Subject"
        name="subject"
        {...register("subject", {
          required: "Subject is required.",
          validate: (val) => val && val.trim().length >= 20
        })}
        {...errors.subject &&
        {
          error: true,
          helperText: errors.subject.message
        }}
        {...errors.subject && errors.subject.type === "validate" &&
        {
          error: true,
          helperText: "Minimum 20 characters required"
        }}
      />
      <LoadingButton
        loading={isSubmitting}
        type={"submit"}
        fullWidth
        size={"large"}
        variant="contained"
        sx={{ mt: 4 }}
      >
        Send message
      </LoadingButton>
    </Box>
  );
};

export default GetInTouch;

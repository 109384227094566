import { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { Paper, Box, Radio, Typography, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { theme } from "components/theme/constants";
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import { MESSAGES } from "../messages";

const MyAddressesCard = memo(({
                                id, address1, address2, city, state, zipCode, openAddressDialog, openDeleteDialog, sx,
                                noAction, isValid, openDefaultDialog, isDefault,
                                radioName, selectedItem,
                              }) => {
  const checked = useMemo(() => selectedItem === id, [selectedItem, id]);

  const activeStyle = useMemo(() => ({
      ...sx,
      ...checked && {
        border: `2px solid ${theme.palette.primary.main}`,
      },
      ...!isValid && {
        border: `2px solid ${theme.palette.error.main}`,
      },

    }),
    [checked, sx, isValid]);

  const key = useMemo(() => `${radioName}-${id}`, [radioName, id]);

  return (
    <Paper
      elevation={6}
      key={key}
      sx={{
        overflow: "hidden",
        position: "relative",
        width: { xs: "100%", sm: "44%", md: "45%" },
        my: 1.5, p: 1.5,
        ...isValid && {
          cursor: "pointer",
        },
        ...activeStyle,
      }}
      {...radioName && {
        component: "label",
        htmlFor: key,
      }}
    >
      {radioName &&
        <Radio
          id={key}
          value={id}
          checked={checked}
          name={radioName}
          sx={{ display: "none" }}
          disabled={!isValid}
        />
      }

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="body3"
                    sx={{ width: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
          {`${address1}${address2 ? ` ${address2},` : ","} ${city}, ${state} ${zipCode}`}
        </Typography>
        {!noAction &&
          <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", width: "90px" }}>
            <Tooltip title={MESSAGES.SET_DEFAULT_ADDRESS} placement="top">
                <span>
                  <IconButton size={"small"} disabled={!isValid} sx={{ color: isDefault && theme.palette.success.main }}
                              aria-label={MESSAGES.SET_DEFAULT_ADDRESS} onClick={openDefaultDialog}>
                    <WhereToVoteIcon fontSize={"small"}/>
                  </IconButton>
                </span>
            </Tooltip>
            <Tooltip title={MESSAGES.EDIT_ADDRESS} placement="top">
              <IconButton size={"small"} aria-label={MESSAGES.EDIT_ADDRESS} onClick={openAddressDialog}>
                <EditIcon fontSize={"small"}/>
              </IconButton>
            </Tooltip>
            <Tooltip size={"small"} title={MESSAGES.DELETE_ADDRESS} placement="top">
              <IconButton aria-label={MESSAGES.DELETE_ADDRESS} onClick={openDeleteDialog}>
                <DeleteIcon fontSize={"small"}/>
              </IconButton>
            </Tooltip>
          </Box>
        }
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        {!isValid && <Typography variant="body3" color="error">{MESSAGES.NOT_VALID}</Typography>}
      </Box>
    </Paper>
  );
});

MyAddressesCard.propTypes = {
  id: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  isDefault: PropTypes.bool.isRequired,

  selectedItem: PropTypes.number,
  radioName: PropTypes.string,
  errors: PropTypes.object,

  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zipCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  openAddressDialog: PropTypes.func,
  openDeleteDialog: PropTypes.func,
  openDefaultDialog: PropTypes.func,
  noAction: PropTypes.bool.isRequired,
};

MyAddressesCard.defaultProps = {
  noAction: false,
  isDefault: false,
  radioName: "",
  errors: {},
};

export default MyAddressesCard;

import { useState }                              from "react";
import PropTypes                                 from "prop-types";
import { FormControl, InputLabel, NativeSelect } from "@mui/material";
import { SH_FIELD_NAMES }                        from "pages/main/Product/components/ProductForm/constants";
import { optionTypes }                           from "../useProductForm";

const OrderOtherOptions = ({ sortedFields, register, handleOptionsChange, selectedOptions, getNumber, getSelectedValues }) => {
  const [defaultValues, setDefaultValues] = useState({});
  const selectedValues = sortedFields ? getSelectedValues() : {};

  return (
    sortedFields.map((item, index) => {
      const { id, productOptions, name } = item;

      if (name === optionTypes.RaisedSpotUv && getNumber(selectedValues[ optionTypes.PrintSides ]?.label) === 1 && getNumber(selectedValues[ optionTypes.RaisedSpotUv ]?.label) === 2) {
        const UVOptions = sortedFields.find(i => i.name === optionTypes.RaisedSpotUv).productOptions;
        handleOptionsChange({
          target: {
            name: `${SH_FIELD_NAMES.selectedOptions}.${index}`,
            value: UVOptions.filter(o => getNumber(o.label) !== 2)[ 0 ].id
          }
        });
      }

      return <FormControl key={id} sx={{ display: "flex", flexDirection: "column" }}>
        <InputLabel htmlFor={name}
                    sx={{ transform: "translateY(-9px) scale(0.75)" }}>
          {name}
        </InputLabel>
        <NativeSelect
          defaultValue={defaultValues[ id ]}
          fullWidth
          id={name}
          sx={{ my: 2 }}
          {...register(`${SH_FIELD_NAMES.selectedOptions}.${index}`, {
            valueAsNumber: true
          })}
          key={index}
          onChange={handleOptionsChange}
        >
          {productOptions?.map(({ id: optionId, label }) => {
            const defaultValue = selectedOptions?.includes(optionId) ? optionId : "";
            if (!defaultValues[ id ] && defaultValue) {
              setDefaultValues({
                ...defaultValues,
                [ id ]: defaultValue
              });
            }

            if (getNumber(getSelectedValues()[ optionTypes.PrintSides ]?.label) === 1 && name === optionTypes.RaisedSpotUv && getNumber(label) === 2) {
              return;
            }
            return (
              <option key={optionId} value={optionId}>{label}</option>
            );
          })}
        </NativeSelect>
      </FormControl>;
    })
  );
};

OrderOtherOptions.propTypes = {
  sortedFields: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  register: PropTypes.func.isRequired,
  handleOptionsChange: PropTypes.func.isRequired
};

OrderOtherOptions.defaultProps = {
  selectedOptions: []
};

export default OrderOtherOptions;

import { Avatar, Paper, Typography } from "@mui/material";
import { theme }                     from "../../../../../components/theme/constants";

const WorkCard = ({ title, info, Icon, sx }) => {
  return <Paper variant="outlined"
                sx={{
                  mb:3,
                  p: 3,
                  width: "100%",
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                  boxSizing: "border-box",
                  borderColor: "primary.main",
                  ...sx,
                }}
  >
    <Avatar
      sx={{
        backgroundColor: theme.palette.primary.main20,
        width: "56px",
        height: "56px",
        mb: 3
      }}
    >
      <Icon
        fontSize="large"
        color="primary"
      />
    </Avatar>
    <Typography variant="h4" gutterBottom>
      {title}
    </Typography>
    <Typography variant="body2">
      {info}
    </Typography>
  </Paper>;
};

export default WorkCard;
import { Grid, Typography } from "@mui/material";
import { Cards, GetInTouch, LocationInfo } from "pages/main/ContactUs/components";
import { useShopSelector } from "store/hooks";
import shopInfoSelectors from "store/shopInfo/shopInfoSelectors";

const ContactUs = () => {
  const { email, phone, city, address, workingDays, workingHours } = useShopSelector(shopInfoSelectors.getCompanyInfo);

  return (
    <Grid container justifyContent="center" sx={{py:{xs:8}, maxWidth:"1152px", m:"auto"}}>
      <Grid item xs={12} sx={{px:{xs:2, sm:3, md:10.5, lg:0} }}>
        <Typography variant="h1" sx={{mb:{xs:2}}}>
          We’d love to hear from you
        </Typography>
        <Typography variant="body1" color={"grey.600"}>
          Our friendly team is always here to chat.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ px: {xs:2,sm:3,md:10.5, lg:0}, maxWidth:"1152px",
        m:"auto" }}>
        <Cards
          email={email}
          phone={phone}
          address={address}
        />
      </Grid>
      <Grid item xs={12} sx={{ mx: "auto", backgroundColor:"primary.main4"}}>
        <LocationInfo
          city={city}
          address={address}
          workingDays={workingDays}
          workingHours={workingHours}
        />
      </Grid>
      <Grid item md={5} textAlign="center" sx={{px:{xs:2, sm:3, md:10.5, lg:0},maxWidth:"1152px",
        m:"auto", mt:{xs:8}}}>
        <GetInTouch />
      </Grid>
    </Grid>
  )
}

export default ContactUs;

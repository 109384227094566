import { getAPI, postAPI, postFilesAPI } from "services/api/NetService";
import {
  CARD_DETAILS_KEY,
  EMAIL_ADDRESS_KEY,
  GUEST_ORDER_KEY,
  ORDER_KEY,
  SELECTED_SHIPPING_RATE_KEY,
} from "../../../pages/customer/ShoppingBag/PlaceOrder/constants";
import { ORDER_TRACK_PATH, ORDERS_GUEST_PATH, ORDERS_PATH, STATUSES_PATH } from "./constants";
import { updateCustomer } from "services/api/serviceHelpers";

export const getOrders = () => {
  return getAPI(ORDERS_PATH).then(({ data: orders, success, title }) => {
    if (success) {
      return orders;
    }
    return Promise.reject(title);
  });
};

export const createOrder = (data) => {
  return postAPI(ORDERS_PATH, data)
    .then(({ data: order, success, title, errors }) => {
      if (success) {
        return order;
      }

      updateCustomer(false);

      return Promise.reject({ title, errors, ...order });
    });
};

const createFormData = (data) => {
  const formData = new FormData();
  /** append customer uploaded files*/
  for (let shoppingBagItem of data.order.shoppingBagItems) {
    if (shoppingBagItem.file) {
      for (let file of shoppingBagItem.file) {
        formData.append(`file-${shoppingBagItem.id}`, file);
      }
    }
    delete shoppingBagItem.file;
  }
  formData.append(GUEST_ORDER_KEY, JSON.stringify(data[ORDER_KEY]));

  formData.append(EMAIL_ADDRESS_KEY, data[EMAIL_ADDRESS_KEY]);

  formData.append(CARD_DETAILS_KEY, JSON.stringify(data[CARD_DETAILS_KEY]));

  formData.append(SELECTED_SHIPPING_RATE_KEY, JSON.stringify(data[SELECTED_SHIPPING_RATE_KEY]));

  return formData;
};

export const createGuestOrder = (data) => {
  const formData = createFormData(data);
  return postFilesAPI(ORDERS_GUEST_PATH, formData)
    .then(({ data: order, success, title, errors }) => {
      if (success) {
        return order;
      }

      return Promise.reject({ title, errors, ...order });
    });
};

export const getStatusesRequest = () => {
  return getAPI(STATUSES_PATH).then(({ success, data, title }) => {
    if (success) {
      return data;
    }
    return Promise.reject(title);
  });
};

export const trackOrderRequest = (orderId) => {
  return getAPI(ORDER_TRACK_PATH(orderId))
    .then(({ success, data, title }) => {
      if (success) {
        return data;
      }

      return Promise.reject(title);
    });
};

import React from "react";
import PropTypes from "prop-types";
import { Box, List, ListItem, ListItemText,Typography } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { theme } from "components/theme/constants";

const ProductTemplates = ({ templates }) => {
  return (
    <Box sx={{ maxWidth: "600px", mx: "auto" }}>
      {templates.length
        ?
        <List>
          {templates.map((template) => {
            return (
              <a
                key={template.id}
                // rel="noopener noreferrer"
                // download={template.name}
                target="_blank"
                href={template.uri}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItem
                  sx={{
                    border: "1px solid",
                    borderColor: theme.palette.primary.main,
                    borderRadius: 1,
                    marginBottom: 1,
                    cursor: "pointer"
                  }}
                  secondaryAction={<FileDownloadOutlinedIcon edge="end"/>}
                  fullWidth
                  variant="outlined"
                >
                  <ListItemText primary={template.name}/>
                </ListItem>
              </a>
            );
          })}
        </List>
        :
        <Typography variant={"p1"} sx={{ mb: { xs: 2, md: 3 } }}>
          There are not any attached templates to this product
        </Typography>
      }
    </Box>
  );
};

ProductTemplates.propTypes = {
  templates: PropTypes.array.isRequired,
};

export default ProductTemplates;

export const FETCH_INITIAL_STATE = {
  serverMessageType: "",
  serverMessage: "",
  shouldRedirectOnFail: false,
};

export const FETCH_ACTIONS = {
  FETCH_START: "FETCH_START",
  FETCH_END: "FETCH_END",
  FETCH_ERROR: "FETCH_ERROR",
};

export const fetchReducer = (state, { type, payload }) => {
  switch (type) {
    case FETCH_ACTIONS.FETCH_START:
      return { ...state, serverMessage: "", serverMessageType: "", shouldRedirectOnFail: false };
    case FETCH_ACTIONS.FETCH_END:
      return { ...state, ...payload };
    case FETCH_ACTIONS.FETCH_ERROR:
      return { ...state, ...payload };
    default:
      throw new Error(`no action type ${type}`);
  }
};

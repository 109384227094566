import { useCallback } from "react";
import PropTypes from "prop-types";
import { FormControl, RadioGroup, Radio, Button, Typography, Box, FormControlLabel, Switch } from "@mui/material";
import { SH_FIELD_NAMES, SHOPPING_BAG_KEY } from "pages/main/Product/components/ProductForm/constants";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import messages from "pages/main/Product/components/ProductForm/messages";

const OrderArtWork = ({ register, setValue, trigger, getValues, setPurchasePrice }) => {
  const { isArtwork, isProofing } = SH_FIELD_NAMES;
  const {[SHOPPING_BAG_KEY]: { isArtwork: isArtworkVal, isProofing: isProofingVal }} = getValues();

  const onChange = useCallback((ev) => {
    const newVal = ev.target.value !== "false"
    setValue(isArtwork, newVal);
    setValue(isProofing, newVal);
    setPurchasePrice()
    trigger([`${SH_FIELD_NAMES.file}`, `${SH_FIELD_NAMES.customerUploads}`])
  }, [setValue, trigger, isProofing, isArtwork, setPurchasePrice]);

  const onProofChange = useCallback((ev) => {
    !isArtworkVal && setValue(isProofing, ev.target.checked);
  }, [isProofing, setValue, isArtworkVal]);

  return (
    <FormControl sx={{ width: "100%", mt: 1 }}>
      <RadioGroup
        name={isArtwork}
        id={isArtwork}
        onChange={onChange}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
      >
        <Button
          variant={isArtworkVal ? "outlined" : "contained"}
          component="label"
          endIcon={<FileUploadOutlinedIcon />}
          sx={{ flexBasis: "49%"}}
        >
          <Radio
            value={false}
            name={isArtwork}
            sx={{ display: "none" }}
            {...register(isArtwork)}
          />
          {messages.noArt}
        </Button>

        <Button
          variant={isArtworkVal ? "contained" : "outlined"}
          component="label"
          endIcon={<DesignServicesOutlinedIcon />}
          sx={{ flexBasis: "49%"}}
        >
          <Radio
            value={true}
            name={isArtwork}
            sx={{ display: "none" }}
            {...register(isArtwork)}
          />
          {messages.withArt}
        </Button>
      </RadioGroup>

      <Box>
        <FormControlLabel
          control={
            <Switch
              name={isProofing}
              {...register(isProofing)}
              checked={isProofingVal}
              onChange={onProofChange}
            />
          }
          label={
            <Typography variant="body2" color="text.secondary">
              {messages.proof}
            </Typography>
          }
        >
        </FormControlLabel>
      </Box>
    </FormControl>
  );
};

OrderArtWork.propTypes = {
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
};

export default OrderArtWork;

import useProject                     from "./useProject";
import { useParams }                  from "react-router-dom";
import { Box, CardMedia, Typography } from "@mui/material";

const Project = () => {
  const { id } = useParams();
  const { clientName, images, mainImageUri } = useProject(id);
  return <>
    <Box sx={{ maxWidth: "1152px", m: "auto", mb: { xs: 4, md: 6 }, px: { xs: 2, sm: 3, md: 10.5 } }}>
      <Box sx={{display:"flex", flexDirection:{xs:"column", sm:"row"}, justifyContent:"space-between", alignItems: "center", mt: 6 }}>
        <Box sx={{width:{xs:"100%",sm:"70%" }}}>
          <Typography gutterBottom variant="subtitle2" component="div" color={"secondary.main"} >
            Made for company
          </Typography>
          <Typography variant="h1" component="div" sx={{ mt: 2 }}>
            {clientName}
          </Typography>
          <Typography variant="body1" component="div" color={"grey.600"} sx={{ mt: 3, mb: 4 }}>
            {clientName}
          </Typography>
        </Box>
        <Box sx={{width: {xs:"50%",sm:"30%" } }}>
          <CardMedia
            component="img"
            width="100%"
            image={mainImageUri}
            alt={"logo"}
          />
        </Box>

      </Box>

    </Box>
    <Box sx={{ backgroundColor: "grey.50" }}>
      <Box sx={{ maxWidth: "1152px", m: "auto", py: { xs: 3, sm: 6, md: 12 }, px: { xs: 2, sm: 3, md: 10.5 } }}>
        {images?.map((i, k) => {
          return <CardMedia
            component="img"
            width="100%"
            image={i.uri}
            alt={i.name}
            key={k}
          />;
        })}
      </Box>
    </Box>
  </>;
};
export default Project;
export const SHOPPING_BAG_INITIAL_STATE = {
  serverMessageType: "",
  serverMessage: "",
  loadingConfirm: false,
  totalPrice: 0,
  selectedItem: null,
  openConfirm: false,
  data: [],
  dataLoading: true,
  checkedItems:[],
  allChecked:true
};

export const SHOPPING_BAG_ACTIONS = {
  FETCH_START: "FETCH_START",
  FETCH_END: "FETCH_END",
  FETCH_ERROR: "FETCH_ERROR",
  OPEN_CONFIRM: "OPEN_CONFIRM",
  CLOSE_CONFIRM: "CLOSE_CONFIRM",
  START_CONFIRM: "START_CONFIRM",
  END_CONFIRM: "END_CONFIRM",
  CHECK: "CHECK"
};

export const shoppingBagReducer = (state, { type, payload }) => {
  switch (type) {
    case SHOPPING_BAG_ACTIONS.FETCH_START:
      return { ...state, dataLoading: true, serverMessage: "", serverMessageType: "" };
    case SHOPPING_BAG_ACTIONS.FETCH_END:
    case SHOPPING_BAG_ACTIONS.FETCH_ERROR:
      return { ...state, ...payload, dataLoading: false };
    case SHOPPING_BAG_ACTIONS.CHECK:
      return { ...state, ...payload };
    case SHOPPING_BAG_ACTIONS.OPEN_CONFIRM:
      return { ...state, ...payload, openConfirm: true };
    case SHOPPING_BAG_ACTIONS.CLOSE_CONFIRM:
      return { ...state, ...payload, openConfirm: false };
    case SHOPPING_BAG_ACTIONS.START_CONFIRM:
      return { ...state, ...payload, loadingConfirm: true, serverMessage: "", serverMessageType: "" };
    case SHOPPING_BAG_ACTIONS.END_CONFIRM:
      return { ...state, ...payload, loadingConfirm: false, openConfirm: false, selectedItem: null };
    default:
      throw new Error(`no action type ${type}`);
  }
};

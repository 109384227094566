import { useCallback, useEffect, useReducer } from "react";
import {
  MY_ADDRESSES_ACTIONS,
  MY_ADDRESSES_INITIAL_STATE,
  myAddressesReducer
}                                             from "pages/customer/MyAddresses/myAddressesReducer";
import {
  deleteAddressRequest,
  getAllMyAddressesRequest,
  getUSStatesRequest,
  updateAddressRequest,
} from "services/api/addresses/myAddressesService";
import { setErrorAlert, setSuccessAlert }     from "utils";
import { MESSAGES }                           from "pages/customer/messages";
import useShopSnackbar                        from "hooks/useShopSnackbar";
import { snackBarVariants } from "pages/constants";
import { useShopSelector } from "store/hooks";
import customerSelectors from "store/customer/customerSelectors";
import { ADDRESS_ACTIONS } from "./constants";

export const useMyAddresses = () => {
  const isLoggedIn = useShopSelector(customerSelectors.getIsLoggedIn);
  const [state, dispatch] = useReducer(myAddressesReducer, MY_ADDRESSES_INITIAL_STATE);
  useShopSnackbar(state.serverMessage, state.serverMessageType);

  const sortMyAddresses = useCallback((myAddresses) => {
    return myAddresses.sort(({ isDefault, isValid }, { isDefault: nextIsDefault, isValid: nextIsValid }) =>
      isDefault < nextIsDefault ? 1 : isValid < nextIsValid ? 1 : -1);
  }, []);

  const getAddresses = useCallback(() => {
    dispatch({ type: MY_ADDRESSES_ACTIONS.FETCH_START });
    getAllMyAddressesRequest(isLoggedIn)
      .then(({ data }) => {
        dispatch({
          type: MY_ADDRESSES_ACTIONS.FETCH_END,
          payload: { myAddresses: sortMyAddresses(data) }
        });
      })
      .catch((err) => {
        dispatch({
          type: MY_ADDRESSES_ACTIONS.FETCH_ERROR,
          payload: {
            serverMessageType: snackBarVariants.error,
            serverMessage: err.message
          }
        });
      });
  }, [isLoggedIn, sortMyAddresses]);

  const getStates = useCallback(() => {
    getUSStatesRequest()
      .then(({ data: states }) => {
        dispatch({
          type: MY_ADDRESSES_ACTIONS.UPDATE_STATES,
          payload: { states }
        });
      })
      .catch((err) => {
        dispatch({
          type: MY_ADDRESSES_ACTIONS.FETCH_ERROR,
          payload: {
            serverMessageType: snackBarVariants.error,
            serverMessage: err.message
          }
        });
      });
  }, []);

  useEffect(() => {
    getAddresses();
    getStates();
  }, [getAddresses, getStates]);

  const deleteAddress = useCallback((selectedItem) => {

    dispatch({ type: MY_ADDRESSES_ACTIONS.START_CONFIRM });
    deleteAddressRequest({ id:selectedItem.id, isLoggedIn }).then(({ success, title }) => {
      if (success) {
        dispatch({
          type: MY_ADDRESSES_ACTIONS.END_CONFIRM,
          payload: {
            myAddresses: state.myAddresses.filter(({ id }) => id !== selectedItem.id),
            ...setSuccessAlert(MESSAGES.ADDRESS_DELETED)
          }
        });
      } else {
        return Promise.reject(title);
      }
    }).catch((er) => {
      const msg = er?.message || er;
      dispatch({
        type: MY_ADDRESSES_ACTIONS.END_CONFIRM,
        payload: setErrorAlert(msg)
      });

      return Promise.reject(msg);
    });
  }, [state.myAddresses, isLoggedIn]);

  const setDefaultAddress = useCallback((selectedItem) => {
    dispatch({ type: MY_ADDRESSES_ACTIONS.START_CONFIRM });

    updateAddressRequest({ data: { ...selectedItem, isDefault: true }, isLoggedIn })
      .then(({ success, title }) => {
        if (success) {

          const updatedAddresses = state.myAddresses.map((currentAddress) => {
            currentAddress.isDefault = currentAddress.id === selectedItem.id;
            return currentAddress;
          })
          const myAddresses = sortMyAddresses(updatedAddresses);

          dispatch({
            type: MY_ADDRESSES_ACTIONS.END_CONFIRM,
            payload: {
              myAddresses,
              ...setSuccessAlert(MESSAGES.ADDRESS_SAVED)
            }
          });
        } else {
          return Promise.reject(title);
        }
    })
      .catch((er) => {
        const msg = er?.message || er;
        dispatch({
          type: MY_ADDRESSES_ACTIONS.END_CONFIRM,
          payload: setErrorAlert(msg)
        });

        return Promise.reject(msg);
      });
  }, [state.myAddresses, isLoggedIn, sortMyAddresses]);

  const toggleDialog = useCallback((open, selectedItem, action = ADDRESS_ACTIONS.DELETE) => {
    dispatch({
      type: open ? MY_ADDRESSES_ACTIONS.OPEN_CONFIRM : MY_ADDRESSES_ACTIONS.CLOSE_CONFIRM,
      payload:
        {
          ...open
            ?
            {
              ...action === ADDRESS_ACTIONS.DELETE
                ?
                {
                  selectedItem,
                  confirmFunc: () => deleteAddress(selectedItem),
                  addressConfirmAction: action,
                  confirmTitle: MESSAGES.CONFIRM_DELETE_ADR_TITLE,
                  confirmInfo: MESSAGES.CONFIRM_DELETE_ADR_INFO
                }
                :
                {
                  selectedItem,
                  confirmFunc: () => setDefaultAddress(selectedItem),
                  addressConfirmAction: action,
                  confirmTitle: MESSAGES.CONFIRM_DEFAULT_ADR_TITLE,
                  confirmInfo: MESSAGES.CONFIRM_DEFAULT_ADR_INFO
                }
            }
            :
            {
              selectedItem: null,
              addressConfirmAction: "",
              confirmTitle: "",
              confirmInfo: "",
            }
        }
    });

  }, [deleteAddress, setDefaultAddress]);

  const toggleAddressDialog = useCallback(({ open = true, selectedItem, reFetchAddresses }) => {
    dispatch({
      type: open ? MY_ADDRESSES_ACTIONS.OPEN_ADDRESS : MY_ADDRESSES_ACTIONS.CLOSE_ADDRESS,
      payload: {
        selectedItem: open ? selectedItem : null
      }
    });

    if (reFetchAddresses) {
      getAddresses();
    }
  }, [getAddresses]);

  return {
    ...state,
    toggleDialog,
    toggleAddressDialog
  };
};

import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import { theme } from "../../../components/theme/constants";
import { MESSAGES } from "../messages";

const SelectedAddress = memo(({ address }) => {
  return (
    <Box sx={{ mt: 2, alignItems: "center", display: "flex" }}>
      {address
        ?
        <>
          {address.isDefault &&
            <WhereToVoteIcon fontSize="small" sx={{ color: theme.palette.success.main }}/>
          }
          <Typography variant={"body"}>
            {`${address.address1}${address.address2 ? ` ${address.address2},` : ","} ${address.city}, ${address.state} ${address.zipCode}`}
          </Typography>
        </>
        :
        <Typography variant={"body"}>
          {MESSAGES.PLS_SELECT_ADDRESS}
        </Typography>
      }
    </Box>
  );
});

SelectedAddress.propTypes = {
  address: PropTypes.object,
};

export default SelectedAddress;

import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const SEO = (props) => {
  return (props &&
    <Helmet>
      <title>{props.title}</title>
      {Object.keys(props).map( key => <meta key={key} name={key} description={props[key]} />)}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SEO;

import { useMemo } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { MESSAGES } from "../../../messages";
import AddressPaper from "./AddressPaper";
import { Box } from "@mui/material";
import ShippingRates from "./ShippingRates";

const ShippingOrder = ({ addressesData, orderItems }) => {
  const { register, setValue, getValues, shippingAddressKeyName } = useFormContext();
  const shipToId = getValues(shippingAddressKeyName);

  const shipTo = useMemo(() => {
    if (typeof shipToId === "number") {
      return addressesData.myAddresses.find(({ id }) => id === shipToId);
    }

    return shipToId;
  }, [addressesData, shipToId]);

  return (
    <Box
      sx={{ backgroundColor: "grey.50", mt: 0.5 }}
      noValidate
      component="form"
    >
      <AddressPaper
        {...addressesData}
        point={1}
        header={MESSAGES.ADR_SHIP_HEADER}
      />

      {shipTo &&
        <ShippingRates
          shipTo={shipTo}
          orderItems={orderItems}
          register={register}
          setValue={setValue}
        />
      }
    </Box>
  );
};

ShippingOrder.propTypes = {
  addressesData: PropTypes.object.isRequired,
  orderItems: PropTypes.array.isRequired,
};

export default ShippingOrder;

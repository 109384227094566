import PropTypes              from "prop-types";
import { Box }                from "@mui/material";
import { Card }               from "pages/main/ContactUs/components/index";
import MailOutlineOutlined    from "@mui/icons-material/MailOutlineOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOn             from "@mui/icons-material/LocationOn";

const Cards = ({ email, phone, address }) => {
  return (
    <Box
      sx={{
        my: 8,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Card
        Icon={MailOutlineOutlined}
        title="Write to us"
        info={email}
        href={`mailto:${email}`}
      />
      <Card
        Icon={LocalPhoneOutlinedIcon}
        title="Speak to us"
        info={phone}
        href={`tel:${phone}`}

      />
      <Card
        Icon={LocationOn}
        title="Location"
        info={address}
        href={"https://www.google.com/maps/place/380+S+Los+Angeles+St+Unit+P1,+Los+Angeles,+CA+90013,+USA/@34.0473321,-118.2479971,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2c649e43f8bf7:0xb218bfa3be22fda!8m2!3d34.0473321!4d-118.2458084"}
      />
    </Box>
  );
};

Cards.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string
};

export default Cards;

import { useForm }                                   from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getResellerStatus }                         from "../../../services/api/settings/SettingsService";

export const useBecomeReseller = (resellerStatus, onSubmit) => {
  const { register, handleSubmit, reset, formState: { errors } }
    = useForm();
  const [statuses, setStatuses] = useState([]);
  useEffect(() => {
    getResellerStatus().then(data => setStatuses(data));
  }, []);

  const handleUpload = useCallback(({ resellerForm }) => {
    onSubmit(resellerForm, reset);
  }, [reset, onSubmit]);

  const currentStatus = useMemo(() => {
    return statuses[ resellerStatus ];
  }, [statuses, resellerStatus]);

  return {
    currentStatus,
    register,
    handleSubmit,
    errors,
    handleUpload
  };

};
import { postAPI } from "../NetService";
import { SHIPPING_RATE_PATH } from "./constants";

//todo - abort controller if there is already request in process.
export const getPackagesShippingRateRequest = (data) => {
  return postAPI(SHIPPING_RATE_PATH, data)
    .then(({ data, success, title }) => {
      if (success) {
        return { data, success, title };
      }

      return Promise.reject(title ?? "Something went wrong");
    });
};

import PropTypes from "prop-types";
import { InputAdornment, TextField } from "@mui/material";
import VisibilityButton from "./VisibilityButton";
import { useState } from "react";
import { passwordValidationRegex } from "pages/constants";

const passwordRegMsg = "Password should contain Uppercase/lowercase letters, numbers and symbols.";

const PasswordField = ({ register, errors, name="password", label="Password", required = true, regex, showHelpText, ...props }) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  return (
    <TextField
      margin="normal"
      required
      fullWidth
      label={label}
      id={name}
      name={name}
      autoComplete={name}
      {...showHelpText && {
        helperText: passwordRegMsg
      }}
      type={show ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <VisibilityButton
              show={show}
              onClick={toggleShow}
            />
          </InputAdornment>
        ),
      }}
      {...register(name, {
        required: !!required && "Password is required.",
        ...(regex
          ? {
            pattern: {
              value: passwordValidationRegex,
              message: passwordRegMsg
            }
          }
          : {})
      })}
      {...errors[name] &&
      {
        error: true,
        helperText: errors[name].message
      }
      }
      {...props}
    />
  );
};

PasswordField.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  showHelpText: PropTypes.bool,
  regex: PropTypes.bool,
};

export default PasswordField;

import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import { useBecomeReseller }                         from "./useBecomeReseller";
import { useShopSelector } from "store/hooks";
import customerSelectors from "store/customer/customerSelectors";

export const BecomeReseller = ({ resellerStatus, onSubmit }) => {
  const {
    currentStatus,
    register,
    handleSubmit,
    errors,
    handleUpload
  } = useBecomeReseller(resellerStatus, onSubmit);
  const isReseller = useShopSelector(customerSelectors.getIsReseller);

  return <Paper sx={{ width: "100%", my: 2, p: 2, boxSizing: "border-box" }}>
    {!isReseller &&
      <Box display={"flex"} flexWrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
        <Typography variant={"h4"} sx={{ mr: 0.5 }}>
          To Become Reseller -
        </Typography>
        <Typography variant={"h4"}>
          Please fill the
        </Typography>
        <Typography variant={"body1"} sx={{ mx: 0.5 }} alignSelf={"self-start"}>
          <a download={"California-Resale-Certificate.pdf"} href={`/California-Resale-Certificate.pdf`}>form</a>
        </Typography>
        <Typography variant={"h4"}>
          and Submit
        </Typography>
      </Box>
    }
    {(resellerStatus === 0 || resellerStatus === 2) ?
      <Box sx={{ mt: 3, display: "flex", justifyContent: "center", flexDirection: "column" }}>
        {resellerStatus === 2 && <Typography> Reseller request is {currentStatus} </Typography>}
        <Box component="form"
             onSubmit={handleSubmit(handleUpload)}
             noValidate
             sx={{ display: "flex", justifyContent: "space-around", alignItems: "start", mt: 3 }}>
          <TextField
            type="file"
            id={"resellerForm"}
            name={"resellerForm"}
            inputProps={{ multiple: false }}
            {...register("resellerForm", {
              required: "Form is required"
            })}
            {...errors[ "resellerForm" ] &&
            {
              error: true,
              helperText: errors[ "resellerForm" ].message
            }}
          />
          <Button type={"submit"} variant={"contained"} sx={{ ml: 3, height: "55px" }}>Submit</Button>
        </Box> </Box> : <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
        <Typography>
          Reseller request is {currentStatus}
        </Typography>
      </Box>}
  </Paper>;

};
import { getCustomerData } from "services/api/settings/SettingsService";
import { customerDataUpdate } from "store/customer/customerSlice";

export const updateCustomer = (reload = true) => {
  getCustomerData()
    .then((res) => {
      customerDataUpdate(res);
      setTimeout(() => reload && window.location.reload(), 2000);
    });
};

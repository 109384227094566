import PropTypes from "prop-types";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

const VisibilityButton = ({ show, onClick }) => {
  return (
    <IconButton
      aria-label={show ? "hide" : "show"}
      size="large"
      onClick={onClick}
    >
      {
        show
          ? <VisibilityOffIcon fontSize="inherit" />
          : <VisibilityIcon fontSize="inherit" />
      }
    </IconButton>
  );
};

VisibilityButton.propTypes = {
  show: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default VisibilityButton;

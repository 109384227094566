import { Outlet } from "react-router-dom";
import { MyMenu } from "pages/customer/components";
import { Box }    from "@mui/material";

const MyWrapper = () => {
  return(
    <Box sx={{backgroundColor:"grey.50"}}>
      <Box display={{ lg:"flex" }} justifyContent={"space-between"} sx={{ px: { xs: 2, sm: 3, md: 10.5, lg: 0 }, mx: "auto", py:4, maxWidth: "1152px"}}>
        <MyMenu />
        <Outlet />
      </Box>
    </Box>
  )
}

export default MyWrapper;
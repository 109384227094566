import * as React                               from "react";
import Box                                      from "@mui/material/Box";
import Table                                    from "@mui/material/Table";
import TableBody                                from "@mui/material/TableBody";
import TableCell                                from "@mui/material/TableCell";
import TableContainer                           from "@mui/material/TableContainer";
import TablePagination                                    from "@mui/material/TablePagination";
import TableRow                                           from "@mui/material/TableRow";
import Paper                                              from "@mui/material/Paper";
import WithProgress from "../../../components/main/WithProgress";
import { useMyOrders }                                    from "./useMyOrders";
import { getComparator, headCells, stableSort }           from "./constants";
import { EnhancedTableHead }                              from "./EnhancedTableHead";
import { EnhancedTableToolbar }                           from "./EnhancedTableToolbar";
import { Button, Collapse, Typography } from "@mui/material";
import { shopPaths }                                      from "../../../routing/constants";
import { RowDescription }                                 from "./RowDescription";
import { theme }                                          from "../../../components/theme/constants";
import { ShoppingBagOutlined }                            from "@mui/icons-material";

export default function MyOrders() {
  const {
    myOrders, order,
    orderBy,
    rowsPerPage, page,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    emptyRows,
    requestLoading,
    statuses,
    open,
    setOpen,
    navigate
  } = useMyOrders();

  return (
    <Box sx={{ mt: { xs: 5, md: 8, lg: 0 }, width: { xs: "100%", lg: "74%" } }}>
      <Box display={"flex"} alignItems={"center"}>
        <ShoppingBagOutlined color={"primary"}/>
        <Typography component="p" variant="h3" sx={{ ml: 1.5 }}>
          Orders list
        </Typography>
      </Box>
      <Paper sx={{ width: "100%", my: 2, px: 2, boxSizing: "border-box" }}>
        {/*<EnhancedTableToolbar/>*/}
        <WithProgress inProgress={requestLoading}>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody sx={{ display: { xs: "none", md: "table-row-group" } }}>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(myOrders, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const created = new Date(row.created);
                  return <React.Fragment key={row.id}>
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      sx={{ cursor: "pointer" }}
                      onClick={() => setOpen(open === row.id ? null : row.id)}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ ...theme.typography.body4 }}
                      >
                        {row.id}
                      </TableCell>
                      <TableCell align="left" sx={{ ...theme.typography.body4 }}>{statuses[ row.status ]}</TableCell>
                      <TableCell align="left"
                                 sx={{ whiteSpace: "nowrap", ...theme.typography.body4 }}>$ {row.total}</TableCell>
                      <TableCell align="left"
                                 sx={{ ...theme.typography.body4 }}>{created.toLocaleDateString()}</TableCell>
                      {/*<TableCell align="left" padding={"none"} sx={{ ...theme.typography.body4 }}>*/}
                      {/*  <Button onClick={(e) => {*/}
                      {/*    e.stopPropagation();*/}
                      {/*  }} variant={"outlined"} sx={{ borderRadius: "50px", textTransform: "none" }}> Reorder</Button>*/}
                      {/*</TableCell>*/}
                    </TableRow>
                    <TableRow>
                      {open === row.id &&
                      <TableCell sx={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "grey.200" }}
                                 colSpan={headCells.length + 1}>
                        <Collapse open={open === row.id} in={!!open} timeout="auto" unmountOnExit>
                          <Box
                            sx={{ mx: 1, my: 1, display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", flexGrow: 1 }}>
                            <Table aria-labelledby="details" size={"medium"}>
                              <TableBody>
                                {row.shoppingBags.map((item, index) => {
                                  return <RowDescription key={index} row={item}/>;
                                })}
                              </TableBody>
                            </Table>
                            <Button onClick={() => {
                              navigate(`${shopPaths.auth.trackOrder.fullPath}/${row.id}`, { state: { newOrder: false } });
                            }} variant={"text"}
                                    sx={{ borderRadius: "50px", textTransform: "none", mt: 2, mx: "auto" }}> More
                              Details</Button>
                          </Box>
                        </Collapse>
                      </TableCell>}
                    </TableRow>
                  </React.Fragment>;
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (53) * emptyRows
                    }}
                  >
                    <TableCell colSpan={6}/>
                  </TableRow>
                )}
              </TableBody>
              <TableBody sx={{ display: { xs: "table-row-group", md: "none" } }}>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(myOrders, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => navigate(`${shopPaths.auth.trackOrder.fullPath}/${row.id}`, { state: { newOrder: false } })}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="checkbox"
                        sx={{ fontSize: "12px" }}
                      >
                        {row.id}
                      </TableCell>
                      <TableCell padding={"checkbox"} sx={{ fontSize: "12px", py: 1.5 }}
                                 align="left">{statuses[ row.status ]}</TableCell>
                      <TableCell padding={"checkbox"} sx={{ fontSize: "12px" }} align="right">{row.total} $</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (53) * emptyRows
                    }}
                  >
                    <TableCell colSpan={6}/>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </WithProgress>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={myOrders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ display: { xs: "none", md: "block" } }}
        />
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={myOrders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ display: { xs: "block", md: "none" } }}
        />
      </Paper>
    </Box>
  );
}

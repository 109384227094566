import { createSelector } from "@reduxjs/toolkit";

const SELLER_STATUS = {
  NONE: 0,
  PENDING: 1,
  REJECTED: 2,
  IS_RESELLER: 3,
};

const customerBaseState = state => state.customer;

const getEntireData = createSelector(customerBaseState, (state) => state);
const getIsLoading = createSelector(customerBaseState, (state) => state.isLoading);
const getIsLoggedIn = createSelector(state => state.customer, (state) => state.isLoggedIn);
const getToken = createSelector(customerBaseState, (state) => state.customerToken);
const getInfo = createSelector(customerBaseState, (state) => state.customerInfo || {});
const getIsReseller = createSelector(customerBaseState, (state) => state.customerInfo?.resellerStatus === SELLER_STATUS.IS_RESELLER);
const getError = createSelector(customerBaseState, (state) => state.error);
const getSuccess = createSelector(customerBaseState, (state) => state.success);

export default {
  getEntireData,
  getIsLoading,
  getIsLoggedIn,
  getIsReseller,
  getToken,
  getInfo,
  getError,
  getSuccess,
};

import { useCallback, useEffect, useReducer, useRef } from "react";
import { fetchReducer } from "pages/auth/reducers";
import { FETCH_ACTIONS, FETCH_INITIAL_STATE } from "pages/auth/reducers/fetchReducer";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useShopSnackbar from "hooks/useShopSnackbar";
import { setErrorAlert, setSuccessAlert } from "utils";
import { useDispatch, useSelector } from "react-redux";
import customerSelectors from "store/customer/customerSelectors";
import { updateCustomerDada } from "store/customer/customerSlice";

export const useFetch = (fetchFunc, successMsg, redirectPath,
                         {
                           shouldDispatch, // for redux case
                           withData,
                           makeArray,
                           failRedirectPath,
                           errorMsg,
                         } = {}
) => {
  const error = useSelector(customerSelectors.getError);
  const isLoading = useSelector(customerSelectors.getIsLoading);

  const dispatchRedux = useDispatch();

  const [state, dispatch] = useReducer(fetchReducer, { ...FETCH_INITIAL_STATE });
  const { register, handleSubmit, getValues, setError, formState: { errors, isSubmitting, isSubmitSuccessful }} = useForm();

  const navigateData = useRef();
  const navigate = useNavigate();
  const navigateAfterSuccess = useCallback(() => {
    if (redirectPath) {
      if (withData) {
        return navigate(redirectPath, { state: { data: navigateData.current } });
      }

      return navigate(redirectPath);
    }
  }, [navigate, redirectPath, withData]);

  const navigateAfterFail = useCallback(() => {
    if (state.shouldRedirectOnFail && failRedirectPath) {
      return navigate(failRedirectPath, { state: { data: navigateData.current } });
    }
  }, [navigate, failRedirectPath, state.shouldRedirectOnFail]);

  useShopSnackbar(state.serverMessage, state.serverMessageType, navigateAfterSuccess, navigateAfterFail)

  /**
   * ================== handle redux case start ==================
   */
  const handleDispatch = useCallback((data) => {
    dispatch({type: FETCH_ACTIONS.FETCH_START});

    dispatchRedux(fetchFunc({ ...data, setError }))
      .then(({ payload }) => {
        if (payload?.status === 403) {
          navigateData.current = { ...data }
          dispatch({
            type: FETCH_ACTIONS.FETCH_END,
            payload: {
              ...setErrorAlert(errorMsg),
              shouldRedirectOnFail: true,
            }
          });
        }
      })

  }, [dispatchRedux, fetchFunc, setError, errorMsg]);

  useEffect(() => {
    if (shouldDispatch) {
      if (error) {
        dispatch({
          type: FETCH_ACTIONS.FETCH_END,
          payload: setErrorAlert(error)
        });
      }
    }

    return () => {
      if (shouldDispatch && error) {
        dispatchRedux(updateCustomerDada({ error: null }));
      }
    }
  }, [error, shouldDispatch, dispatchRedux])
  /**
   * ================== handle redux case end ==================
   */

  const handleFetchSubmit = useCallback((data) => {
    if (shouldDispatch) {
      return handleDispatch(data);
    }

    dispatch({type: FETCH_ACTIONS.FETCH_START});
// todo address validation needed
    return handleSubmit(async () => {
      return fetchFunc(data)
      .then(({ success, title, data }) => {
        if (success) {
          navigateData.current = makeArray ? [data] : data;

          dispatch({
            type: FETCH_ACTIONS.FETCH_END,
            payload: setSuccessAlert(successMsg)
          });

        } else {
          return Promise.reject(title)
        }
      })
      .catch((er) => {
        const msg = er?.message || er;
        dispatch({
          type: FETCH_ACTIONS.FETCH_END,
          payload: setErrorAlert(msg)
        });

        return Promise.reject(msg)
      })
    })();
  }, [handleSubmit, fetchFunc, successMsg, handleDispatch, makeArray, shouldDispatch]);

  return {
    ...state,
    errors,
    setError,
    isSubmitting: isSubmitting || isLoading,
    isSubmitSuccessful,
    register,
    getValues,
    handleSubmit,
    handleFetchSubmit
  };
};

import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { EmailField } from "../../../../../components/main/form";
import customerSelectors from "../../../../../store/customer/customerSelectors";
import { useShopSelector } from "../../../../../store/hooks";
import { MESSAGES } from "../../../messages";
import { calculateTotalPrice } from "../../helper";
import {
  CURRENCY_SYMBOL,
  IS_WITHOUT_SHIPPING_KEY,
  SELECTED_SHIPPING_TOTAL_CHARGES_KEY,
} from "../constants";
import { TAX } from "../usePlaceOrder";
import { OrderListItem } from "./OrderListItem";
import { Box, Divider, List, ListSubheader, Paper, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const OrderSummary = ({ data, total, addedTaxes, loading, handleSubmit, handlePurchaseSubmit }) => {
  const { getValues, errors, register } = useFormContext();
  const isLoggedIn = useShopSelector(customerSelectors.getIsLoggedIn);
  const [isWithoutShipping, selectedTotalCharges] = getValues([IS_WITHOUT_SHIPPING_KEY, SELECTED_SHIPPING_TOTAL_CHARGES_KEY]);

  return (
    <Paper sx={{
      width: { lg: "30%" },
      p: 1,
      boxSizing: "border-box", m: { lg: 2 },
      position: { lg: "sticky" },
      top: { lg: 0 },
      alignSelf: "flex-start",
    }}
    >
      <List
        sx={{ width: "100%" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader sx={{ fontSize: "16px", color: "black", position: "relative" }} component="div"
                         id="nested-list-subheader">
            {MESSAGES.YOUR_ORDER}
          </ListSubheader>
        }
      >
        {data?.map((item) => <OrderListItem key={item.id} data={item}/>)}
        <Divider/>
        <Box sx={{ pl: 3, pt: 2, display: "flex", justifyContent: "space-between" }}>
          <Typography variant={"subtitle2"}>
            {MESSAGES.SUBTOTAL}:
          </Typography>
          <Typography variant={"subtitle2"}>
            {CURRENCY_SYMBOL} {total.toFixed(2)}
          </Typography>
        </Box>
        <Box sx={{ pl: 3, pt: 2, display: "flex", justifyContent: "space-between" }}>
          <Typography variant={"subtitle2"}>
            {MESSAGES.TAX} {TAX}%:
          </Typography>
          <Typography variant={"subtitle2"}>
            {CURRENCY_SYMBOL} {addedTaxes}
          </Typography>
        </Box>
        {!isWithoutShipping &&
          <Box sx={{ pl: 3, py: 2, display: "flex", justifyContent: "space-between" }}>
            <Typography variant={"subtitle2"}>
              {MESSAGES.SHIP}:
            </Typography>
            <Typography variant={"subtitle2"}>
              {selectedTotalCharges ? `${CURRENCY_SYMBOL} ${selectedTotalCharges}` : "-"}
            </Typography>
          </Box>
        }
        <Box sx={{ pl: 3, py: 2, display: "flex", justifyContent: "space-between" }}>
          <Typography variant={"subtitle1"}>
            {MESSAGES.TOTAL}:
          </Typography>
          <Typography variant={"subtitle1"}>
            {CURRENCY_SYMBOL} {calculateTotalPrice(total, addedTaxes, selectedTotalCharges)}
          </Typography>
        </Box>
      </List>

      {!isLoggedIn &&
        <EmailField
          register={register}
          errors={errors}
        />
      }

      <LoadingButton
        fullWidth
        type="button"
        variant="contained"
        loading={loading}
        onClick={handleSubmit(handlePurchaseSubmit)}
      >
        {MESSAGES.PLACE_ORDER}
      </LoadingButton>
    </Paper>
  );
};

OrderSummary.propTypes = {
  data: PropTypes.array,
  total: PropTypes.number.isRequired,
  addedTaxes: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handlePurchaseSubmit: PropTypes.func.isRequired,
};

export default OrderSummary;

import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes                                    from "prop-types";
import {
  Box, Typography, Button, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
  NativeSelect, FormHelperText, FormControlLabel, Checkbox
}                                 from "@mui/material";
import CloseIcon                  from "@mui/icons-material/Close";
import { FIELD_TYPES, getAddressFields } from "pages/customer/MyAddresses/constants";
import {
  createAddressRequest,
  updateAddressRequest,
  validateZipCodeRequest
}                                             from "services/api/addresses/myAddressesService";
import { LoadingButton }                      from "@mui/lab";
import { useShopSelector }                    from "store/hooks";
import customerSelectors                      from "store/customer/customerSelectors";
import { useForm }                            from "react-hook-form";

const AddressPopup = ({ selectedItem, states, open, toggleAddressDialog }) => {
  const updateMode = selectedItem?.id;
  const saveButton = useRef();
  const [errMessage, setErrMessage] = useState()
  const addressFields = getAddressFields(selectedItem || {});
  const handleClose = useCallback((reFetchAddresses = false) =>
      toggleAddressDialog({ open: false, reFetchAddresses }),
    [toggleAddressDialog]);
  const handleSuccessClose = useCallback(() => handleClose(true), [handleClose]);
  const handleCancelClose = useCallback(() => handleClose(), [handleClose])

  const isLoggedIn = useShopSelector(customerSelectors.getIsLoggedIn);
  const {register, setValue, setError, clearErrors, getValues, handleSubmit, formState:{errors, isSubmitting,  isSubmitSuccessful}} = useForm()
  const handleAddressSave = useCallback(() => {
    const data = getValues();
    const saveFunc = updateMode ? updateAddressRequest : createAddressRequest;
     return saveFunc({ data, isLoggedIn }).then((e)=> {
       setErrMessage(null)
     }).catch(setErrMessage)
  }, [isLoggedIn, updateMode, getValues]);

  useEffect(() => {
    if (isSubmitSuccessful && open && !errMessage) {
      handleSuccessClose();
    }
  }, [isSubmitSuccessful, open, errMessage, handleSuccessClose]);

  const handleBlur = useCallback((e, name) => {
    const isZip = name === "zipCode";

    e.relatedTarget !== saveButton.current && !!e?.target.value && isZip &&
    validateZipCodeRequest(e.target.value)
      .then((resp => {
        !!errors["zipCode"] && clearErrors("zipCode")
        if (!resp) {
          setError("zipCode", {type: "server", message: "Please enter a valid ZipCode" })
        } else if(!isSubmitting){
          setValue("city",resp.city)
          setValue("state",resp.state)
        }
      }));
  },[clearErrors, setError, isSubmitting, errors, setValue])

  return (
    <Dialog open={open} onClose={handleCancelClose}>
      <Box
        component="form"
        onSubmit={handleSubmit(handleAddressSave)}
        noValidate
      >
        <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center", pr: 1}}>
          <Typography variant="body2">
            {`${updateMode ? "Edit" : "Add New"} Address` }
          </Typography>
          <IconButton onClick={handleCancelClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {addressFields.map(({ label, name, id, required, defaultValue, disabled, pattern, valueAsNumber, type, validate }) => {
            if (type === FIELD_TYPES.SELECT) {
              return (
              <FormControl
                key={id}
                fullWidth
                {...errors[id] && {
                  error: true
                }}
              >
                <NativeSelect
                  defaultValue={defaultValue}
                  id={id}
                  sx={{px: 2}}
                  name={name}
                  {...register(id, {
                    ...required && {
                      required: `${label} is required.`,
                    },
                  })}
                >
                  {states.map(state => <option key={state} value={state}>{state}</option>)}
                  </NativeSelect>
                {errors[id] &&
                  <FormHelperText error>{errors[id].message}</FormHelperText>
                }
                </FormControl>
              )
            }

            if (type === FIELD_TYPES.CHECKBOX) {
              return (
                <FormControlLabel
                  key={id}
                  control={
                    <Checkbox
                      defaultChecked={defaultValue}
                      name={name}
                      id={id}
                      {...register(name)}
                    />
                  }
                  label={label}
                />
              );
            }

            return (
              <TextField
                key={id}
                disabled={disabled}
                margin="normal"
                required={required}
                InputProps={{ onBlur: (e) => handleBlur(e, name) }}
                fullWidth
                id={name}
                label={label}
                name={name}
                {...register(name, {
                  ...required && {
                    required: `${label} is required.`,
                  },
                  value: defaultValue,
                  ...valueAsNumber && {
                    valueAsNumber: true,
                  },
                  ...pattern && {
                    pattern: {
                      value: pattern,
                      message: `${label} is invalid.`
                    }
                  },
                  ...validate && { validate }

                })}
                {...errors[ name ] &&
                {
                  error: true,
                  helperText: errors[ name ].message
                }
                }
              />
            );
          })
          }
          {updateMode &&
            <input
              type="hidden"
              value={selectedItem.id}
              {...register("id")}
            />
          }
          <Box sx={{textAlign:"center"}}>
            <Typography color={"red"} >
              {errMessage }
            </Typography>
            </Box>
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          <Button variant="contained" onClick={handleCancelClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            ref={el => saveButton.current = el}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

AddressPopup.propTypes = {
  states: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedItem: PropTypes.object,
  open: PropTypes.bool,
  toggleAddressDialog: PropTypes.func.isRequired,
};

export default AddressPopup;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import OrderPaper from "pages/customer/ShoppingBag/components/OrderPaper";
import { PAPER_TYPES } from "./constants";

const ShoppingBagItems = memo(({ items, toggleCheck, checkedItems, toggleDialog, onCheckout }) => {
  return (
    items.map(bagItem => (
      <OrderPaper
        key={bagItem.id}
        item={bagItem}
        defaultChecked={!!checkedItems.find(it => it.id === bagItem.id)}
        changeCheck={toggleCheck}
        toggleDialog={toggleDialog}
        type={PAPER_TYPES.SHOPPING_BAG}
        onCheckout={onCheckout}
      />
    ))
  );
});

ShoppingBagItems.propTypes = {
  items: PropTypes.array,
  checkedItems: PropTypes.array.isRequired,
  toggleCheck: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  onCheckout: PropTypes.func.isRequired,
};

ShoppingBagItems.defaultProps = {
  items: []
};

export default ShoppingBagItems;

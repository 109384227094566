import { useCallback, useState } from "react";
import { Paper, BottomNavigation, BottomNavigationAction, Badge } from "@mui/material";
import ShoppingBagOutlinedIcon                              from "@mui/icons-material/ShoppingBagOutlined";
import AccountCircleIcon                                    from "@mui/icons-material/AccountCircle";
import PropTypes                                            from "prop-types";
import customerSelectors from "store/customer/customerSelectors";
import useMainNavigation                                    from "hooks/useMainNavigation";
import ContactPhoneOutlinedIcon                             from "@mui/icons-material/ContactPhoneOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/customer/customerSlice";
import { useShopSelector } from "store/hooks";
import shopInfoSelectors from "store/shopInfo/shopInfoSelectors";

const ShopBottomNavigation = ({ hideMenu }) => {
  const [value, setValue] = useState(-1);
  const isLoggedIn = useSelector(customerSelectors.getIsLoggedIn);
  const { goToShoppingBag, goToContactsUs, goToAccount, goToLogin } = useMainNavigation();

  const dispatch = useDispatch();
  const logoutHandler = useCallback(() => dispatch(logout()), [dispatch]);
  const hideHandler = useCallback((e) => hideMenu(e), [hideMenu]);
  const shoppingBagItemsCount = useShopSelector(shopInfoSelectors.getShoppingBagItemsCount);

  return (
    <Paper sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        onClick={hideHandler}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          onClick={goToContactsUs}
          label="Contact Us"
          sx={{padding:0, minWidth:"50px"}}
          icon={<ContactPhoneOutlinedIcon/>}
        />
        <BottomNavigationAction
          onClick={goToShoppingBag}
          sx={{padding:0, minWidth:"50px"}}
          label="Bag"
          icon={
            <Badge badgeContent={`${shoppingBagItemsCount}`} color="badge">
              <ShoppingBagOutlinedIcon/>
            </Badge>
          }
        />
        <BottomNavigationAction
          onClick={isLoggedIn ? goToAccount : goToLogin}
          label={isLoggedIn ? "Account" : "Log In"}
          icon={<AccountCircleIcon />}
          sx={{padding:0, minWidth:"50px"}}
        />
        {isLoggedIn &&
          <BottomNavigationAction
            onClick={logoutHandler}
            label="Log Out"
            icon={<LogoutIcon/>}
            sx={{padding:0, minWidth:"50px"}}
          />
        }
      </BottomNavigation>
    </Paper>
  );
};

ShopBottomNavigation.propTypes = {
  hideMenu: PropTypes.func.isRequired
};

export default ShopBottomNavigation;

import { Box, Button, Paper, Typography } from "@mui/material";
import WithProgress from "../../../components/main/WithProgress";
import AddressPopup                                         from "./AddressPopup";
import MyAddressesCards                                     from "pages/customer/MyAddresses/MyAddressesCards";
import { useMyAddresses }                                   from "pages/customer/MyAddresses/useMyAddresses";
import { ConfirmDialog }                                    from "pages/customer/components";
import LocationOn                                           from "@mui/icons-material/LocationOn";

const MyAddresses = () => {
  const {
    myAddresses,
    selectedItem,
    states,
    openConfirm,
    confirmTitle,
    confirmInfo,
    confirmFunc,
    loadingConfirm,
    toggleDialog,
    openAddressPopup,
    toggleAddressDialog,
    requestLoading
  } = useMyAddresses();
  return (
    <Box sx={{ mt: { xs: 5, md: 8, lg: 0 }, width: { xs: "100%", lg: "74%" } }}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"} alignItems={"center"}>
          <LocationOn color={"primary"}/>
          <Typography component="p" variant="h3" sx={{ ml: 1.5 }}>
            All addresses
          </Typography>
        </Box>
        <Button variant="outlined" onClick={() => toggleAddressDialog({})}>
          Add Address
        </Button>

        {openAddressPopup &&
        <AddressPopup
          states={states}
          selectedItem={selectedItem}
          open={openAddressPopup}
          toggleAddressDialog={toggleAddressDialog}
        />
        }
      </Box>
      <Paper sx={{ width: "100%", my: 2, px: 2, boxSizing: "border-box" }}>
        <WithProgress inProgress={requestLoading}>
          <MyAddressesCards
            myAddresses={myAddresses}
            toggleDialog={toggleDialog}
            toggleAddressDialog={toggleAddressDialog}
          />
        </WithProgress>
      </Paper>
      {confirmFunc &&
        <ConfirmDialog
          open={openConfirm}
          title={confirmTitle}
          message={confirmInfo}
          confirmFunc={confirmFunc}
          toggleDialog={toggleDialog}
          loading={loadingConfirm}
        />
      }
    </Box>
  );
};

export default MyAddresses;

import { useCallback, useMemo, useState } from "react";
import { Box, IconButton, Badge, Menu, MenuItem, Typography } from '@mui/material';
import PersonOutlined from '@mui/icons-material/PersonOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { Link } from "react-router-dom";
import { shopPaths } from "routing/constants";
import { useDispatch, useSelector } from "react-redux";
import customerSelectors from "store/customer/customerSelectors";
import { logout } from "store/customer/customerSlice";
import useMainNavigation from "hooks/useMainNavigation";
import { useShopSelector } from "store/hooks";
import shopInfoSelectors from "store/shopInfo/shopInfoSelectors";

const StatusBar = () => {
  // todo - Fix-108 fullname does not change on customer update
  const { fullName } = useSelector(customerSelectors.getInfo);
  const isLoggedIn = useSelector(customerSelectors.getIsLoggedIn);
  const dispatch = useDispatch();
  const customerFirstChar  = useMemo(() => fullName?.charAt(0), [fullName])
  const { goToShoppingBag, goToLogin } = useMainNavigation();
  const shoppingBagItemsCount = useShopSelector(shopInfoSelectors.getShoppingBagItemsCount);

  const logoutHandler = useCallback(() => {
    dispatch(logout());
    handleMenuClose();
    goToLogin()
  }, [dispatch, goToLogin]);

  const [anchorEl, setAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} >
        <Link to={shopPaths.unAuth.signUp.path} style={{all:"unset" }}>
          Sign Up
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link to={shopPaths.unAuth.login.path} style={{all:"unset" }}>
          Log In
        </Link>
      </MenuItem>
    </Menu>
  );

  const renderLoggedInMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} >
        <Link to={shopPaths.auth.myOrders.fullPath} style={{all:"unset" }} >
          My Orders
        </Link>
      </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <Link to={shopPaths.auth.myAddresses.fullPath} style={{all:"unset" }}>
            My Adresses
          </Link>
        </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link to={shopPaths.auth.myAccountSettings.fullPath} style={{all:"unset" }}>
          Settings
        </Link>
      </MenuItem>
      <MenuItem onClick={logoutHandler}>
        Log Out
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
      {/*<IconButton size="large" aria-label="show 4 new mails" sx={{ backgroundColor: "grey.200", ml: 2 }}>*/}
      {/*  <Badge badgeContent={4} color="badge">*/}
      {/*    <MailOutlineOutlinedIcon />*/}
      {/*  </Badge>*/}
      {/*</IconButton>*/}
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        sx={{ backgroundColor: "grey.200", m:"auto", width:"48px"}}
      >
        {isLoggedIn
          ? <Typography variant="body2" sx={{ mx: .5 }}>{customerFirstChar}</Typography>
          : <PersonOutlined />
        }
      </IconButton>
      <IconButton
        size="large"
        aria-label="show 7 new notifications"
        sx={{ backgroundColor: "grey.200", ml: 2 }}
        onClick={goToShoppingBag}
      >
        <Badge badgeContent={`${shoppingBagItemsCount}`} color="badge">
          <ShoppingBagOutlinedIcon />
        </Badge>
      </IconButton>
      {isLoggedIn
        ? renderLoggedInMenu
        : renderMenu
      }
    </Box>
  );
};

export default StatusBar;

import { useCallback, useEffect }              from "react";
import PropTypes                               from "prop-types";
import {
  Box, Button, Typography, Paper, List, ListSubheader, ListItemText,
  FormHelperText, ListItem, IconButton
}                                              from "@mui/material";
import CloudUploadOutlined                     from "@mui/icons-material/CloudUploadOutlined";
import { SH_FIELD_NAMES, SHOPPING_BAG_KEY }    from "pages/main/Product/components/ProductForm/constants";
import { handleImageValidate }                 from "pages/main/Product/utils";
import { FILE_MAX_ITEMS, FILE_MAX_SIZE_LABEL } from "pages/main/Product/constants";
import { theme }                               from "components/theme/constants";
import DeleteIcon                              from "@mui/icons-material/Delete";

const ProductOrderImages = ({ register, errors, isSubmitting, getValues, setValue, trigger }) => {
  const { [ SHOPPING_BAG_KEY ]: { customerUploads }, file } = getValues();

  const deleteSelectedFile = useCallback((index) => {
    const currentFiles = [...file];
    currentFiles.splice(index, 1);
    setValue(SH_FIELD_NAMES.file, currentFiles);
  }, [file, setValue]);

  register(SH_FIELD_NAMES.customerUploads, { defaultValue: customerUploads });

  const deleteCustomerUploadedFile = useCallback((id) => {
    const filtered = customerUploads.filter((uploadedImage) => uploadedImage.id !== id);
    setValue(SH_FIELD_NAMES.customerUploads, filtered);
  }, [customerUploads, setValue]);

  const handleFiles = useCallback((files) => {
    if (files) {
      const ar = Array.from(files);
      setValue(SH_FIELD_NAMES.file, [...file, ...ar]);
    }
  }, [file, setValue]);

  useEffect(() => {
    trigger(SH_FIELD_NAMES.file);
  }, [file, trigger]);

  const stopEvents = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  }, []);

  const onDrop = useCallback((ev) => {
    stopEvents(ev);
    handleFiles(ev.dataTransfer.files);
  }, [handleFiles, stopEvents]);

  return (
    <Box>
      <Paper
        variant="outlined"
        sx={[
          {
            "&:hover": {
              background: theme.palette.grey[ 100 ]
            }
          },
          { borderStyle: "dashed", borderRadius: "8px", textAlign: "center", p: 3 }
        ]}
        onDragEnter={stopEvents}
        onDragOver={stopEvents}
        onDragLeave={stopEvents}
        onDrop={onDrop}
      >
        <CloudUploadOutlined fontSize="large"/>
        <Typography variant="body2" sx={{ mt: 4, mb: 1 }}>
          Select a file
          {/*or drag and drop here*/}
        </Typography>
        <Typography variant="body3" color="text.secondary">
          {`JPG, JPEG, PNG or PDF, max ${FILE_MAX_ITEMS} files, each size no more than ${FILE_MAX_SIZE_LABEL}`}
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Button variant="outlined" component="label">
            Select file
            <input
              multiple
              hidden
              type="file"
              name={SH_FIELD_NAMES.file}
              id={SH_FIELD_NAMES.file}
              {...register(SH_FIELD_NAMES.file, {
                validate: (files) => {
                  const { [ SHOPPING_BAG_KEY ]: { isArtwork } } = getValues();
                  return handleImageValidate(files, !customerUploads?.length && !isArtwork, isSubmitting);
                },
                onChange: (ev) => {
                  handleFiles(ev.target.files);
                  return ev.target.files;
                }
              })}
            />
          </Button>
        </Box>
        {errors[ SH_FIELD_NAMES.file ] &&
        <FormHelperText error>{errors[ SH_FIELD_NAMES.file ].message}</FormHelperText>
        }
      </Paper>

      <List>
        <ListSubheader>
          <Typography variant="subtitle1" color="text.primary">
            Files
          </Typography>
        </ListSubheader>
        {file?.map?.(({ name }, i) => {
          return (
            <ListItem key={i} secondaryAction={
              <IconButton onClick={() => deleteSelectedFile(i)} edge="end" aria-label="delete">
                <DeleteIcon/>
              </IconButton>}>
              <ListItemText primary={name} primaryTypographyProps={{ variant: "body2" }}/>
            </ListItem>
          );
        })
        }
      </List>

      {!!customerUploads?.length &&
      <List>
        <ListSubheader>
          <Typography variant="subtitle1" color="text.primary">
            Uploaded files
          </Typography>
        </ListSubheader>
        {customerUploads?.map?.(({ name, id }) =>
          (
            <ListItem key={id} secondaryAction={
              <IconButton onClick={() => deleteCustomerUploadedFile(id)} edge="end" aria-label="delete">
                <DeleteIcon/>
              </IconButton>}>
              <ListItemText primary={name} primaryTypographyProps={{ variant: "body2" }}/>
            </ListItem>
          ))
        }
      </List>
      }
    </Box>
  );
};

ProductOrderImages.propTypes = {
  register: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired
};

export default ProductOrderImages;
